import {
  Box,
  Button,
  Tab,
  Tabs,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { ReactComponent as CredentialIcon } from "assets/images/svg/credential-icon.svg";
import { ReactComponent as ScanIcon } from "assets/images/svg/scan-icon2.svg";
import { ReactComponent as UploadIcon } from "assets/images/svg/upload-icon.svg";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";

import Loader from "components/Loader";
import QrReader from "react-qr-reader";
import { useDispatch, useSelector } from "react-redux";
import { openNotification, toggleOfflineModal } from "store/commonActions";
import { uploadJson, verifyCredentials } from "../verifyCredentials.action";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column-reverse",
  },
  tabsContainer: {
    padding: "20px 0",
    background: "#f6f6f6",
    borderRadius: "20px 20px 0 0",
    marginTop: "-20px",
    zIndex: 1,
    "& button": {
      padding: "0 5px",
    },
    "& .MuiTab-root": {
      minWidth: "32%",
      minHeight: 40,
      borderRadius: 25,
      flexGrow: 1,
      marginRight: 5,
      border: "1px solid #d5d5d5",
      "&:last-child": {
        marginRight: 0,
      },
    },
    "& .MuiTabs-root": {
      minHeight: 0,
    },
    "& .Mui-selected": {
      opacity: 1,
      background: "#083f85",
      color: "#fff",
    },
    "& .PrivateTabIndicator-root": {
      display: "none",
    },
    "& .MuiTabs-flexContainer": {
      justifyContent: "space-evenly",
    },
  },
  tabImg: {
    marginRight: 5,
    fill: "#616161",
  },
  tabImgActive: {
    fill: "#fff",
    marginRight: 5,
  },
  tabImg2: {
    marginRight: 5,
    // fill: "#616161",
    stroke: "#616161",
  },
  tabImgActive2: {
    stroke: "#fff",
    marginRight: 5,
  },
  tabList: {
    display: "flex",
    alignItems: "center",
    fontSize: 12,
    textTransform: "none",
  },
  tabPanelContainer: {
    "& span": {
      fontWeight: "bold",
      textAlign: "center",
      display: "block",
    },
  },
  fields: {
    "& .MuiOutlinedInput-root": {
      marginBottom: 0,
    },
    "& input": {
      height: "42px !important",
    },
  },
  customLabel: {
    fontSize: "12px",
    fontWeight: "600",
    color: "#616161",
  },
  heading: {
    padding: "40px 0",
    background: "#f6f6f6",
    borderRadius: "20px 20px 0 0",
    marginTop: -15,
  },
  verifyMidContainer: {
    padding: "40px 20px",
    background: "#fff",
    borderRadius: "20px 20px 0 0",
    marginTop: -15,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    minHeight: 350,
  },
  qrContainer: {
    padding: "40px 20px",
    background: "#bbb",
    borderRadius: "20px 20px 0 0",
    marginTop: -15,
    minHeight: 350,
  },
  buttonWrapper: {
    textAlign: "center",
    padding: "30px 0 0",
    "& button": {
      height: 36,
      width: 130,
    },
  },
  chooseHelper: {
    paddingTop: 10,
  },
  tabMain: {
    padding: "0 8px",
  },
}));

export default function ScrollableTabsButtonAuto() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [credID, setCredID] = useState("");
  const [result, setResult] = useState("");
  const [jsonFile, setJsonFile] = useState(null);
  const verifyCredentialsLoading = useSelector(
    (state) => state.verifyCredentials.verifyId.loading
  );
  const isUserOnline = useSelector((state) => state.common.isUserOnline);
  const uploadJsonLoading = useSelector(
    (state) => state.verifyCredentials.uploadJson.loading
  );

  useEffect(() => {
    if (result) {
      const newWindow = window.open(
        `${result}`,
        "_blank",
        "noopener,noreferrer"
      );
      if (newWindow) {
        newWindow.opener = null;
        setResult("");
      }
    }
  }, [result]);

  const handleChange = (event, newValue) => {
    setCredID("");
    setJsonFile(null);
    setValue(newValue);
  };

  const handleScan = (data) => {
    if (data !== result) {
      setResult(data);
    }
  };
  const handleError = (err) => {
    console.error("err", err);
  };

  const handleVerifyCredentials = useCallback(
    (event) => {
      event.preventDefault();
      if (!isUserOnline) {
        dispatch(toggleOfflineModal(!isUserOnline));
        return;
      }
      const successCB = () => {
        const newWindow = window.open(
          `${process.env.REACT_APP_EVERYCRED_VERIFIER}/${credID}`,
          "_blank",
          "noopener,noreferrer"
        );

        if (newWindow) newWindow.opener = null;
      };
      if (credID) {
        dispatch(verifyCredentials(credID, successCB));
      }
    },
    [credID, dispatch, isUserOnline]
  );

  const handleCredentialIdChange = (event) => {
    setCredID(event.target.value.trim());
  };

  const handleFileChange = (event) => {
    if (event.target.files[0]) {
      setJsonFile(event.target.files[0]);
    }
  };

  const handleUpload = (event) => {
    if (!jsonFile) {
      dispatch(
        openNotification({
          message: "Please Upload Json File!",
          severity: "error",
        })
      );
      return;
    }
    const successCB = (data) => {
      window.open(`${data.credential_link}`, "_blank", "noopener,noreferrer");
    };
    dispatch(uploadJson(jsonFile, successCB));
  };

  return (
    <div className={classes.root}>
      <div className={classes.tabsContainer}>
        <Tabs
          value={value}
          className={classes.tabMain}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab
            label={
              <div className={classes.tabList}>
                <ScanIcon
                  className={
                    value === 0 ? classes.tabImgActive : classes.tabImg
                  }
                />
                Scan QR Code
              </div>
            }
            {...a11yProps(0)}
          />
          <Tab
            label={
              <div className={classes.tabList}>
                <CredentialIcon
                  className={
                    value === 1 ? classes.tabImgActive : classes.tabImg
                  }
                />
                Credential ID
              </div>
            }
            {...a11yProps(1)}
          />
          <Tab
            label={
              <div className={classes.tabList}>
                <UploadIcon
                  className={
                    value === 2 ? classes.tabImgActive2 : classes.tabImg2
                  }
                />
                Upload
              </div>
            }
            {...a11yProps(2)}
          />
        </Tabs>
      </div>

      <TabPanel value={value} index={0}>
        <div className={classes.tabPanelContainer}>
          <Typography
            variant="h1"
            color="primary"
            component="span"
            className={classes.heading}
          >
            Scan QR Code
          </Typography>
          <div className={classes.qrContainer}>
            <QrReader
              delay={2000}
              onError={handleError}
              onScan={handleScan}
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className={classes.tabPanelContainer}>
          <Typography
            variant="h1"
            color="primary"
            component="span"
            className={classes.heading}
          >
            Credential ID
          </Typography>
          <div className={classes.verifyMidContainer}>
            <form onSubmit={handleVerifyCredentials}>
              <div className={classes.fields}>
                <TextField
                  fullWidth
                  type="text"
                  name="credential-id"
                  variant="outlined"
                  placeholder="Enter Credential Id"
                  size="small"
                  value={credID}
                  onChange={handleCredentialIdChange}
                  required
                />
                <div className={classes.buttonWrapper}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={verifyCredentialsLoading || credID.length === 0}
                  >
                    <Loader show={verifyCredentialsLoading} />
                    {!verifyCredentialsLoading ? "Verify" : null}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div className={classes.tabPanelContainer}>
          <Typography
            variant="h1"
            color="primary"
            component="span"
            className={classes.heading}
          >
            Upload
          </Typography>
          <div className={classes.verifyMidContainer}>
            <div className={classes.fields}>
              <label htmlFor="btn-upload">
                <input
                  id="btn-upload"
                  name="btn-upload"
                  style={{ display: "none" }}
                  type="file"
                  accept=".json"
                  multiple={false}
                  onChange={handleFileChange}
                />
                <Button
                  className="btn-choose"
                  variant="outlined"
                  component="span"
                  color="primary"
                >
                  Choose Files
                </Button>
              </label>
              <Typography
                variant="body2"
                color="secondary"
                component="p"
                className={classes.chooseHelper}
              >
                {jsonFile?.name || "Select JSON file that you want to upload"}
              </Typography>
              <div className={classes.buttonWrapper}>
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  onClick={handleUpload}
                  disabled={uploadJsonLoading}
                >
                  <Loader show={uploadJsonLoading} />
                  {!uploadJsonLoading ? "Upload" : null}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </TabPanel>
    </div>
  );
}
