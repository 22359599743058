import React, { useEffect } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import MainPattern from "assets/images/svg/bg-pattern-big.svg";
import BackButtonHeader from "components/BackButtonHeader/BackButtonHeader";
import { useLocation } from "react-router-dom";
import { getDate, getTime } from "utils";
import { useDispatch, useSelector } from "react-redux";
import { getLastVerifiedDate } from "pages/credentials/Credentials.action";

const useStyles = makeStyles((theme) => ({
  outerWrapper: {
    position: "relative",
    width: "100%",
    backgroundColor: "#083f85",
    // minHeight: "50vh",
    "&::after": {
      content: `' '`,
      position: "absolute",
      backgroundImage: `url(${MainPattern})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "top right",
      backgroundSize: "contain",
      top: 0,
      right: 0,
      width: "50%",
      height: "40%",
      zIndex: 5,
    },
  },
  userDetailsOuterWrapper: {
    padding: "70px 10px 40px",
    position: "relative",
    // width: "100%",
    textAlign: "center",
    "& span": {
      color: "#fff",
      paddingBottom: 10,
      display: "block",
    },
  },
  heading: {
    position: "fixed",
    backgroundColor: "#083f85",
    paddingBottom: 10,
    // width: "100%",
    zIndex: 3,
    width: "1024px",
    maxWidth: "1024px ",
    [theme.breakpoints.up("md")]: {
      width: "768px",
      maxWidth: "768px ",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  imgWrapper: {
    width: 87,
    height: 84,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    border: "solid 1px #d5d5d5",
    margin: "auto",
    backgroundColor: "#fff",
    "& img": {
      width: "80%",
    },
  },
  detailsWrapper: {
    padding: "10px 0",
    textAlign: "center",
    "& span": {
      fontWeight: "bold",
      "&:nth-child(2)": {
        fontWeight: 500,
      },
      "&:last-child": {
        fontWeight: 400,
        paddingBottom: 0,
      },
    },
  },
  verifyDetailsWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 300,
    margin: "auto",
    borderRadius: 10,
    backgroundColor: "rgba(55, 123, 213, .56)",
    padding: "14px 0px",
    "& span": {
      fontWeight: 500,
      "&:last-child": {
        wordBreak: "break-all",
        padding: "0 15px",
      },
    },
  },
}));
export default function CredentialsDetailsHeader() {
  const classes = useStyles();
  const location = useLocation();
  const { is_verified, last_verified } = useSelector(
    (state) => state.credential.lastVerifiedDate.response
  );
  if (!location.state) {
    window.location.replace("/");
  }
  let { issuer, credential } = location.state;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getLastVerifiedDate({
        cred_id: credential.id,
      })
    );
  }, [dispatch, credential.id]);

  return (
    <React.Fragment>
      <div className={classes.outerWrapper}>
        <div className={classes.heading}>
          <BackButtonHeader title="Credential Details" />
        </div>
        <div className={classes.userDetailsOuterWrapper}>
          <div className={classes.imgWrapper}>
            <img src={credential.logo} alt="user" />
          </div>
          <div className={classes.detailsWrapper}>
            <Typography variant="h4" component="span">
              {credential.subject_name}
            </Typography>
            <Typography variant="h5" component="span">
              {issuer.name}
            </Typography>
            <Typography variant="body1" component="span">
              Issued Date :{" "}
              {`${getDate(credential.created_at)} - ${getTime(
                credential.created_at
              )}`}
            </Typography>
          </div>
          <div className={classes.verifyDetailsWrapper}>
            <Typography variant="body2" component="span">
              Last Verified :{" "}
              {is_verified
                ? `${getDate(last_verified)} - ${getTime(last_verified)}`
                : "Not Yet Verified"}
            </Typography>
            <Typography variant="body2" component="span">
              Hash : {credential.hash}
            </Typography>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
