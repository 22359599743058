import {
  RESET_PASSWORD_BEGIN,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
} from "./resetPassword.constant";

const initState = {
  loading: false,
  message: "",
  error: "",
};

const resetPasswordReducer = (state = initState, action) => {
  switch (action.type) {
    case RESET_PASSWORD_BEGIN:
      return {
        ...state,
        loading: true,
        message: "",
        error: "",
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: action.payload,
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        message: "",
      };
    default:
      return state;
  }
};

export default resetPasswordReducer;
