import { Typography, Button } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/css/commonstyle.module.css";
import Pagination from "@material-ui/lab/Pagination";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { useDispatch, useSelector } from "react-redux";
import { getVerificationLog } from "pages/credentials/Credentials.action";
import { useLocation } from "react-router-dom";
import { getTimeDifference } from "utils";
import RecentField from "components/Skeletons/RecentFields";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontWeight: "bold",
  },
  information: {
    color: "#5e5e5e",
    fontSize: 14,
    fontWeight: "400",
  },
  weightText: {
    fontSize: 15,
    fontWeight: 700,
    paddingBottom: 5,
  },
  recentActivity: {
    padding: "20px 0",
  },
  field: {
    borderBottom: "1px solid #d4d0d0",
    paddingBottom: 15,
    marginBottom: 10,
    "&:last-child": {
      marginBottom: 0,
    },
  },
  time: {
    fontWeight: 700,
    color: "#444444",
  },
  dropdownTitle: {
    paddingRight: 10,
    fontWeight: 700,
  },
  paginationWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0",
  },
  showRecord: {
    display: "flex",
    alignItems: "center",
  },
  pagination: {
    "& .MuiPagination-ul": {
      flexWrap: "nowrap",
    },
  },
}));

export default function Recent() {
  const classes = useStyles();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const dispatch = useDispatch();
  const location = useLocation();
  let { credential } = location.state;
  const recents = useSelector((state) => state.credential.logs.response);
  const loading = useSelector((state) => state.credential.logs.loading);

  useEffect(() => {
    dispatch(
      getVerificationLog({
        cred_id: credential.id,
        page,
        per_page: rowsPerPage,
      })
    );
  }, [dispatch, credential.id, page, rowsPerPage]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (value) => {
    setRowsPerPage(parseInt(value, 10));
    setPage(1);
  };

  const handleClose = (event) => {
    handleChangeRowsPerPage(event.target.value);
    if (anchorRef?.current?.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const logs = recents?.data?.map((item, index) => {
    return (
      <div className={classes.field} key={index}>
        <Typography
          variant="body1"
          component="p"
          color="primary"
          className={[classes.heading, classes.weightText].join(" ")}
        >
          Credential{" "}
          <span className={classes.information}>
            has been verified from {item.ip_address}
          </span>
        </Typography>
        <Typography variant="body2" component="p" className={classes.time}>
          {" "}
          {getTimeDifference(item.created_at)}
        </Typography>
      </div>
    );
  });

  const loadingText = loading ? (
    <RecentField />
  ) : (
    <Typography component="h6" variant="h6" color="primary" align="center">
      No Data Found
    </Typography>
  );

  return (
    <>
      <Typography
        variant="h5"
        component="span"
        color="primary"
        className={classes.heading}
      >
        Recent
      </Typography>
      {!loading && Boolean(recents?.data?.length) ? (
        <>
          <div className={classes.recentActivity}>{logs}</div>
          <div className={classes.paginationWrapper}>
            <div className={classes.showRecord}>
              <Typography
                variant="body2"
                component="p"
                className={classes.dropdownTitle}
              >
                Show:{" "}
              </Typography>
              <Button
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                className={styles.recordBtn}
              >
                {rowsPerPage} Rows
                <KeyboardArrowDownIcon style={{ marginLeft: 10 }} />
              </Button>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom" ? "center top" : "center bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClickAway}>
                        <MenuList
                          autoFocusItem={open}
                          id="menu-list-grow"
                          onKeyDown={handleListKeyDown}
                        >
                          <MenuItem value="5" onClick={handleClose}>
                            5 Rows
                          </MenuItem>
                          <MenuItem value="10" onClick={handleClose}>
                            10 Rows
                          </MenuItem>
                          <MenuItem value="25" onClick={handleClose}>
                            25 Rows
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
            <Pagination
              className={classes.pagination}
              count={Math.ceil(recents?.total / rowsPerPage)}
              size="small"
              page={page}
              defaultPage={1}
              onChange={handleChangePage}
              siblingCount={0}
              boundaryCount={1}
            />
          </div>
        </>
      ) : (
        loadingText
      )}
    </>
  );
}
