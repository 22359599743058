export const REGISTER_BEGIN = "REGISTER_BEGIN";
export const REGISTER_SUCCESS = "REGISTER_SUCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const SEND_VERIFICATION_LINK_TO_EMAIL_BEGIN =
  "SEND_VERIFICATION_LINK_TO_EMAIL_BEGIN";
export const SEND_VERIFICATION_LINK_TO_EMAIL_SUCCESS =
  "SEND_VERIFICATION_LINK_TO_EMAIL_SUCCESS";
export const SEND_VERIFICATION_LINK_TO_EMAIL_FAILURE =
  "SEND_VERIFICATION_LINK_TO_EMAIL_FAILURE";

export const GET_EMAIL_VERIFICATION_STATUS = "GET_EMAIL_VERIFICATION_STATUS";
export const TOGGLE_EMAIL_VERIFICATION_STATUS =
  "TOGGLE_EMAIL_VERIFICATION_STATUS";
