function Footer({ display }) {
  if (!display) return null;
  return (
    <footer
      style={{
        textAlign: "center",
        padding: 3,
        backgroundColor: "rgb(247, 247, 247)",
        color: "rgb(26, 26, 26)",
      }}
    >
      <p>Author: React Team</p>
    </footer>
  );
}

export default Footer;
