import React, { useEffect } from "react";
import Footer from "./Footer/Footer";
import Header from "./Header";
import Main from "./Main/Main";
import Sidebar from "./Sidebar/Sidebar";

function Layout({ isChild, config, children }) {
  const { header, sidebar, footer } = config || {};

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  if (isChild) return children;

  return (
    <>
      <Header display={header?.display} />
      <div>
        <Main>{children}</Main>
        <Sidebar display={sidebar?.display} />
      </div>
      <Footer display={footer?.display} />
    </>
  );
}

export default React.memo(Layout);
