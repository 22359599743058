import moment from "moment";

export const getTimeDifference = (date) => {
  const newDate = new Date(date + "Z");
  return moment(newDate).fromNow();
};

export const getFormattedDate = (date) => {
  let returnValue = "";
  const momentDate = moment(new Date(date + "Z"));
  const REFERENCE = moment();
  const TODAY = REFERENCE.clone().startOf("day");
  const YESTERDAY = REFERENCE.clone().subtract(1, "days").startOf("day");

  const isToday = (newDate) => {
    return newDate.isSame(TODAY, "d");
  };
  const isYesterday = (newDate) => {
    return newDate.isSame(YESTERDAY, "d");
  };

  if (isToday(momentDate)) {
    returnValue = momentDate.fromNow();
  } else if (isYesterday(momentDate)) {
    returnValue = `Yesterday - ${momentDate.format("LT")}`;
  } else {
    returnValue = `${momentDate
      .local()
      .format("D MMMM YYYY")} -  ${momentDate.format("LT")} `;
  }

  return returnValue;
};
