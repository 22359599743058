import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  tabMain: {
    height: 132,
    margin: "20px 20px 20px 10px",
    borderRadius: 10,
    position: "relative",
  },
}));

const CredentialsList = () => {
  const classes = useStyles();
  return (
    <>
      <Skeleton animation="wave" variant="rect" className={classes.tabMain} />
      <Skeleton animation="wave" variant="rect" className={classes.tabMain} />
      <Skeleton animation="wave" variant="rect" className={classes.tabMain} />
      <Skeleton animation="wave" variant="rect" className={classes.tabMain} />
    </>
  );
};

export default CredentialsList;
