import React, { useState } from "react";
import {
  TextField,
  InputLabel,
  makeStyles,
  Typography,
  Grid,
  Button,
} from "@material-ui/core";
import AppLogo from "components/AppLogo";
import RedirectComponent from "components/RedirectComponent";
import AuthImage from "components/AuthImage/AuthImage";
import styles from "assets/css/commonstyle.module.css";
import { forgetPassword } from "./ForgetPassword.action";
import { useDispatch, useSelector } from "react-redux";
import { emailRegex } from "utils";
import Loader from "components/Loader";
import MainPattern from "assets/images/main-pattern.png";
import { toggleOfflineModal } from "store/commonActions";

const useStyles = makeStyles((theme) => ({
  preLoginContentWrapper: {
    margin: "0 auto",
    textAlign: "center",
  },
  pageContainer: {
    padding: "0 20px",
    background: `url(${MainPattern}) no-repeat`,
    backgroundSize: "contain",
  },
  containerWrapper: {
    maxWidth: "500px",
    margin: "0 auto",
  },
  formWrapper: {
    padding: "20px 0",
  },
  fields: {
    display: "flex",
    flexDirection: "column",
    "& label": {
      textAlign: "left",
      marginBottom: 7,
    },
    "& .MuiOutlinedInput-root": {
      marginBottom: 6,
    },
  },
  heading: {
    paddingBottom: 25,
  },
  instruction: {
    fontSize: 12,
    textAlign: "center",
    fontWeight: "bold",
  },
  reponseError: {
    marginTop: "15px",
    textAlign: "center",
    "& p": {
      color: "red",
      fontWeight: "500",
      fontSize: 12,
    },
  },
  customLabel: {
    fontSize: "12px",
    fontWeight: "600",
    color: "#616161",
  },
  buttonWrapper: {
    padding: "20px 0",
    "& button": {
      height: 36,
    },
  },
}));

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [show, setShow] = useState(false);
  const [error, setError] = useState({
    errorStatus: false,
    helperText: "",
  });
  const [errorInFailure, setFailureError] = useState({
    errorStatus: false,
    helperText: "",
  });
  const dispatch = useDispatch();
  const reducerState = useSelector((state) => state.forgetPassword);
  const isUserOnline = useSelector((state) => state.common.isUserOnline);

  const handleChange = (event) => {
    const { value } = event.target;
    setEmail(value);
    setError({
      errorStatus: false,
      helperText: "",
    });
    setFailureError({
      errorStatus: false,
      helperText: "",
    });
    if (value.length === 0) {
      setError({
        errorStatus: true,
        helperText: "*This field is required!",
      });
    }
  };

  const validate = () => {
    if (emailRegex.test(email)) {
      return true;
    } else {
      setError({
        helperText: "Field Must Contain Valid Email",
        errorStatus: true,
      });
      return false;
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isUserOnline) {
      dispatch(toggleOfflineModal(!isUserOnline));
      return;
    }
    if (validate() && !errorInFailure.errorStatus) {
      const successCB = () => {
        setEmail("");
        setShow(true);
      };
      const FailureCB = (message) => {
        setFailureError({
          errorStatus: true,
          helperText: message,
        });
      };
      dispatch(forgetPassword({ email }, successCB, FailureCB));
    }
  };

  const classes = useStyles();
  const { errorStatus, helperText } = error;
  const { loading } = reducerState;
  let instruction = (
    <Typography variant="body1" className={classes.instruction} color="primary">
      we have sent an email with reset password link to your register email
      please check your email and reset password.
    </Typography>
  );

  let errorInResponse = errorInFailure.errorStatus ? (
    <div className={classes.reponseError}>
      <Typography variant="body1">{errorInFailure.helperText}</Typography>{" "}
    </div>
  ) : null;

  return (
    <Grid container className={classes.pageContainer}>
      <Grid item xs={12}>
        <AuthImage />
      </Grid>
      <Grid item xs={12} className={classes.preLoginContentWrapper}>
        <div className={classes.containerWrapper}>
          <Typography
            variant="h1"
            component="p"
            className={classes.heading}
            color="primary"
            align="center"
          >
            Reset Password
          </Typography>
          <AppLogo />
          <form className={classes.formWrapper} onSubmit={handleSubmit}>
            <div className={classes.fields}>
              <InputLabel className={classes.customLabel}>Email</InputLabel>
              <TextField
                type="email"
                name="email"
                variant="outlined"
                className={styles.primaryInput}
                placeholder="Enter Email Address"
                size="small"
                value={email}
                onChange={handleChange}
                error={errorStatus}
                helperText={helperText}
                required
              />
            </div>
            {errorInResponse}
            <div className={classes.buttonWrapper}>
              <Button
                fullWidth
                type="submit"
                color="primary"
                variant="contained"
                disabled={loading}
              >
                <Loader show={loading} />
                {!loading ? " Reset Password" : null}
              </Button>
            </div>
          </form>
        </div>
        <RedirectComponent text="Back To" path="/" name="Login" />
        {show ? instruction : null}
      </Grid>
    </Grid>
  );
};

export default ForgetPassword;
