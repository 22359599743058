import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import UserDetail from "components/UserDetail";
import { Typography } from "@material-ui/core";
import styles from "assets/css/commonstyle.module.css";
import Grid from "@material-ui/core/Grid";
import VerificationIcon from "assets/images/svg/verification-blue.svg";
import UserKeyIcon from "assets/images/svg/user-key-blue.svg";
import UserGroupIcon from "assets/images/svg/user-group-blue.svg";
import BlockIcon from "assets/images/svg/block-blue.svg";
import CardBackground from "assets/images/svg/card-background.svg";
import { useDispatch, useSelector } from "react-redux";
import { getTotalCredentialsStats } from "./Insights.action";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  Main: {
    height: "100vh",
    background: "#083f85",
  },
  content: {
    background: "#f6f6f6",
    zIndex: 2,
    position: "relative",
    borderRadius: "15px 15px 0 0",
    padding: "20px 30px 20px 20px",
    margin: "auto 10px ",
    [theme.breakpoints.down("sm")]: {
      margin: "auto ",
    },
  },

  /***** analytics cards style ***/
  analyticsCounts: {
    color: " #ffffff",
    fontSize: 39,
    fontWeight: "bold",
    padding: "30px 0 5px",
  },
  analyticsCardsWrapper: {
    margin: "40px 0 0",
  },
  analyticsTitle: {
    color: "#ffffff",
    fontWeight: 400,
  },
  analyticsCard: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    borderRadius: 12,
    position: "relative",
    zIndex: 1,
    padding: "0 20px",
    height: 170,
    margin: "20px 0",
    "&:after": {
      content: `""`,
      position: "absolute",
      width: "100%",
      height: "100%",
      background: `url(${CardBackground})`,
      top: 0,
      left: 0,
      zIndex: "-1",
      backgroundPosition: "center center",
    },
  },
  blueCard: {
    backgroundImage: "linear-gradient(234deg, #4fc1e9 98%, #3bafda 40%)",
  },
  darkBlueCard: {
    backgroundImage: "linear-gradient(234deg, #5d9ece 98%, #4a89dc 40%)",
  },
  redCard: {
    backgroundImage: "linear-gradient(234deg, #fc6e51 99%, #e9573f 40%)",
  },
  yellowCard: {
    backgroundImage: "linear-gradient(235deg, #ffce54 99%, #f6bb42 40%)",
  },
  cardContent: {
    textAlign: "center",
    width: "100%",
  },
  cardIconsWrapper: {
    backgroundColor: "#ffffff",
    borderRadius: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 60,
    height: 60,
    flex: "0 0 60px",
    position: "absolute",
    top: "-20%",
    left: "50%",
    transform: "translateX(-50%)",
  },
  blueIcon: {
    border: "2px solid #2fa1cb",
  },
  darkBlueIcon: {
    border: "2px solid #377bd5",
  },
  redIcon: {
    border: "2px solid #e34f36",
  },
  yellowIcon: {
    border: "2px solid #f5b42f",
  },
  cardIcon: {
    lineHeight: 0,
    width: 30,
    "& img": {
      width: "100%",
      height: "auto",
    },
  },
}));

function Insights() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const holderDetails = useSelector(
    (state) => state.common.holderDetails.response
  );
  const totalCredentialsStats = useSelector(
    (state) => state.insights.totalCredentialsStats
  );

  useEffect(() => {
    dispatch(getTotalCredentialsStats());
  }, [dispatch]);

  const handleRedirect = (event) => {
    history.push("/credentials");
  };

  let { response } = totalCredentialsStats;

  return (
    <div className={classes.Main}>
      <UserDetail name={holderDetails?.name} />
      <div className={classes.content}>
        <Typography className={styles.pageTitle}>Insights</Typography>
        <Grid container spacing={2} className={classes.analyticsCardsWrapper}>
          <Grid item xs={6} sm={6}>
            <div
              className={`${[classes.analyticsCard, classes.blueCard].join(
                " "
              )}`}
              aria-hidden="true"
              onClick={handleRedirect}
            >
              <div className={classes.cardContent}>
                <Typography variant="h2" className={classes.analyticsCounts}>
                  {response["Credentials Verified"]}
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.analyticsTitle}
                >
                  Credentials Verified
                </Typography>
              </div>
              <div
                className={`${[classes.cardIconsWrapper, classes.blueIcon].join(
                  " "
                )}`}
              >
                <i className={classes.cardIcon}>
                  <img src={VerificationIcon} alt="Verification" />
                </i>
              </div>
            </div>
          </Grid>
          <Grid item xs={6} sm={6}>
            <div
              className={[classes.analyticsCard, classes.darkBlueCard].join(
                " "
              )}
              aria-hidden="true"
              onClick={handleRedirect}
            >
              <div className={classes.cardContent}>
                <Typography variant="h2" className={classes.analyticsCounts}>
                  {response["Credentials Issued"]}
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.analyticsTitle}
                >
                  Credentials Issued
                </Typography>
              </div>
              <div
                className={`${[
                  classes.cardIconsWrapper,
                  classes.darkBlueIcon,
                ].join(" ")}`}
              >
                <i className={classes.cardIcon}>
                  <img src={UserKeyIcon} alt="User key" />
                </i>
              </div>
            </div>
          </Grid>
          <Grid item xs={6} sm={6}>
            <div
              className={`${[classes.analyticsCard, classes.redCard].join(
                " "
              )}`}
              aria-hidden="true"
              onClick={handleRedirect}
            >
              <div className={classes.cardContent}>
                <Typography variant="h2" className={classes.analyticsCounts}>
                  {response["Issuers"]}
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.analyticsTitle}
                >
                  Issuers
                </Typography>
              </div>
              <div
                className={`${[classes.cardIconsWrapper, classes.redIcon].join(
                  " "
                )}`}
              >
                <i className={classes.cardIcon}>
                  <img src={UserGroupIcon} alt="User group" />
                </i>
              </div>
            </div>
          </Grid>
          <Grid item xs={6} sm={6}>
            <div
              className={`${[classes.analyticsCard, classes.yellowCard].join(
                " "
              )}`}
              aria-hidden="true"
              onClick={handleRedirect}
            >
              <div className={classes.cardContent}>
                <Typography variant="h2" className={classes.analyticsCounts}>
                  {response["Credentials Revoked"]}
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.analyticsTitle}
                >
                  Credentials Revoked
                </Typography>
              </div>
              <div
                className={`${[
                  classes.cardIconsWrapper,
                  classes.yellowIcon,
                ].join(" ")}`}
              >
                <i className={classes.cardIcon}>
                  <img src={BlockIcon} alt="Block" />
                </i>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Insights;
