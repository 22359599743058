import { Button, Dialog, DialogActions, makeStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import ImageCropper from "components/ImageCropper";
import Loader from "components/Loader/Loader";
import { previewCredential } from "pages/credentials/Credentials.action";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toBase64 } from "utils";
import { addCredentialImage } from "../Credentials.action";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    padding: "15px",
    alignItems: "center",
    borderRadius: "10px",
    minHeight: "300px",
  },
  dialogTitle: {
    paddingLeft: "12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  fileInput: {
    border: "solid 0.5px #9f9f9f",
    borderRadius: "4px",
    padding: "5px 10px",
  },
  fileInputLable: {
    fontSize: "15px",
    fontWeight: "500",
    color: "#083f85",
    marginBottom: "0",
    textTransform: "capitalize",
    "& span": {
      color: "#f00",
    },
  },
  fieldError: {
    color: "red",
    fontSize: "12px",
    marginTop: "6px",
  },
  fileName: {
    fontSize: "15px",
    color: "#616161",
  },
  thumbInner: {
    width: "200px",
    marginTop: "10px",
    position: "relative",
  },
  thumbButton: {
    position: "absolute",
    right: 10,
    bottom: 10,
    background: "rgba(0,0,0,.8)",
    color: "#fff",
    border: 0,
    borderRadius: ".325em",
    cursor: "pointer",
  },
  img: {
    display: "block",
    width: "100%",
    height: "100%",
  },
  thumb: {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    padding: 4,
    boxSizing: "border-box",
  },
  tabRoot: {
    fontSize: "12px",
    padding: "6px 5px",
  },
  buttonGen: {
    width: 150,
    marginRight: 20,
    "&:last-child": {
      marginRight: 0,
    },
    [theme.breakpoints.down("sm")]: {
      width: 80,
      marginRight: 10,
      padding: "3px 0",
    },
  },
  cancel: {
    backgroundColor: "#f6f6f6",
    border: "solid 1px #d5d5d5",
  },
  buttonContainer: {
    display: "flex",
    gap: "10px",
    justifyContent: "center",
  },
  customizeDefault: {
    justifyContent: "center",
  },
}));

const IMAGE_MAX_SIZE = 1000000;
const fileTypes = ["image/jpeg", "image/jpg", "image/png"];
const aspectRatios = {
  0: 16 / 9,
  1: 1,
  2: 4 / 5,
};

export default function CredentialImageModal({
  openDialog,
  handleClose,
  awardImage,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  let { credential } = location.state;
  const [image, setImage] = useState();
  const [croppedImage, setCroppedImage] = useState();
  const [imageSrc, setImageSrc] = useState("");
  const [tab, setTab] = useState(1);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [crop, setCrop] = useState({
    unit: "%",
    aspect: aspectRatios[tab],
  });
  const { loading } = useSelector(
    (state) => state.credential.addCredentialImage
  );
  const isAwardImageEditable = awardImage?.is_holder_editable;

  useEffect(() => {
    if (image) {
      setFileAndOpenCropper(image);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  useEffect(
    () => () => {
      URL.revokeObjectURL(image?.preview);
    },
    [image]
  );

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
    setCrop({ unit: "%", aspect: aspectRatios[newValue] });
  };

  const setFileAndOpenCropper = (file) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setImageSrc(reader.result);
      setOpen(true);
    });
    reader.readAsDataURL(file);
  };

  const handleEdit = (ev, file) => {
    ev.preventDefault();
    ev.stopPropagation();
    setFileAndOpenCropper(file);
  };

  const handleCroppedImage = (image) => {
    setCroppedImage(image);
  };

  const handleImageChange = async (ev) => {
    let files = ev.target.files[0];
    if (!files) return;
    if (files?.size > IMAGE_MAX_SIZE) {
      setError("The file size should be less than 1 MB.");
      return;
    } else if (!fileTypes.includes(files?.type)) {
      setError("The file should be in .png or .jpeg format only.");
      return;
    }
    Object.assign(files, {
      preview: URL.createObjectURL(files),
    });
    setError("");
    setImage(files);
  };

  const submitCredentialImage = async () => {
    if (error) return;
    const fieldName = awardImage?.fname;
    const credentialId = credential.id;
    let credentialImage = await toBase64(croppedImage ?? image);

    const payload = {
      additional_record_slug: JSON.stringify({
        [fieldName]: credentialImage,
      }),
    };

    const successCB = () => {
      dispatch(previewCredential({ cred_id: credentialId }));
      handleClose();
    };

    dispatch(addCredentialImage(credentialId, payload, successCB));
  };

  const defaultPreview = awardImage && !image && (
    <div className={classes.thumb}>
      <div className={classes.thumbInner}>
        <img
          src={awardImage?.fvalue}
          className={classes.img}
          alt={awardImage?.fname}
        />
      </div>
    </div>
  );

  const imagePreview = image && (
    <div className={classes.thumb}>
      <div className={classes.thumbInner}>
        <img
          src={croppedImage?.preview ? croppedImage?.preview : image?.preview}
          className={classes.img}
          alt={image.name}
        />
        <button
          type="button"
          className={classes.thumbButton}
          onClick={(ev) => handleEdit(ev, image)}
        >
          Edit
        </button>
      </div>
    </div>
  );

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      onClick={(ev) => ev.stopPropagation()}
      fullWidth
    >
      <div id="image-crop-box" className={classes.dialogTitle}>
        <Typography variant="h4" component="h4" color="primary">
          Credential Image
        </Typography>
        <IconButton
          className={classes.closeIconWrapper}
          onClick={handleClose}
          disabled={loading}
        >
          <CloseIcon color="primary" />
        </IconButton>
      </div>
      {isAwardImageEditable ? (
        <>
          <div className={classes.dialogWrapper}>
            <ImageCropper
              crop={crop}
              setCrop={setCrop}
              src={imageSrc}
              setSrc={setImageSrc}
              open={open}
              setOpen={setOpen}
              setFiles={handleCroppedImage}
            >
              {image && (
                <Paper square>
                  <Tabs
                    value={tab}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab
                      label="Horizontal (16/9)"
                      classes={{ root: classes.tabRoot }}
                    />
                    <Tab
                      label="Square (1/1)"
                      classes={{ root: classes.tabRoot }}
                    />
                    <Tab
                      label="Vertical (4/5)"
                      classes={{ root: classes.tabRoot }}
                    />
                  </Tabs>
                </Paper>
              )}
            </ImageCropper>
            <div>
              <div className={classes.fileInput}>
                <InputLabel
                  htmlFor="CredentialImage"
                  className={classes.fileInputLable}
                >
                  Choose Image
                  {image?.name && (
                    <Typography
                      variant="body1"
                      component="p"
                      className={classes.fileName}
                    >
                      {image?.name}
                    </Typography>
                  )}
                </InputLabel>
                <input
                  type="file"
                  hidden
                  id="CredentialImage"
                  accept=".png,.jpg,.jpeg"
                  size="1000000"
                  onChange={handleImageChange}
                />
              </div>
              {imagePreview}
              {defaultPreview}
              {error && (
                <Typography
                  variant="body1"
                  component="p"
                  className={classes.fieldError}
                >
                  {error}
                </Typography>
              )}
            </div>
          </div>

          <DialogActions className={classes.customizeDefault}>
            <div className={classes.buttonContainer}>
              <Button
                variant="contained"
                className={[classes.buttonGen, classes.cancel].join(" ")}
                onClick={handleClose}
                disabled={loading}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                className={classes.buttonGen}
                onClick={submitCredentialImage}
                disabled={loading || !image || !!error}
              >
                <Loader show={loading} />
                {!loading ? "Save" : null}
              </Button>
            </div>
          </DialogActions>
        </>
      ) : (
        defaultPreview
      )}
    </Dialog>
  );
}
