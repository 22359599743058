import { IconButton, Typography, makeStyles } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import React from "react";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  headingWrapper: {
    paddingTop: 20,
    paddingLeft: 30,
    display: "flex",
    alignItems: "center",
  },
  iconWrapper: {
    width: 36,
    height: 36,
    borderRadius: "50%",
    backgroundColor: "#fff",
    paddingLeft: 20,
  },
  title: {
    fontWeight: "bold",
    color: "#fff",
    paddingLeft: 18,
  },
}));

function Header({ title }) {
  const classes = useStyles();
  const history = useHistory();
  const handleGoBack = (event) => {
    history.goBack();
  };

  return (
    <div className={classes.headingWrapper}>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="close"
        className={classes.iconWrapper}
        onClick={handleGoBack}
      >
        <ArrowBackIosIcon color="primary" />
      </IconButton>
      <Typography variant="h4" component="span" className={classes.title}>
        {title}
      </Typography>
    </div>
  );
}

export default React.memo(Header);
