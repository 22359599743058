import authRoles from "core/auth/authRoles";
import NoMatch from "./NoMatch";

const noMatchConfig = {
  component: NoMatch,
  path: "*",
  layout: {
    config: {
      navbar: {
        display: false,
      },
      sidebar: {
        display: false,
      },
      footer: {
        display: false,
      },
    },
  },
  protected: true,
  auth: authRoles.onlyGuest,
};

export default noMatchConfig;
