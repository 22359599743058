import { http, headers } from "utils";
import {
  GET_TOTAL_CREDENTIALS_STATS_BEGIN,
  GET_TOTAL_CREDENTIALS_STATS_SUCCESS,
  GET_TOTAL_CREDENTIALS_STATS_FAILURE,
} from "./Insights.constant";

const getTotalCredentialsStatsBegin = () => ({
  type: GET_TOTAL_CREDENTIALS_STATS_BEGIN,
});

const getTotalCredentialsStatsSuccess = (payload) => ({
  type: GET_TOTAL_CREDENTIALS_STATS_SUCCESS,
  payload,
});
const getTotalCredentialsStatsFailure = (payload) => ({
  type: GET_TOTAL_CREDENTIALS_STATS_FAILURE,
  payload,
});

export const getTotalCredentialsStats = () => (dispatch) => {
  dispatch(getTotalCredentialsStatsBegin());
  http("get", "/holder/stats", null, { headers }, true)
    .then((response) => {
      dispatch(getTotalCredentialsStatsSuccess(response.data.data));
    })
    .catch((error) => {
      dispatch(getTotalCredentialsStatsFailure(error.response?.data?.message));
    });
};
