import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Banner from "assets/images/about/banner.svg";
import Logo from "assets/images/svg/everycred-full-logo.svg";
import Secure from "assets/images/about/secure.svg";
import Private from "assets/images/about/private.svg";
import Immutable from "assets/images/about/immutable.svg";
import Scalable from "assets/images/about/scalable.svg";
import WhyChoose from "assets/images/about/why-choose.svg";
import Business from "assets/images/about/business.svg";
import Institution from "assets/images/about/institution.svg";
import Organization from "assets/images/about/organization.svg";
import Company from "assets/images/about/company.svg";
import Typography from "@material-ui/core/Typography";
import Header from "components/BackButtonHeader/BackButtonHeader";

const useStyles = makeStyles((theme) => ({
  main: {
    padding: "20px",
    // backgroundColor: "#f6f6f6",
    backgroundColor: "#ffffff",
    marginTop: -10,
    borderRadius: "20px 20px 0 0",
  },
  banner: {
    textAlign: "center",
    margin: "0 auto",
  },
  logo: {
    width: "60%",
    margin: "75px 0 0 0",
  },
  title: {
    margin: "20px 0 20px 0",
    fontWeight: 700,
  },
  description: {
    margin: "0 0 20px 0",
    fontWeight: 600,
    opacity: 0.8,
  },
  category: {
    display: "flex",
    alignItems: "center",
    marginTop: "30px",
    maxWidth: "350px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  icon: {
    width: 68,
    height: 68,
    border: "8px solid #eee",
    borderRadius: "50%",
    padding: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 20,
    maxHeight: "84px",
  },
  footerTitle: {
    wordBreak: "break-all",
    overflow: "hidden",
    fontWeight: 600,
  },
  headerBackground: {
    background: "#fff",
    paddingBottom: 10,
    position: "fixed",
    maxWidth: "1024px",
    [theme.breakpoints.up("md")]: {
      maxWidth: 768,
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    "& span": {
      color: "#083f85",
      flexGrow: 1,
    },
    "& button": {
      background: "#f6f6f6",
    },
  },
}));

function About() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.headerBackground}>
        <Header title="About" />
      </div>
      <div className={classes.main}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <img src={Logo} alt="logo" className={classes.logo} />
            <Typography
              variant="h2"
              fontWeight="bold"
              color="primary"
              className={classes.title}
            >
              Exemplary Platform for Generating Blockchain Credentials.
            </Typography>
            <Typography
              variant="subtitle2"
              color="secondary"
              style={{ width: "90%" }}
              className={classes.description}
            >
              We provide a highly flexible platform for creating, issuing,
              viewing and verifying blockchain based Credentials.​ Credentials
              are stored in an immutable Blockchain network which are
              cryptographically signed, and tamper proof.​
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} className={classes.banner}>
            <img src={Banner} alt="banner" width="83%" />
          </Grid>
        </Grid>
        <div className={classes.characteristics}>
          <Typography variant="h2" color="primary" className={classes.title}>
            Characteristics
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6} style={{ textAlign: "center" }}>
              <img src={Secure} alt="secure" width="50%" />
              <Typography
                variant="h4"
                color="primary"
                className={classes.title}
              >
                Secure
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "center" }}>
              <img src={Immutable} alt="immutable" width="50%" />
              <Typography
                variant="h4"
                color="primary"
                className={classes.title}
              >
                Immutable
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "center" }}>
              <img src={Private} alt="private" width="50%" />
              <Typography
                variant="h4"
                color="primary"
                className={classes.title}
              >
                Private
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "center" }}>
              <img src={Scalable} alt="scalable" width="50%" />
              <Typography
                variant="h4"
                color="primary"
                className={classes.title}
              >
                Scalable
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div className={classes.whyChoose}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant="h2"
                color="primary"
                className={classes.title}
              >
                Why should you choose us?
              </Typography>
              <Typography
                variant="subtitle2"
                color="secondary"
                className={classes.description}
              >
                We provide a platform which is the suitable for multiple
                enterprises like businesses, institutions, organizations,
                companies to generate Credentials and place them in a blockchain
                network. We ensure the security of our users by providing a
                transparent trusted platform.
              </Typography>
              <Typography
                variant="subtitle2"
                color="secondary"
                className={classes.description}
              >
                Our platform will enable its users to generate Credentials in
                bulk and verify all the Credentials at a single instance. It
                will provide a certainty to the enterprises for generating
                signed Credentials for their registered users with the
                information provided by them.​
              </Typography>
              <Grid container spacing={0}>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <div className={classes.category}>
                    <div className={classes.icon}>
                      <img src={Business} alt="business" />
                    </div>
                    <Typography
                      variant="h3"
                      color="primary"
                      className={classes.footerTitle}
                    >
                      Business
                    </Typography>
                  </div>
                  <div className={classes.category}>
                    <div className={classes.icon}>
                      <img src={Organization} alt="organization" />
                    </div>
                    <Typography
                      variant="h3"
                      color="primary"
                      className={classes.footerTitle}
                    >
                      Organizations
                    </Typography>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  className={classes.alignItemsRight}
                >
                  <div className={classes.category}>
                    <div className={classes.icon}>
                      <img src={Institution} alt="institution" />
                    </div>
                    <Typography
                      variant="h3"
                      color="primary"
                      className={classes.footerTitle}
                    >
                      Institutions
                    </Typography>
                  </div>
                  <div className={classes.category}>
                    <div className={classes.icon}>
                      <img src={Company} alt="company" />
                    </div>
                    <Typography
                      variant="h3"
                      color="primary"
                      className={classes.footerTitle}
                    >
                      Companies
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className={classes.banner}>
              <img src={WhyChoose} alt="why-choose" width="80%" />
            </Grid>
          </Grid>
        </div>
      </div>
    </React.Fragment>
  );
}

export default About;
