import { Button } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import React from "react";
import { useDispatch } from "react-redux";
import { openNotification } from "store/commonActions";

const CopyButton = ({ color, content }) => {
  const useStyles = makeStyles((theme) => ({
    copyBtn: {
      color: "#ffffff",
      minWidth: 0,
      "& span": {
        padding: "0 !important",
      },
    },
  }));
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleCopy = (event, content) => {
    navigator.clipboard.writeText(content);
    dispatch(
      openNotification({
        message: "Holder DID Copied!",
        hideDuration: 2000,
      })
    );
  };

  return (
    <Button
      onClick={(event) => handleCopy(event, content)}
      className={classes.copyBtn}
    >
      <FileCopyIcon style={{ fontSize: 14, color }} />
    </Button>
  );
};

export default React.memo(CopyButton);
