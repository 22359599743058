import forgetPasswordReducer from "pages/ForgetPassword/ForgetPassword.reducer";
import insightsReducer from "pages/Insights/Insights.reducer";
import loginReducer from "pages/Login/login.reducer";
import NotificationReducer from "pages/Notification/Notification.reducer";
import profileReducer from "pages/Profile/profile.reducer";
import registerReducer from "pages/Register/components/register.reducer";
import resetPasswordReducer from "pages/ResetPassword/resetPassword.reducer";
import verifyCredentialsReducer from "pages/VerifyCredentials/verifyCredentials.reducer";
import verifyEmailReducer from "pages/VerifyEmail/verifyEmail.reducer";
import CredentialReducer, {
  CredentialsActionReducer,
} from "pages/credentials/Credentials.reducer";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import commonReducer from "./commonReducer";

const appReducer = combineReducers({
  common: commonReducer,
  register: registerReducer,
  login: loginReducer,
  forgetPassword: forgetPasswordReducer,
  resetPassword: resetPasswordReducer,
  insights: insightsReducer,
  credential: CredentialReducer,
  credentialsAction: CredentialsActionReducer,
  profile: profileReducer,
  verifyCredentials: verifyCredentialsReducer,
  verifyEmail: verifyEmailReducer,
  notification: NotificationReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_USER") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};
const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;
