import authRoles from "core/auth/authRoles";
import ResetPassword from "./ResetPassword";

const resetPasswordConfig = {
  component: ResetPassword,
  path: "/reset/:token",
  exact: true,
  layout: {
    config: {
      header: {
        display: false,
      },
      sidebar: {
        display: false,
      },
      footer: {
        display: false,
      },
    },
  },
  protected: false,
  auth: authRoles.anyOne,
};

export default resetPasswordConfig;
