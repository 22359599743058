import authRoles from "core/auth/authRoles";
import Insights from "./Insights";

const InsightsConfig = {
  component: Insights,
  path: "/insights",
  exact: true,
  layout: {
    config: {
      header: {
        display: true,
      },
      sidebar: {
        display: true,
      },
      footer: {
        display: false,
      },
    },
  },
  protected: true,
  auth: authRoles.onlyGuest,
};

export default InsightsConfig;
