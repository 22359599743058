import { http, headers } from "utils";
import {
  VERIFY_EMAIL_BEGIN,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILURE,
} from "./verfiyEmail.constant";

const verifyEmailBegin = () => ({
  type: VERIFY_EMAIL_BEGIN,
});
const verifyEmailSuccess = (payload) => ({
  type: VERIFY_EMAIL_SUCCESS,
  payload,
});
const verifyEmailFailure = (payload) => ({
  type: VERIFY_EMAIL_FAILURE,
  payload,
});

export const verifyEmail = (params) => (dispatch) => {
  dispatch(verifyEmailBegin());
  http("put", "/holder/email/verify", null, { headers, params })
    .then(function (response) {
      dispatch(verifyEmailSuccess(response?.data?.message));
    })
    .catch(function (error) {
      dispatch(verifyEmailFailure(error.response?.data?.message));
    });
};
