import axios from "axios";
import { openNotification } from "store/commonActions";
import { deleteCookie, getCookie } from "utils";

axios.defaults.baseURL = process.env.REACT_APP_API_HOST;

export const headers = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "*",
  "Content-Type": "application/json",
};
export const getQueryString = (params) =>
  Object.keys(params)
    .map((key) => `${key}=${encodeURIComponent(params[key])}`)
    .join("&");

export default function setupInterceptors(store, history) {
  let newSocket = store.getState().common.newSocket;
  const { dispatch } = store;
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (
        error.response?.status === 401 &&
        error.response?.data?.detail === "Provided token is not valid"
      ) {
        deleteCookie("rememberMe");
        deleteCookie("accessToken");
        history.push("/");
        dispatch(
          openNotification({
            message: "Unauthorized! login again",
            severity: "error",
          })
        );
        newSocket?.close?.();
      }
      return Promise.reject(error);
    }
  );
}

export const http = (
  method = "GET",
  url = "",
  payload = {},
  options = {},
  token = false
) => {
  if (token) {
    const access_token = getCookie("accessToken");
    options.headers["Authorization"] = `Bearer ${access_token}`;
  }
  const request = {
    method,
    url,
    data: payload,
    ...options,
  };
  return new Promise((resolve, reject) => {
    axios(request)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
