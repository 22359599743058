import moment from "moment";

export const getDate = (dateString) => {
  const newDate = new Date(dateString + "Z");
  return moment(newDate).local().format("D MMMM YYYY");
};

export const getTime = (dateString) => {
  const newDate = new Date(dateString + "Z");
  return moment(newDate).local().format("LT");
};
