import {
  UPDATE_USER_PROFILE_BEGIN,
  UPDATE_USER_PROFILE_FAILURE,
  UPDATE_USER_PROFILE_SUCCESS,
  CHANGE_USER_PASSWORD_BEGIN,
  CHANGE_USER_PASSWORD_SUCCESS,
  CHANGE_USER_PASSWORD_FAILURE,
  CLEAR_UPDATE_USER_PROFILE_RESPONSE,
} from "./profile.constant";

const initState = {
  updateUserProfile: {
    loading: false,
    error: "",
    response: {},
  },
  changePassword: {
    loading: false,
    error: "",
    response: {},
  },
};

export default function profileReducer(state = initState, action) {
  switch (action.type) {
    case UPDATE_USER_PROFILE_BEGIN:
      return {
        ...state,
        updateUserProfile: {
          ...state.updateUserProfile,
          loading: true,
          response: "",
          error: "",
        },
      };
    case UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        updateUserProfile: {
          ...state.updateUserProfile,
          loading: false,
          response: action.payload,
          error: "",
        },
      };
    case UPDATE_USER_PROFILE_FAILURE:
      return {
        ...state,
        updateUserProfile: {
          ...state.updateUserProfile,
          loading: false,
          error: action.payload,
        },
      };
    case CLEAR_UPDATE_USER_PROFILE_RESPONSE:
      return {
        ...state,
        updateUserProfile: {
          ...state.updateUserProfile,
          response: "",
          loading: false,
          error: "",
        },
      };
    case CHANGE_USER_PASSWORD_BEGIN:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          loading: true,
        },
      };
    case CHANGE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          loading: false,
          response: action.payload,
          error: "",
        },
      };
    case CHANGE_USER_PASSWORD_FAILURE:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          loading: false,
          response: "",
          error: action.payload,
        },
      };
    default:
      return state;
  }
}
