import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as VerifyIcon } from "assets/images/svg/show.svg";
import { useDispatch, useSelector } from "react-redux";
import { toggleOfflineModal } from "store/commonActions";

const useStyles = makeStyles({
  actionBtn: {
    width: 30,
    minWidth: "auto",
    borderRadius: "50%",
    height: 30,
    backgroundColor: "#083f85",
    "&:hover": {
      backgroundColor: "#083f85",
    },
  },
});

function PreviewCredential({ link }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isUserOnline = useSelector((state) => state.common.isUserOnline);
  const handleClick = (event) => {
    event.stopPropagation();
    if (!isUserOnline) {
      dispatch(toggleOfflineModal(!isUserOnline));
      return;
    }
    window.open(`${link}`, "_blank", "noopener,noreferrer");
  };
  return (
    <Button className={classes.actionBtn} onClick={handleClick}>
      <VerifyIcon />
    </Button>
  );
}

export default PreviewCredential;
