import {
  InputLabel,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import PreviewCredentialSkeleton from "components/Skeletons/Preview";
import moment from "moment";
import { previewCredential } from "pages/credentials/Credentials.action";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toggleOfflineModal } from "store/commonActions";

const useStyles = makeStyles((theme) => ({
  actionWrapper: {
    paddingTop: 30,
    "& span": {
      "&:first-child": {
        fontWeight: "bold",
      },
    },
  },
  formWrapper: {
    padding: "20px 0",
  },
  wrapWord: { wordWrap: "break-word" },
  customLabel: {
    fontSize: 12,
    fontWeight: 600,
    minWidth: 85,
    maxWidth: 85,
    marginRight: 20,
    marginBottom: 0,
    textTransform: "capitalize",
  },
  formField: {
    marginBottom: 15,
    display: "flex",
    alignItems: "center",
    "&:last-child": {
      marginBottom: 0,
    },
    "& .MuiFormControl-root": {
      flexGrow: 1,
    },
    "& input": {
      height: "35px !important",
      fontSize: "12px !important",
      background: "#f6f6f6",
      minWidth: 180,
      borderRadius: 6,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiOutlinedInput-root": {
      marginBottom: 0,
      padding: 0,
    },
  },
}));

export default function Preview() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  let { credential } = location.state;

  const previewCredentialState = useSelector(
    (state) => state.credential.previewCredential
  );
  const credentialDetails =
    previewCredentialState.response?.credential_data ?? [];

  const isUserOnline = useSelector((state) => state.common.isUserOnline);

  useEffect(() => {
    if (
      !previewCredentialState.loading &&
      !credentialDetails?.length &&
      !isUserOnline
    )
      dispatch(toggleOfflineModal(!isUserOnline));
  });

  useEffect(() => {
    dispatch(previewCredential({ cred_id: credential.id }));
  }, [dispatch, credential.id]);

  const getFormattedValue = (item) => {
    switch (item.ftype) {
      case "datetime": {
        const isValid = moment(item.fvalue).isValid();
        const value = item.fvalue
          ? moment(item.fvalue).format("DD MMMM yyyy - LT")
          : "-";
        return isValid ? value : item.fvalue || "-";
      }
      case "date": {
        const valid = moment(item.fvalue).isValid();
        const value = item.fvalue
          ? moment(item.fvalue).format("DD MMMM yyyy")
          : "-";
        return valid ? value : item.fvalue || "-";
      }
      default:
        return item.fvalue.toString() || "-";
    }
  };

  const credentialFields = credentialDetails?.map((item) => {
    return (
      <div className={classes.formField} key={item?.fname}>
        <InputLabel
          className={[classes.customLabel, classes.wrapWord].join(" ")}
          htmlFor="name"
        >
          {item?.fname.replaceAll("_", " ")}
        </InputLabel>
        <TextField
          value={getFormattedValue(item)}
          type="text"
          variant="outlined"
          size="small"
          name="name"
          margin="none"
          required
          disabled
          multiline
        />
      </div>
    );
  });

  return (
    <div className={classes.actionWrapper}>
      <Typography variant="h5" component="span" color="primary">
        Preview Credential
      </Typography>
      <form className={classes.formWrapper}>
        {previewCredentialState.loading ? (
          <>
            <PreviewCredentialSkeleton />
            <PreviewCredentialSkeleton />
            <PreviewCredentialSkeleton />
          </>
        ) : (
          <>{credentialFields}</>
        )}
      </form>
    </div>
  );
}
