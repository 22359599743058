export const GET_ISSUER_LIST_BEGIN = "GET_ISSUER_LIST_BEGIN";
export const GET_ISSUER_LIST_SUCCESS = "GET_ISSUER_LIST_SUCCESS";
export const GET_ISSUER_LIST_FAILURE = "GET_ISSUER_LIST_FAILURE";

export const GET_CREDENTIAL_LIST_BEGIN = "GET_CREDENTIAL_LIST_BEGIN";
export const GET_CREDENTIAL_LIST_SUCCESS = "GET_CREDENTIAL_LIST_SUCCESS";
export const GET_CREDENTIAL_LIST_FAILURE = "GET_CREDENTIAL_LIST_FAILURE";

export const SELECT_ISSUER = "SELECT_ISSUER";
export const SELECT_CREDENTIAL = "SELECT_CREDENTIAL";

export const GET_CREDENTIAL_PREVIEW_BEGIN = "GET_CREDENTIAL_PREVIEW_BEGIN";
export const GET_CREDENTIAL_PREVIEW_SUCCESS = "GET_CREDENTIAL_PREVIEW_SUCCESS";
export const GET_CREDENTIAL_PREVIEW_FAILURE = "GET_CREDENTIAL_PREVIEW_FAILURE";

export const LOAD_MORE_ISSUER_BEGIN = "LOAD_MORE_ISSUER_BEGIN";
export const LOAD_MORE_ISSUER_SUCCESS = "LOAD_MORE_ISSUER_SUCCESS";
export const LOAD_MORE_ISSUER_FAILURE = "LOAD_MORE_ISSUER_FAILURE";

export const CLEAR_CREDENTIAL_REDUCER = "CLEAR_CREDENTIAL_REDUCER";

export const CREDENTIAL_PREVIEW_BEGIN = "CREDENTIAL_PREVIEW_BEGINF";
export const CREDENTIAL_PREVIEW_SUCCESS = "CREDENTIAL_PREVIEW_SUCCESS";
export const CREDENTIAL_PREVIEW_FAILURE = "CREDENTIAL_PREVIEW_FAILURE";

export const SHARE_CREDENTIAL_BEGIN = "SHARE_CREDENTIAL_BEGIN";
export const SHARE_CREDENTIAL_SUCCESS = "SHARE_CREDENTIAL_SUCCESS";
export const SHARE_CREDENTIAL_FAILURE = "SHARE_CREDENTIAL_FAILURE";

export const DOWNLOAD_CREDENTIAL_BEGIN = "DOWNLOAD_CREDENTIAL_BEGIN";
export const DOWNLOAD_CREDENTIAL_SUCCESS = "DOWNLOAD_CREDENTIAL_SUCCESS";
export const DOWNLOAD_CREDENTIAL_FAILURE = "DOWNLOAD_CREDENTIAL_FAILURE";

export const GET_VERIFICATION_OVERVIEW_BEGIN =
  "GET_VERIFICATION_OVERVIEW_BEGIN";
export const GET_VERIFICATION_OVERVIEW_SUCCESS =
  "GET_VERIFICATION_OVERVIEW_SUCCESS";
export const GET_VERIFICATION_OVERVIEW_FAILURE =
  "GET_VERIFICATION_OVERVIEW_FAILURE";

export const GET_RECENTLY_VERIFIED_CREDENTIAL_BEGIN =
  "GET_RECENTLY_VERIFIED_CREDENTIAL_BEGIN";
export const GET_RECENTLY_VERIFIED_CREDENTIAL_SUCCESS =
  "GET_RECENTLY_VERIFIED_CREDENTIAL_SUCCESS";
export const GET_RECENTLY_VERIFIED_CREDENTIAL_FAILURE =
  "GET_RECENTLY_VERIFIED_CREDENTIAL_FAILURE";

export const GET_LAST_VERIFIED_DATE_BEGIN = "GET_LAST_VERIFIED_DATE_BEGIN";
export const GET_LAST_VERIFIED_DATE_SUCCESS = "GET_LAST_VERIFIED_DATE_SUCCESS";
export const GET_LAST_VERIFIED_DATE_FAILURE = "GET_LAST_VERIFIED_DATE_FAILURE";

export const ADD_CREDENTIAL_IMAGE_BEGIN = "ADD_CREDENTIAL_IMAGE_BEGIN";
export const ADD_CREDENTIAL_IMAGE_SUCCESS = "ADD_CREDENTIAL_IMAGE_SUCCESS";
export const ADD_CREDENTIAL_IMAGE_FAILURE = "ADD_CREDENTIAL_IMAGE_FAILURE";
