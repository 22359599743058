import { Button, makeStyles, Typography } from "@material-ui/core";
import {
  readNotification,
  setNewNotificationList,
} from "pages/Notification/Notification.action";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  wrapper: {
    paddingBottom: 12,
  },
  headingWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 5,
  },
  title: {
    fontWeight: "700",
  },
  read: {
    background: "transparent",
    border: "none",
    color: "#616161",
    fontSize: 12,
    fontWeight: 400,
  },
  notifications: {
    "& > div:last-child": {
      borderBottom: "none",
    },
  },
}));

function NotificationType({ title, read, children }) {
  const classes = useStyles();
  const { response } = useSelector(
    (state) => state.notification.notificationList
  );
  const dispatch = useDispatch();

  let isReadAllButtonDisable = response?.data?.find((item) => item.is_new);

  const handleReadAll = (event) => {
    const isNewList = response?.data.filter((item) => {
      if (item.is_new) {
        return item.notification_id;
      } else {
        return null;
      }
    });
    const idList = isNewList.map((item) => item.notification_id);

    const successCB = () => {
      const newList = response?.data.map((item) => ({
        ...item,
        is_new: false,
      }));
      let payload = {
        data: newList,
        pages: response?.pages,
        total: response?.total,
      };
      dispatch(setNewNotificationList(payload));
    };

    dispatch(
      readNotification({ notification_id: JSON.stringify(idList) }, successCB)
    );
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.headingWrapper}>
        <Typography
          variant="h5"
          component="span"
          color="primary"
          className={classes.title}
        >
          {title}
        </Typography>
        {read ? (
          <Button
            type="button"
            onClick={handleReadAll}
            className={classes.read}
            disabled={isReadAllButtonDisable ? false : true}
          >
            {read}
          </Button>
        ) : null}
      </div>
      <div className={classes.notifications}>{children}</div>
    </div>
  );
}

export default NotificationType;
