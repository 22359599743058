import React, { useCallback, useState } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as ShareIcon } from "assets/images/svg/share-icon.svg";
import ShareCredentialModal from "../ActionModals/ShareModal";

const useStyles = makeStyles({
  actionBtn: {
    width: 30,
    minWidth: "auto",
    borderRadius: "50%",
    height: 30,
    backgroundColor: "#083f85",
    "&:hover": {
      backgroundColor: "#083f85",
    },
  },
});

const emailInitState = {
  email: "",
  error: "",
};

function ShareCredentials({ record_id, share_link }) {
  const classes = useStyles();
  const [openShareModal, setOpenShareModal] = useState(false);
  const [emailState, setEmailState] = useState(emailInitState);
  const [credLink] = useState(share_link);
  const [copied, setCopied] = useState(false);

  const closeShareModal = useCallback((event) => {
    event.stopPropagation();
    setOpenShareModal(false);
    setEmailState(emailInitState);
    setCopied(false);
  }, []);

  const handleShareClick = (event) => {
    event.stopPropagation();
    setOpenShareModal(true);
  };

  return (
    <>
      <Button className={classes.actionBtn} onClick={handleShareClick}>
        <ShareIcon />
      </Button>
      <ShareCredentialModal
        open={openShareModal}
        share_link={share_link}
        handleClose={closeShareModal}
        emailState={emailState}
        setEmailState={setEmailState}
        credLink={credLink}
        record_id={record_id}
        copied={copied}
        setCopied={setCopied}
      />
    </>
  );
}

export default ShareCredentials;
