import { Grid } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  analyticsCardsWrapper: {
    padding: "20px 0",
  },
  analyticCard: {
    height: 67,
    borderRadius: 4,
  },
}));

export default function CardsSkeleton() {
  const classes = useStyles();
  return (
    <Grid container spacing={2} className={classes.analyticsCardsWrapper}>
      <Grid item xs={6} sm={6} md={2} lg={2}>
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.analyticCard}
        />
      </Grid>
      <Grid item xs={6} sm={6} md={2} lg={2}>
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.analyticCard}
        />
      </Grid>
      <Grid item xs={6} sm={6} md={2} lg={2}>
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.analyticCard}
        />
      </Grid>
      <Grid item xs={6} sm={6} md={2} lg={2}>
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.analyticCard}
        />
      </Grid>
      <Grid item xs={6} sm={6} md={2} lg={2}>
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.analyticCard}
        />
      </Grid>
      <Grid item xs={6} sm={6} md={2} lg={2}>
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.analyticCard}
        />
      </Grid>
    </Grid>
  );
}
