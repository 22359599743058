import { makeStyles } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

const useStyle = makeStyles((theme) => ({
  sidebarNavItem: {
    display: "flex",
    alignItems: "center",
    height: 54,
    width: 257,
    padding: "1.6px 3.6px 6.2px 22px",
    textDecoration: "none",
  },
  sidebarNavItemActive: {
    background: "#f6f6f6",
    height: 54,
    width: 254,
    position: "relative",
    "&::after": {
      position: "absolute",
      content: '""',
      top: 0,
      right: 0,
      height: "100%",
      borderRight: "3px solid #083f85",
    },
  },
  iconWrap: {
    height: 28,
    width: 28,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "100%",
    background: "#083f85",
  },
  sidebarNavItemIcon: {
    lineHeight: 0,
    "& svg": {
      height: "auto",
      width: "16px",
    },
  },
  itemDetailsContainer: {
    marginLeft: 10,
    display: "flex",
    flexDirection: "column",
  },
  sidebarNavItemText: {
    fontSize: 16,
    color: "#083f85",
    fontWeight: 500,
  },
  subtitle: {
    fontSize: 10,
    color: "#616161",
  },
  newWrapper: {
    position: "absolute",
    backgroundColor: "#083f85",
    color: "#fff",
    fontSize: 10,
    padding: "0px 6px 2px",
    borderRadius: 10,
    top: 15,
    right: 102,
  },
}));

function SidebarNavItem({ path, itemName, subtitle, icon, exact, showBadge }) {
  const classes = useStyle();
  const { response } = useSelector(
    (state) => state.notification.notificationList
  );

  let newList = response?.data?.filter((item) => item.is_new);
  let listLength =
    newList?.length > 9 ? newList?.length : `0${newList?.length}`;

  return (
    <NavLink
      className={classes.sidebarNavItem}
      to={path}
      exact={exact}
      activeClassName={classes.sidebarNavItemActive}
    >
      <div className={`${classes.iconWrap} nav-icon-wrap`}>
        <i className={`${classes.sidebarNavItemIcon}`}>{icon}</i>
      </div>
      <div className={`${classes.itemDetailsContainer}`}>
        <span className={classes.sidebarNavItemText}>{itemName}</span>
        <span className={classes.subtitle}>{subtitle}</span>
      </div>
      {showBadge && newList?.length ? (
        <span className={classes.newWrapper}>{listLength}</span>
      ) : null}
    </NavLink>
  );
}

export default React.memo(SidebarNavItem);
