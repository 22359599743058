import {
  RESET_PASSWORD_BEGIN,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
} from "./resetPassword.constant";
import { http, getQueryString } from "utils";

const resetPasswordBegin = () => ({
  type: RESET_PASSWORD_BEGIN,
});

const resetPasswordSuccess = (payload) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload,
});

const resetPasswordFailure = (payload) => ({
  type: RESET_PASSWORD_FAILURE,
  payload,
});

export const resetPassword =
  (data, token, successCB, FailureCB) => (dispatch) => {
    dispatch(resetPasswordBegin());

    http("post", `/holder/reset/${token}/submit`, getQueryString(data), {
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => {
        dispatch(resetPasswordSuccess(response.data.data));
        successCB();
      })
      .catch((err) => {
        dispatch(resetPasswordFailure(err.response?.data?.message));
        FailureCB(err.response?.data?.message);
      });
  };
