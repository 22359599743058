import authRoles from "core/auth/authRoles";
import Login from "./Login";

const LoginConfig = {
  component: Login,
  path: "/",
  exact: true,
  layout: {
    config: {
      header: {
        display: false,
      },
      sidebar: {
        display: false,
      },
      footer: {
        display: false,
      },
    },
  },
  protected: false,
  auth: authRoles.onlyGuest,
};

export default LoginConfig;
