import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Settings, {
  ReactComponent as SeetingsIcon,
} from "assets/images/svg/404-settings.svg";
import ErrorImage from "assets/images/404/error.png";
import { NavLink } from "react-router-dom";
import styles from "assets/css/commonstyle.module.css";

const useStyles = makeStyles((theme) => ({
  errorContainer: {
    display: "flex",
    width: "auto",
    height: "100vh",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "0 10px",
  },
  text: {
    textAlign: "center",
    paddingBottom: 20,
    "& p": {
      "&:first-child": {
        paddingBottom: 10,
      },
    },
  },
  imageContainer: {
    paddingBottom: 40,
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      maxWidth: 275,
      "& img": {
        "&:first-child": {
          width: "100%",
        },
      },
    },
  },
  pageNotFound: {
    border: "2px solid #2aa8e0",
    borderRadius: 10,
    padding: "10px 0",
    marginBottom: 40,
    fontSize: 30,
    color: "#083f85",
    fontWeight: 700,
    width: 300,
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: 20,
      width: 200,
      marginBottom: 30,
    },
  },
  goHome: {
    padding: "10px 0",
    fontSize: 18,
    backgroundColor: "#083f85",
    color: "#fff",
    fontWeight: 700,
    textDecoration: "none",
    borderRadius: 20,
    width: 200,
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
      width: 130,
    },
  },
  animationImage: {
    position: "absolute",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  big: {
    top: -25,
    right: -45,
  },
  mediumFirst: {
    width: 90,
    top: -42,
    left: 100,
  },
  mediumSecond: {
    width: 90,
    top: 100,
  },
  small: {
    width: 70,
    top: 60,
    left: 50,
  },
}));

export default function PageNotFound() {
  const classes = useStyles();
  return (
    <div className={classes.errorContainer}>
      {/* <div claaName={classes.errorWrapper}> */}
      <div className={classes.text}>
        <Typography variant="h2" component="p" color="primary">
          Oops!
        </Typography>
        <Typography variant="h1" component="p" color="primary">
          404 Error
        </Typography>
      </div>
      <div className={classes.imageContainer}>
        <img src={ErrorImage} alt="error" />
        <SeetingsIcon
          src={Settings}
          alt="settings"
          className={[
            classes.animationImage,
            classes.big,
            styles.animationSpin,
          ].join(" ")}
        />
        <SeetingsIcon
          src={Settings}
          alt="settings"
          className={[
            classes.animationImage,
            classes.mediumFirst,
            styles.animationSpin,
          ].join(" ")}
        />
        <SeetingsIcon
          src={Settings}
          alt="settings"
          className={[
            classes.animationImage,
            classes.mediumSecond,
            styles.animationSpin,
          ].join(" ")}
        />
        <SeetingsIcon
          src={Settings}
          alt="settings"
          className={[
            classes.animationImage,
            classes.small,
            styles.animationSpin,
          ].join(" ")}
        />
      </div>
      <div className={classes.pageNotFound}>Page Not Found</div>
      <NavLink to="/" className={classes.goHome}>
        Go To Home{" "}
      </NavLink>
    </div>
  );
}
