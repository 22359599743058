import {
  FORGET_PASSWORD_BEGIN,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAIL,
} from "./ForgetPassword.constant";

import { http, headers } from "utils";
const forgetPasswordBegin = () => ({ type: FORGET_PASSWORD_BEGIN });

const forgetPasswordSuccess = (payload) => ({
  type: FORGET_PASSWORD_SUCCESS,
  payload,
});

const forgetPasswordFail = (payload) => ({
  type: FORGET_PASSWORD_FAIL,
  payload,
});

export const forgetPassword = (data, successCB, FailureCB) => (dispatch) => {
  dispatch(forgetPasswordBegin());
  http("post", "/holder/forget", data, { headers })
    .then((response) => {
      dispatch(forgetPasswordSuccess(response.data));
      successCB();
    })
    .catch((err) => {
      dispatch(forgetPasswordFail(err.response?.data?.message));
      FailureCB(err.response?.data?.message);
    });
};
