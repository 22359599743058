import authRoles from "core/auth/authRoles";
import About from "./About";

const AboutConfig = {
  component: About,
  path: "/about",
  exact: true,
  layout: {
    config: {
      header: {
        display: false,
      },
      sidebar: {
        display: false,
      },
      footer: {
        display: false,
      },
    },
  },
  protected: true,
  auth: authRoles.onlyGuest,
};

export default AboutConfig;
