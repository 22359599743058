import React, { useState } from "react";
import {
  TextField,
  InputLabel,
  makeStyles,
  Typography,
  Grid,
  Button,
} from "@material-ui/core";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import { passwordRegex } from "utils";
import { resetPassword } from "./resetPassword.action";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Loader from "components/Loader/Loader";
import AuthImage from "components/AuthImage/AuthImage";
import AppLogo from "components/AppLogo/AppLogo";
import styles from "assets/css/commonstyle.module.css";
import { openNotification, toggleOfflineModal } from "store/commonActions";
import MainPattern from "assets/images/main-pattern.png";

const useStyles = makeStyles((theme) => ({
  preLoginContentWrapper: {
    margin: "0 auto",
    textAlign: "center",
  },
  heading: {
    paddingBottom: 25,
  },
  pageContainer: {
    padding: "0 20px",
    background: `url(${MainPattern}) no-repeat`,
    backgroundSize: "contain",
    maxWidth: "414px",
    margin: "0 auto",
  },
  containerWrapper: {
    maxWidth: "500px",
    margin: "0 auto",
  },
  buttonWrapper: {
    padding: "20px 0 10px",
    "& button": {
      height: 36,
    },
  },
  formWrapper: {
    padding: "20px 0",
  },
  customLabel: {
    fontSize: "12px",
    fontWeight: "600",
    color: "#616161",
  },
  registerField: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 15,
    "& label": {
      textAlign: "left",
      marginBottom: 7,
    },
    "& .MuiOutlinedInput-root": {
      marginBottom: 6,
    },
  },
  instruction: {
    fontSize: "13px",
    textAlign: "center",
    color: "#616161",
    fontWeight: "bold",
    textTransform: "capitalize",
  },
  reponseError: {
    marginTop: "15px",
    textAlign: "center",
    "& p": {
      color: "red",
      fontWeight: "500",
      fontSize: "13.5px",
    },
  },
}));

const ResetPassword = () => {
  const [data, setData] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const [error, setError] = useState({
    newPasswordError: {
      errorStatus: false,
      helperText: "",
    },
    confirmPasswordError: {
      errorStatus: false,
      helperText: "",
    },
  });
  const [errorInFailure, setErrorInFailure] = useState({
    errorStatus: false,
    helperText: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { token } = useParams();
  const [show, setShow] = useState(false);
  const reducerState = useSelector((state) => state.resetPassword);
  const isUserOnline = useSelector((state) => state.common.isUserOnline);
  const classes = useStyles();

  const handleChange = (event) => {
    let { name, value } = event.target;
    switch (name) {
      case "newPassword":
        if (value.length === 0) {
          setError({
            ...error,
            newPasswordError: {
              errorStatus: true,
              helperText: "*This field is required!",
            },
          });
        } else if (
          value !== data.confirmPassword &&
          data.confirmPassword !== ""
        ) {
          setError({
            ...error,
            confirmPasswordError: {
              errorStatus: true,
              helperText: "Confirm Password doesn't match!",
            },
          });
        } else {
          setError({
            ...error,
            newPasswordError: {
              errorStatus: false,
              helperText: "",
            },
          });
        }
        setData({
          ...data,
          newPassword: value,
        });

        setErrorInFailure({
          errorStatus: false,
          helperText: "",
        });
        break;

      case "confirmPassword":
        if (value === data.newPassword || value.length === 0) {
          setError({
            ...error,
            confirmPasswordError: {
              errorStatus: false,
              helperText: "",
            },
          });
        } else {
          setError({
            ...error,
            confirmPasswordError: {
              errorStatus: true,
              helperText: "Confirm Password doesn't match!",
            },
          });
        }
        setData({
          ...data,
          confirmPassword: value,
        });
        setErrorInFailure({
          errorStatus: false,
          helperText: "",
        });
        break;
      default:
        break;
    }
  };

  const handleBlur = (event) => {
    let { value } = event.target;
    if (!passwordRegex.test(value)) {
      setError({
        ...error,
        newPasswordError: {
          errorStatus: true,
          helperText:
            "Your password must be have at least: 8 characters,one lowerCase,one upperCase,one special character and one Number!",
        },
      });
    } else {
      setError({
        ...error,
        newPasswordError: {
          errorStatus: false,
          helperText: "",
        },
      });
    }
  };

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isUserOnline) {
      dispatch(toggleOfflineModal(!isUserOnline));
      return;
    }
    if (
      !error.newPasswordError.errorStatus &&
      !error.confirmPasswordError.errorStatus &&
      !errorInFailure.errorStatus
    ) {
      const newData = {
        confirm_password: data.newPassword,
        new_password: data.newPassword,
      };
      const successCB = () => {
        setData({
          newPassword: "",
          confirmPassword: "",
        });
        setShow(true);
        dispatch(
          openNotification({
            message: "Password has been reset successfully!",
          })
        );
        history.push("/");
      };
      const FailureCB = (message) => {
        setErrorInFailure({
          errorStatus: true,
          helperText: message,
        });
      };
      dispatch(resetPassword(newData, token, successCB, FailureCB));
    }
  };

  let { newPassword, confirmPassword } = data;
  let { newPasswordError, confirmPasswordError } = error;
  const { loading } = reducerState;

  let instruction = (
    <Typography variant="body1" className={classes.instruction}>
      Your password is reset successfully.Login With New Password
    </Typography>
  );

  let errorInResponse = errorInFailure.errorStatus ? (
    <div className={classes.reponseError}>
      <Typography variant="body1">{errorInFailure.helperText}</Typography>{" "}
    </div>
  ) : null;
  return (
    <Grid container className={classes.pageContainer}>
      <Grid item xs={12}>
        <AuthImage />
      </Grid>
      <Grid item xs={12} className={classes.preLoginContentWrapper}>
        <div className={classes.containerWrapper}>
          <AppLogo />
          <form className={classes.formWrapper} onSubmit={handleSubmit}>
            <div className={classes.registerField}>
              <InputLabel className={classes.customLabel} htmlFor="password">
                New Password
              </InputLabel>
              <TextField
                placeholder="Enter New Password"
                type={showPassword ? "text" : "password"}
                variant="outlined"
                size="small"
                name="newPassword"
                className={styles.primaryInput}
                required
                value={newPassword}
                onChange={handleChange}
                error={newPasswordError.errorStatus}
                helperText={newPasswordError.helperText}
                onBlur={handleBlur}
                InputProps={{
                  endAdornment: (
                    <>
                      {!showPassword ? (
                        <VisibilityOffOutlinedIcon
                          fontSize="small"
                          color="primary"
                          onClick={handlePasswordVisibility}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <VisibilityOutlinedIcon
                          fontSize="small"
                          color="primary"
                          onClick={handlePasswordVisibility}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </>
                  ),
                }}
              />
            </div>

            <div className={classes.registerField}>
              <InputLabel
                className={classes.customLabel}
                htmlFor="confirmPassword"
              >
                Confirm Password
              </InputLabel>
              <TextField
                placeholder="Enter Confirm Password"
                type={showConfirmPassword ? "text" : "password"}
                variant="outlined"
                size="small"
                name="confirmPassword"
                className={styles.primaryInput}
                required
                value={confirmPassword}
                onChange={handleChange}
                error={confirmPasswordError.errorStatus}
                helperText={confirmPasswordError.helperText}
                InputProps={{
                  endAdornment: (
                    <>
                      {!showConfirmPassword ? (
                        <VisibilityOffOutlinedIcon
                          fontSize="small"
                          color="primary"
                          onClick={handleConfirmPasswordVisibility}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <VisibilityOutlinedIcon
                          fontSize="small"
                          color="primary"
                          onClick={handleConfirmPasswordVisibility}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </>
                  ),
                }}
              />
            </div>
            {errorInResponse}
            <div className={classes.buttonWrapper}>
              <Button
                fullWidth
                type="submit"
                color="primary"
                variant="contained"
                disabled={loading}
              >
                <Loader show={loading} />
                {!loading ? " Reset Password" : null}
              </Button>
            </div>
          </form>
        </div>
        {show ? instruction : null}
      </Grid>
    </Grid>
  );
};

export default ResetPassword;
