import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as DownloadIcon } from "assets/images/svg/download-icon.svg";
import { downloadCredential } from "../Credentials.action";
import { useDispatch, useSelector } from "react-redux";
import { toggleOfflineModal } from "store/commonActions";

const useStyles = makeStyles({
  actionBtn: {
    width: 30,
    minWidth: "auto",
    borderRadius: "50%",
    height: 30,
    backgroundColor: "#083f85",
    "&:hover": {
      backgroundColor: "#083f85",
    },
  },
});

function DownloadCredentials({ record_id }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isUserOnline = useSelector((state) => state.common.isUserOnline);

  const handleDownload = (event) => {
    event.stopPropagation();
    if (!isUserOnline) {
      dispatch(toggleOfflineModal(!isUserOnline));
      return;
    }
    dispatch(downloadCredential(record_id));
  };

  return (
    <Button className={classes.actionBtn} onClick={handleDownload}>
      <DownloadIcon />
    </Button>
  );
}

export default DownloadCredentials;
