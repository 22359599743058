import Typography from "@material-ui/core/Typography/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";
import { Link } from "react-router-dom";

const RedirectComponent = ({ text, path, name }) => {
  const useStyles = makeStyles({
    redirectPath: {
      display: "flex",
      justifyContent: "center",
      alignItems: "baseline",
      marginBottom: 20,
      "& p": {
        textDecoration: "none",
        color: " #616161",
        fontSize: 14,
        fontWeight: 500,
      },
      "& a": {
        paddingLeft: "2px",
        textDecoration: "underline",
        fontSize: 14,
        color: " #083f85",
        fontWeight: 500,
      },
    },
  });
  const classes = useStyles();

  return (
    <div className={classes.redirectPath} color="primary">
      <Typography variant="body1"> {text}</Typography>
      <Link to={path}>{name}</Link>
    </div>
  );
};

export default React.memo(RedirectComponent);
