import React, { useState } from "react";
import {
  TextField,
  InputLabel,
  makeStyles,
  Typography,
  Button,
} from "@material-ui/core";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import Security from "assets/images/svg/security.svg";
import styles from "assets/css/commonstyle.module.css";
import { useDispatch, useSelector } from "react-redux";
import { changeUserPassword } from "../../profile.actions";
import Loader from "components/Loader";
import { passwordRegex } from "utils";
import { toggleOfflineModal } from "store/commonActions";

const useStyles = makeStyles((theme) => ({
  iconWrapper: {
    width: 36,
    height: 36,
    borderRadius: "50%",
    backgroundColor: "#fff",
    paddingLeft: 20,
  },
  sectionHeading: {
    display: "flex",
    alignItems: "center",
    "& span": {
      fontWeight: "bold",
    },
  },
  sectionIconWrapper: {
    height: 28,
    width: 28,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "100%",
    background: "#083f85",
    marginRight: 12,
    "& img": {
      width: "55%",
    },
  },
  sectionWrapper: {
    paddingTop: 20,
  },
  field: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 15,
    "& label": {
      textAlign: "left",
      marginBottom: 8,
    },
    "& .MuiOutlinedInput-root": {
      marginBottom: 6,
      borderRadius: 6,
    },
    "& input": {
      height: "42px !important",
      fontSize: "14px !important",
    },
  },
  buttonWrapper: {
    padding: "10px 0",
    "& button": {
      height: 36,
    },
  },
  customLabel: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#616161",
  },
  formWrapper: {
    padding: "30px 0 20px",
  },
  reponseError: {
    marginTop: "15px",
    textAlign: "left",
    "& p": {
      color: "red",
      fontWeight: "500",
      fontSize: "13.5px",
    },
  },
}));

function ChangePassword() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [data, setData] = useState({
    newPassword: "",
    confirmPassword: "",
    currentPassword: "",
  });

  const [error, setError] = useState({
    newPasswordError: {
      errorStatus: false,
      helperText: "",
    },
    confirmPasswordError: {
      errorStatus: false,
      helperText: "",
    },
    currentPasswordError: {
      errorStatus: false,
      helperText: "",
    },
  });

  const [failureError, setFailureError] = useState({
    errorStatus: false,
    helperText: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);

  let { newPassword, confirmPassword, currentPassword } = data;
  let { newPasswordError, confirmPasswordError, currentPasswordError } = error;
  const isUserOnline = useSelector((state) => state.common.isUserOnline);
  const userDetails = useSelector(
    (state) => state.common.holderDetails.response
  );
  const changeUserPasswordState = useSelector(
    (state) => state.profile.changePassword
  );
  let { loading } = changeUserPasswordState;

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleCurrentPasswordVisibility = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const handlePasswordChange = (event) => {
    let { name, value } = event.target;
    switch (name) {
      case "currentPassword":
        if (value.length === 0) {
          setError({
            ...error,
            currentPasswordError: {
              errorStatus: true,
              helperText: "*This field is required!",
            },
          });
        } else {
          setError({
            ...error,
            currentPasswordError: {
              errorStatus: false,
              helperText: "",
            },
          });
        }
        setData({
          ...data,
          currentPassword: value,
        });
        setFailureError({
          errorStatus: false,
          helperText: "",
        });
        break;

      case "newPassword":
        if (value.length === 0) {
          setError({
            ...error,
            newPasswordError: {
              errorStatus: true,
              helperText: "*This field is required!",
            },
          });
        } else if (
          value !== data.confirmPassword &&
          data.confirmPassword !== ""
        ) {
          setError({
            ...error,
            confirmPasswordError: {
              errorStatus: true,
              helperText: "Confirm Password doesn't match!",
            },
          });
        } else {
          setError({
            ...error,
            newPasswordError: {
              errorStatus: false,
              helperText: "",
            },
            confirmPasswordError: {
              errorStatus: false,
              helperText: "",
            },
          });
        }
        setData({
          ...data,
          newPassword: value,
        });

        setFailureError({
          errorStatus: false,
          helperText: "",
        });
        break;

      case "confirmPassword":
        if (value === data.newPassword || value.length === 0) {
          setError({
            ...error,
            confirmPasswordError: {
              errorStatus: false,
              helperText: "",
            },
          });
        } else if (data.newPassword.length === 0) {
          setError({
            ...error,
            confirmPasswordError: {
              errorStatus: true,
              helperText: "Please fill new password field also!",
            },
          });
        } else {
          setError({
            ...error,
            confirmPasswordError: {
              errorStatus: true,
              helperText: "Confirm Password doesn't match!",
            },
          });
        }
        setData({
          ...data,
          confirmPassword: value,
        });
        setFailureError({
          errorStatus: false,
          helperText: "",
        });
        break;
      default:
        break;
    }
  };

  const handleBlur = (event) => {
    let { value } = event.target;
    if (!passwordRegex.test(value) && value.length > 0) {
      setError({
        ...error,
        newPasswordError: {
          errorStatus: true,
          helperText:
            "Your password must be have at least: 8 characters, one lowerCase, one upperCase, one special character and one Number!",
        },
      });
    } else {
      setError({
        ...error,
        newPasswordError: {
          errorStatus: false,
          helperText: "",
        },
      });
    }
  };

  const successCB = () => {
    setData({
      newPassword: "",
      confirmPassword: "",
      currentPassword: "",
    });
  };
  const failureCB = (message) => {
    setFailureError({
      errorStatus: true,
      helperText: message,
    });
  };

  const handleResetPassword = (event) => {
    event.preventDefault();
    if (!isUserOnline) {
      dispatch(toggleOfflineModal(!isUserOnline));
      return;
    }
    if (
      newPassword === "" ||
      (currentPassword === "" && userDetails?.login_type !== "social") ||
      confirmPassword === ""
    ) {
      setFailureError({
        errorStatus: true,
        helperText: "Please fill all mendatory fields!",
      });
      return;
    }

    if (
      error.newPasswordError.errorStatus ||
      error.confirmPasswordError.errorStatus ||
      failureError.errorStatus ||
      newPassword === "" ||
      (currentPassword === "" && userDetails?.login_type !== "social") ||
      confirmPassword === ""
    ) {
      return;
    }

    const newData = {
      current_password: data.currentPassword,
      new_password: data.newPassword,
    };

    if (userDetails?.login_type === "social") {
      delete newData["current_password"];
    }
    dispatch(changeUserPassword(newData, successCB, failureCB));
  };

  let errorInResponse = failureError.errorStatus ? (
    <div className={classes.reponseError}>
      <Typography variant="body1">{failureError.helperText}</Typography>{" "}
    </div>
  ) : null;

  let isUpdateButtonDisable =
    !newPassword.length && !confirmPassword.length && !currentPassword.length;

  return (
    <div className={classes.sectionWrapper}>
      <div className={classes.sectionHeading}>
        <div className={classes.sectionIconWrapper}>
          <img src={Security} alt="security" />
        </div>
        <Typography variant="h4" component="span" color="primary">
          Security
        </Typography>
      </div>
      <form className={classes.formWrapper} onSubmit={handleResetPassword}>
        {userDetails?.login_type === "social" ? null : (
          <div className={classes.field}>
            <InputLabel
              className={classes.customLabel}
              htmlFor="currentPassword"
            >
              Current Password
            </InputLabel>
            <TextField
              placeholder="Current Password"
              type={showCurrentPassword ? "text" : "password"}
              variant="outlined"
              size="small"
              className={styles.primaryInput}
              name="currentPassword"
              required
              value={currentPassword}
              onChange={handlePasswordChange}
              error={currentPasswordError.errorStatus}
              helperText={currentPasswordError.helperText}
              InputProps={{
                endAdornment: (
                  <>
                    {!showCurrentPassword ? (
                      <VisibilityOffOutlinedIcon
                        fontSize="small"
                        color="primary"
                        onClick={handleCurrentPasswordVisibility}
                        style={{ cursor: "pointer" }}
                      />
                    ) : (
                      <VisibilityOutlinedIcon
                        fontSize="small"
                        color="primary"
                        onClick={handleCurrentPasswordVisibility}
                        style={{ cursor: "pointer" }}
                      />
                    )}
                  </>
                ),
              }}
            />
          </div>
        )}
        <div className={classes.field}>
          <InputLabel className={classes.customLabel} htmlFor="newPassword">
            New Password
          </InputLabel>
          <TextField
            placeholder="New Password"
            type={showPassword ? "text" : "password"}
            variant="outlined"
            size="small"
            name="newPassword"
            className={classes.registerInput}
            required
            value={newPassword}
            onChange={handlePasswordChange}
            error={newPasswordError.errorStatus}
            helperText={newPasswordError.helperText}
            onBlur={handleBlur}
            InputProps={{
              endAdornment: (
                <>
                  {!showPassword ? (
                    <VisibilityOffOutlinedIcon
                      fontSize="small"
                      color="primary"
                      onClick={handlePasswordVisibility}
                      style={{ cursor: "pointer" }}
                    />
                  ) : (
                    <VisibilityOutlinedIcon
                      fontSize="small"
                      color="primary"
                      onClick={handlePasswordVisibility}
                      style={{ cursor: "pointer" }}
                    />
                  )}
                </>
              ),
            }}
          />
        </div>
        <div className={classes.field}>
          <InputLabel className={classes.customLabel} htmlFor="confirmPassword">
            Confirm Password
          </InputLabel>
          <TextField
            placeholder="Confirm Password"
            type={showConfirmPassword ? "text" : "password"}
            variant="outlined"
            size="small"
            name="confirmPassword"
            className={classes.registerInput}
            required
            value={confirmPassword}
            onChange={handlePasswordChange}
            error={confirmPasswordError.errorStatus}
            helperText={confirmPasswordError.helperText}
            InputProps={{
              endAdornment: (
                <>
                  {!showConfirmPassword ? (
                    <VisibilityOffOutlinedIcon
                      fontSize="small"
                      color="primary"
                      onClick={handleConfirmPasswordVisibility}
                      style={{ cursor: "pointer" }}
                    />
                  ) : (
                    <VisibilityOutlinedIcon
                      fontSize="small"
                      color="primary"
                      onClick={handleConfirmPasswordVisibility}
                      style={{ cursor: "pointer" }}
                    />
                  )}
                </>
              ),
            }}
          />
        </div>

        {errorInResponse}

        <div className={classes.buttonWrapper}>
          <Button
            fullWidth
            type="submit"
            color="primary"
            variant="contained"
            disabled={loading || isUpdateButtonDisable}
          >
            <Loader show={loading} />
            {!loading ? "Change Password" : null}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default ChangePassword;
