import {
  LOGIN_BEGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  SOCIAL_LOGIN_BEGIN,
  SOCIAL_LOGIN_SUCCESS,
  SOCIAL_LOGIN_FAILURE,
} from "./login.constant";

const initState = {
  normalLogin: {
    loading: false,
    data: "",
    error: "",
  },
  SSOLogin: {
    loading: false,
    data: {},
    error: "",
  },
};

const loginReducer = (state = initState, action) => {
  switch (action.type) {
    case LOGIN_BEGIN:
      return {
        ...state,
        normalLogin: {
          ...state.normalLogin,
          loading: true,
        },
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        normalLogin: {
          ...state.normalLogin,
          loading: false,
          data: action.payload,
          error: "",
        },
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        normalLogin: {
          ...state.normalLogin,
          loading: false,
          error: action.payload,
          data: "",
        },
      };
    case SOCIAL_LOGIN_BEGIN:
      return {
        ...state,
        SSOLogin: {
          ...state.SSOLogin,
          loading: true,
        },
      };
    case SOCIAL_LOGIN_SUCCESS:
      return {
        ...state,
        SSOLogin: {
          ...state.SSOLogin,
          loading: false,
          data: action.payload,
        },
      };
    case SOCIAL_LOGIN_FAILURE:
      return {
        ...state,
        SSOLogin: {
          ...state.SSOLogin,
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export default loginReducer;
