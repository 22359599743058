import React, { useEffect } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import styles from "assets/css/commonstyle.module.css";
import ShareCredentials from "../../Actions/ShareCredentials";
import DownloadCredentials from "../../Actions/DownloadCredentials";
import ViewCredentials from "../../Actions/ViewCredentials";
import ScanCredentials from "../../Actions/ScanCredentials";
import { useDispatch, useSelector } from "react-redux";
import { getCredentialsList } from "pages/credentials/Credentials.action";
import { getDate } from "utils";
import PreviewCredential from "pages/credentials/Actions/PreviewCredential";
import CredentialsListSkeleton from "components/Skeletons/CredentialsList";
import { toggleOfflineModal } from "store/commonActions";
import { useHistory } from "react-router-dom";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  Main: {
    height: "100vh",
    background: "#083f85",
  },
  content: {
    background: "#f6f6f6",
    zIndex: 2,
    position: "relative",
    borderRadius: "15px 15px 0 0",
    padding: "20px 0 20px 12px",
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  link: {
    fontSize: 12,
    color: "#616161",
    "&:hover": {
      textDecoration: "none",
    },
  },
  tabMain: {
    height: 130,
    margin: "20px 20px 20px 10px",
    padding: "0 20px",
    border: "1px solid #d5d5d5",
    backgroundColor: "#fff",
    borderRadius: 10,
    position: "relative",
    overflow: "inherit",
    display: "flex",
    "@media screen and (max-width:320px)": {
      padding: "10px",
    },
  },
  tab: {
    // margin: "20px 10px 30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
  },
  tabLogo: {
    padding: 12,
    width: 54,
    height: 54,
    backgroundColor: "#fff",
    borderRadius: "50%",
    border: "solid 0.5px #d5d5d5",
  },
  tabContent: {
    marginLeft: 20,
    flexGrow: 1,
    "@media screen and (max-width:320px)": {
      marginLeft: 10,
    },
  },
  credentialStatus: { display: "flex" },
  tabTitle: {
    fontSize: 16,
    color: "#354052",
    fontWeight: "bold",
    textTransform: "capitalize",
    marginBottom: 5,
    textAlign: "left",
    "-webkit-line-clamp": 2,
    overflow: "hidden",
    "-webkit-box-orient": "vertical",
    display: "-webkit-box",
  },
  tabLabel: {
    margin: "0 12px 0 0",
    padding: "2px 5px",
    borderRadius: 2,
    backgroundColor: "#3cc854",
    color: "#fff",
    fontSize: 8,
  },
  tabDate: {
    color: "#354052",
    fontSize: 11,
  },
  actionButtons: {
    display: "flex",
    margin: "10px 0 0 0",
    "& button": {
      marginRight: 10,
      "&:last-child": {
        marginRight: 0,
      },
    },
    "@media screen and (max-width:320px)": {
      "& button": {
        marginRight: 5,
      },
    },
  },
  revoked: {
    margin: "0 12px 0 0",
    padding: "2px 5px",
    borderRadius: 2,
    fontSize: 8,
    color: "#ffffff",
    backgroundColor: "#ff0000",
  },
}));

const CredentialsList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const credentialsList = useSelector(
    (state) => state.credential.credentialsList
  );
  const selectedIssuer = useSelector(
    (state) => state.credential.selectedIssuer
  );
  const history = useHistory();
  const isUserOnline = useSelector((state) => state.common.isUserOnline);

  useEffect(() => {
    if (
      !credentialsList.loading &&
      !credentialsList.response?.length &&
      !isUserOnline
    )
      dispatch(toggleOfflineModal(!isUserOnline));
  });

  useEffect(() => {
    if (selectedIssuer.id) {
      dispatch(getCredentialsList({ issuer_id: selectedIssuer.id }));
    }
  }, [dispatch, selectedIssuer.id]);

  const handleClick = (event, credential) => {
    event.stopPropagation();
    history.push({
      pathname: "/credentials/credential-details",
      state: { credential, issuer: selectedIssuer },
    });
  };

  const list = credentialsList.response?.map((item) => {
    const {
      subject_name,
      logo,
      created_at,
      id,
      revoked,
      revoked_at,
      valid_until,
    } = item;
    const isExpired = moment(valid_until).diff(new Date(), "seconds") < 0;
    return (
      <div
        className={`${[classes.tabMain, classes.tabAvtive].join(" ")}`}
        key={id}
        onClick={(event) => handleClick(event, item)}
        aria-hidden="true"
      >
        <div className={classes.tab}>
          <img src={logo} alt="tab-logo" className={classes.tabLogo} />
          <div className={classes.tabContent}>
            <Typography className={classes.tabTitle}>{subject_name}</Typography>
            <div className={classes.credentialStatus}>
              {isExpired && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label htmlFor="Expired" className={classes.revoked}>
                    Expired
                  </label>
                </div>
              )}
              {revoked ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label htmlFor="Revoked" className={classes.revoked}>
                    Revoked
                  </label>
                  <Typography className={classes.tabDate}>
                    {revoked_at ? getDate(revoked_at) : null}
                  </Typography>
                </div>
              ) : (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label htmlFor="Active" className={classes.tabLabel}>
                    Active
                  </label>
                  <Typography className={classes.tabDate}>
                    {getDate(created_at)}
                  </Typography>
                </div>
              )}
            </div>
            <div className={classes.actionButtons}>
              <ShareCredentials
                record_id={item.id}
                share_link={item.viewer_link}
              />
              <DownloadCredentials record_id={item.id} />
              <ViewCredentials credential={item} />
              {/* <InsightsCredentials /> */}
              <ScanCredentials qrCode={item.qrcode_path} />
              <PreviewCredential link={item.viewer_link} />
            </div>
          </div>
        </div>
      </div>
    );
  });

  return (
    <>
      <Typography className={styles.pageTitle}>Credentials</Typography>
      {credentialsList.loading ? (
        <CredentialsListSkeleton />
      ) : (
        <div className={classes.subjectListContainer}>{list}</div>
      )}
    </>
  );
};

export default CredentialsList;
