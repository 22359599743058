import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import ImageIcon from "@material-ui/icons/Image";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleOfflineModal } from "store/commonActions";
import CredentialImageModal from "../ActionModals/CredentialImageModal";

const useStyles = makeStyles({
  actionBtn: {
    width: 30,
    minWidth: "auto",
    borderRadius: "50%",
    height: 30,
    color: "white",
    backgroundColor: "#083f85",
    "&:hover": {
      backgroundColor: "#083f85",
    },
  },
});

export default function CredentialImage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const isUserOnline = useSelector((state) => state.common.isUserOnline);

  const previewCredentialState = useSelector(
    (state) => state.credential.previewCredential
  );
  const credentialFields =
    previewCredentialState.response?.additional_field_data;

  const awardImage = credentialFields?.find(
    (elem) => elem.fname === "award_image" && elem.ftype === "image"
  );
  const isAwardImageEditable = awardImage?.is_holder_editable;

  const handleImgModalOpen = (event) => {
    event.stopPropagation();
    if (!isUserOnline) {
      dispatch(toggleOfflineModal(!isUserOnline));
      return;
    }
    setOpen(true);
  };

  const closeImgModal = (ev) => {
    ev?.stopPropagation();
    setOpen(false);
  };

  return (
    <>
      {awardImage && (
        <Button className={classes.actionBtn} onClick={handleImgModalOpen}>
          {isAwardImageEditable ? <AddPhotoAlternateIcon /> : <ImageIcon />}
        </Button>
      )}
      {open && (
        <CredentialImageModal
          openDialog={open}
          handleClose={closeImgModal}
          awardImage={awardImage}
        />
      )}
    </>
  );
}
