import React, { useEffect } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import BackgroundImage from "assets/images/svg/bg-pattern.svg";
import VerificationIcon from "assets/images/svg/verification-blue.svg";
import UserKeyIcon from "assets/images/svg/user-blue.svg";
// import ClockIcon from "assets/images/svg/clock-icon.svg";
import MobileIcon from "assets/images/svg/mobile-icon.svg";
import LaptopIcon from "assets/images/svg/laptop-icon.svg";
import GlobeIcon from "assets/images/svg/globe-icon.svg";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import { getVerificationOverview } from "pages/credentials/Credentials.action";
import { useLocation } from "react-router-dom";
import CardsSkeleton from "components/Skeletons/AnalyticsCards";

const useStyles = makeStyles((theme) => ({
  analyticsCardsWrapper: {
    padding: "20px 0",
  },
  analyticsCounts: {
    color: "#11141a",
    fontWeight: "700",
    margin: "0 0 10px",
  },
  analyticsTitle: {
    color: "#11141a",
    fontWeight: 400,
    fontSize: 10,
  },
  analyticsCard: {
    display: "flex",
    flex: "1",
    alignItems: "center",
    justifyContent: "space-around",
    borderRadius: 4,
    overflow: "hidden",
    position: "relative",
    zIndex: "1",
    border: "1px solid #d5d5d5",
    height: 55,
    padding: "5px 10px",
    "&::after": {
      content: `''`,
      position: "absolute",
      width: "50%",
      height: "70%",
      backgroundImage: `url(${BackgroundImage})`,
      backgroundPosition: "top right",
      backgroundRepeat: "no-repeat",
      top: 0,
      right: 0,
      zIndex: -1,
    },
  },
  cardContent: {
    width: "100%",
    margin: "0 0 3px",
  },
  chartImage: {
    width: 60,
    position: "relative",
    top: "5px",
    "& img": {
      width: "100%",
      height: "auto",
    },
  },
  cardIconsWrapper: {
    backgroundColor: "#f6f6f6",
    borderRadius: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #d5d5d5",
    width: 36,
    height: 30,
  },
  cardIcon: {
    width: "50%",
    height: "50%",
    "& img": {
      width: "100%",
      height: "100%",
    },
  },
  heading: {
    fontWeight: "bold",
  },
}));

const AnalyticsCards = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  let { credential } = location.state;
  const stats = useSelector((state) => state.credential.overviewStats.response);
  const loading = useSelector(
    (state) => state.credential.overviewStats.loading
  );
  useEffect(() => {
    dispatch(getVerificationOverview({ cred_id: credential.id }));
  }, [dispatch, credential.id]);

  return (
    <React.Fragment>
      <Typography
        variant="h5"
        component="span"
        color="primary"
        className={classes.heading}
      >
        Verification Overview
      </Typography>
      {!loading ? (
        <>
          <Grid container spacing={2} className={classes.analyticsCardsWrapper}>
            <Grid item xs={6} sm={6} md={2} lg={2}>
              <div className={classes.analyticsCard}>
                <div className={classes.cardContent}>
                  <Typography
                    variant="body1"
                    className={classes.analyticsCounts}
                  >
                    {stats["credential_verified"]}
                  </Typography>
                  <Typography
                    variant="body2"
                    className={classes.analyticsTitle}
                  >
                    Verification count
                  </Typography>
                </div>
                <div className={classes.cardIconsWrapper}>
                  <i className={classes.cardIcon}>
                    <img src={VerificationIcon} alt="verification-blue" />
                  </i>
                </div>
              </div>
            </Grid>
            <Grid item xs={6} sm={6} md={2} lg={2}>
              <div className={classes.analyticsCard}>
                <div className={classes.cardContent}>
                  <Typography
                    variant="body1"
                    className={classes.analyticsCounts}
                  >
                    {stats["unique_verifier"]}
                  </Typography>
                  <Typography
                    variant="body2"
                    className={classes.analyticsTitle}
                  >
                    Unique Verifier
                  </Typography>
                </div>
                <div className={classes.cardIconsWrapper}>
                  <i className={classes.cardIcon}>
                    <img src={UserKeyIcon} alt="user-key-blue" />
                  </i>
                </div>
              </div>
            </Grid>
            <Grid item xs={6} sm={6} md={2} lg={2}>
              <div className={classes.analyticsCard}>
                <div className={classes.cardContent}>
                  <Typography
                    variant="body1"
                    className={classes.analyticsCounts}
                  >
                    {stats["mobile_devices"]}
                  </Typography>
                  <Typography
                    variant="body2"
                    className={classes.analyticsTitle}
                  >
                    Mobile Devices
                  </Typography>
                </div>
                <div className={classes.cardIconsWrapper}>
                  <i className={classes.cardIcon}>
                    <img src={MobileIcon} alt="mobile-icon" />
                  </i>
                </div>
              </div>
            </Grid>
            <Grid item xs={6} sm={6} md={2} lg={2}>
              <div className={classes.analyticsCard}>
                <div className={classes.cardContent}>
                  <Typography
                    variant="body1"
                    className={classes.analyticsCounts}
                  >
                    {stats["other_devices"]}
                  </Typography>
                  <Typography
                    variant="body2"
                    className={classes.analyticsTitle}
                  >
                    Other Devices
                  </Typography>
                </div>
                <div className={classes.cardIconsWrapper}>
                  <i className={classes.cardIcon}>
                    <img src={LaptopIcon} alt="Laptop-icon" />
                  </i>
                </div>
              </div>
            </Grid>
            {/* <Grid item xs={6} sm={6} md={2} lg={2}>
              <div className={classes.analyticsCard}>
                <div className={classes.cardContent}>
                  <Typography
                    variant="body1"
                    className={classes.analyticsCounts}
                  >
                    {stats["average_minutes_spent"]}
                  </Typography>
                  <Typography
                    variant="body2"
                    className={classes.analyticsTitle}
                  >
                    Average Minutes Spent
                  </Typography>
                </div>
                <div className={classes.cardIconsWrapper}>
                  <i className={classes.cardIcon}>
                    <img src={ClockIcon} alt="clock" />
                  </i>
                </div>
              </div>
            </Grid> */}
            <Grid item xs={6} sm={6} md={2} lg={2}>
              <div className={classes.analyticsCard}>
                <div className={classes.cardContent}>
                  <Typography
                    variant="body1"
                    className={classes.analyticsCounts}
                  >
                    {stats["countries"]}
                  </Typography>
                  <Typography
                    variant="body2"
                    className={classes.analyticsTitle}
                  >
                    Countries
                  </Typography>
                </div>
                <div className={classes.cardIconsWrapper}>
                  <i className={classes.cardIcon}>
                    <img src={GlobeIcon} alt="Globe" />
                  </i>
                </div>
              </div>
            </Grid>
          </Grid>
        </>
      ) : (
        <CardsSkeleton />
      )}
    </React.Fragment>
  );
};

export default AnalyticsCards;
