import React, { useEffect } from "react";
import { CircularProgress, makeStyles, Typography } from "@material-ui/core";
import Header from "components/BackButtonHeader/BackButtonHeader";
import NotificationType from "./components/NotificationType/";
import Item from "./components/Item";
import { useSelector, useDispatch } from "react-redux";
import { getNotificationList, setParameter } from "./Notification.action";
import InfiniteScroll from "react-infinite-scroll-component";
import { getHolderDetails } from "store/commonActions";
import { getCookie } from "utils";
import NotificationSkeleton from "components/Skeletons/Notification";
const useStyles = makeStyles((theme) => ({
  notificationContainer: {
    padding: "80px 20px 0 20px",
    backgroundColor: "#f6f6f6",
    marginTop: -10,
    minHeight: "calc(100vh - 70px)",
    borderRadius: "20px 20px 0 0",
  },
  headerBackground: {
    background: "#fff",
    paddingBottom: 10,
    position: "fixed",
    width: "1024px",
    maxWidth: "1024px",
    [theme.breakpoints.up("md")]: {
      width: "768px",
      maxWidth: "768px ",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    zIndex: 10,
    "& span": {
      color: "#083f85",
      flexGrow: 1,
    },
    "& button": {
      background: "#f6f6f6",
    },
  },
  infiniteLoader: {
    textAlign: "center",
    margin: "10px 0",
  },
  noNotificationWrapper: {
    padding: "80px 20px 0 20px",
    backgroundColor: "#f6f6f6",
    marginTop: -10,
    minHeight: "calc(100vh - 70px)",
    borderRadius: "20px 20px 0 0",
  },
}));

const Notification = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { response, page, loading, perPage } = useSelector(
    (state) => state.notification.notificationList
  );
  const holderDetails = useSelector(
    (state) => state.common.holderDetails.response
  );

  useEffect(() => {
    if (!holderDetails?.name && getCookie("accessToken")) {
      dispatch(getHolderDetails());
    }
  }, [dispatch, holderDetails]);

  useEffect(() => {
    let params = {
      page,
      per_page: perPage,
    };
    if (getCookie("accessToken") && page === 1 && !response?.data?.length) {
      dispatch(getNotificationList(params));
    }
    // eslint-disable-next-line
  }, [dispatch, page, perPage]);

  const latestList = response?.data?.slice(0, 4);
  const earlierList = response?.data?.slice(4, response?.data?.length);

  let latestItem = latestList?.map((item, index) => (
    <Item key={item?.notification_id} data={item} />
  ));

  let earlierItem = earlierList?.map((item, index) => (
    <Item key={item?.notification_id} data={item} />
  ));

  const fetchMoreData = () => {
    dispatch(setParameter({ page: page + 1 }));
  };

  let hasMoreData = response?.data?.length < response?.total;
  return (
    <>
      <div className={classes.headerBackground}>
        <Header title="Notification" />
      </div>
      {!loading || Boolean(response?.data?.length) ? (
        <>
          {Boolean(response?.data?.length) ? (
            <div className={classes.notificationContainer}>
              <InfiniteScroll
                dataLength={response?.data?.length || 0}
                next={fetchMoreData}
                hasMore={hasMoreData}
                loader={
                  <h4 className={classes.infiniteLoader}>
                    <CircularProgress />
                  </h4>
                }>
                {latestItem?.length ? (
                  <NotificationType title="Latest" read="Mark all as read">
                    {latestItem}
                  </NotificationType>
                ) : null}
                {earlierItem?.length ? (
                  <NotificationType title="Earlier" read="">
                    {earlierItem}
                  </NotificationType>
                ) : null}
              </InfiniteScroll>
            </div>
          ) : null}
        </>
      ) : (
        <NotificationSkeleton />
      )}
      {!loading && !Boolean(response?.data?.length) ? (
        <div className={classes.noNotificationWrapper}>
          <Typography
            variant="h4"
            color="primary"
            align="center"
            display="block">
            No Notification
          </Typography>
        </div>
      ) : null}
    </>
  );
};

export default Notification;
