import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import EmailVerified from "assets/images/svg/verified-email.svg";
import ErrorVerified from "assets/images/svg/error-mail.svg";
import InfoMail from "assets/images/svg/info-mail.svg";
import { Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { verifyEmail } from "./verifyEmail.action";
import Loader from "components/Loader/Loader";

const useStyles = makeStyles((theme) => ({
  verifiedContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100vh",
  },
  iconWrapper: {
    width: 220,
    height: 160,
    animation: "$emailVerification 2s ease-in-out infinite",
    "& svg,img": {
      width: "100%",
      height: "100%",
    },
  },
  "@keyframes emailVerification": {
    "0%, 25%, 50%, 75%, 100%": {
      transform: "translateY(0)",
    },
    "40%": {
      transform: "translateY(-25px) ",
    },
    "60%": {
      transform: "translateY(-15px)",
    },
  },
  verifyMessageContainer: {
    padding: "10px 0",
  },
  mainMessage: {
    fontWeight: 500,
    marginBottom: 5,
  },
  loader: {
    animation: "$spin 2s linear infinite",
    top: "calc(50% - 100px)",
    left: "calc(50% - 100px)",
    right: "calc(50% - 100px)",
    bottom: "calc(50% - 100px)",
    width: "150px",
    border: "10px solid #f3f3f3",
    height: "150px",
    position: "fixed",
    borderTop: "10px solid #083f85",
    borderRadius: "50%",
  },
  "@keyframes spin": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
}));

const VerifyEmail = () => {
  const classes = useStyles();
  const { verifyToken } = useParams();
  const dispatch = useDispatch();
  const { loading, isEmailVerified, error, response } = useSelector(
    (state) => state.verifyEmail
  );

  useEffect(() => {
    dispatch(verifyEmail({ verify_token: verifyToken }));
  }, [dispatch, verifyToken]);

  let isEmailAlreadyVerified = error === "Your email is already verified!";

  return (
    <>
      {loading ? (
        <div className={classes.verifiedContainer}>
          <Loader show={loading} />
        </div>
      ) : null}
      {!loading && isEmailVerified ? (
        <div className={classes.verifiedContainer}>
          <span className={classes.iconWrapper}>
            <img
              src={EmailVerified}
              alt="error-mail"
              width="100%"
              height="100%"
            />
          </span>

          <div className={classes.verifyMessageContainer}>
            <Typography
              component="p"
              variant="h4"
              className={classes.mainMessage}
            >
              {response}
            </Typography>
          </div>
        </div>
      ) : null}
      {!loading && error.length ? (
        <div className={classes.verifiedContainer}>
          <span className={classes.iconWrapper}>
            <img
              src={isEmailAlreadyVerified ? InfoMail : ErrorVerified}
              alt="error-mail"
              width="100%"
              height="100%"
            />
          </span>

          <div className={classes.verifyMessageContainer}>
            <Typography
              component="p"
              variant="h4"
              className={classes.mainMessage}
            >
              {error}
            </Typography>
            {!isEmailAlreadyVerified ? (
              <Typography component="p" variant="subtitle1" align="center">
                Please Verify Again
              </Typography>
            ) : null}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default VerifyEmail;
