import { Badge, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import Verify from "assets/images/svg/verification-blue.svg";
import { getNotificationList } from "pages/Notification/Notification.action";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getCookie } from "utils";
import Sidebar from "../Sidebar/Sidebar";

const useStyles = makeStyles((theme) => ({
  header: {
    zIndex: 3,
    position: "fixed",
    padding: 10,
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#083f85",
    width: "calc(1024px - 20px)",
    maxWidth: "calc(1024px - 20px)",
    [theme.breakpoints.up("md")]: {
      width: "calc(768px - 20px)",
      maxWidth: "calc(768px - 20px)",
    },
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 20px)",
    },
    [theme.breakpoints.down("xs")]: {
      width: "calc(100% - 20px)",
      "@media screen and (max-width:320px)": {
        width: "94%",
      },
    },
  },
  headerBtn: {
    background: "#fff",
    width: 40,
    minWidth: 0,
    borderRadius: "50%",
    color: "#083f85",
    padding: 8,
    "&:focus": {
      background: "#fff",
      color: "#083f85",
    },
  },
  leftWrapper: {
    "& button:first-child": {
      marginRight: 8,
    },
  },
}));

function Header({ display }) {
  const classes = useStyles();
  const [openSidebarDrawer, setOpenSidebarDrawer] = useState(false);
  const history = useHistory();
  const { response, page, perPage } = useSelector(
    (state) => state.notification.notificationList
  );
  const dispatch = useDispatch();
  let newList = response?.data?.filter((item) => item.is_new);

  const toggleSidebarDrawer = useCallback(() => {
    setOpenSidebarDrawer(!openSidebarDrawer);
  }, [openSidebarDrawer]);

  const handleVerify = () => {
    history.push("/verify-credentials");
  };

  const openNotification = () => {
    history.push("/notification");
  };

  useEffect(() => {
    let params = {
      page,
      per_page: perPage,
    };
    if (
      getCookie("accessToken") &&
      page > 1 &&
      response.data.length < response.total
    ) {
      dispatch(getNotificationList(params));
    }
    // eslint-disable-next-line
  }, [dispatch, page, perPage]);

  if (!display) return null;
  return (
    <>
      <header className={classes.header}>
        <Button className={classes.headerBtn} onClick={toggleSidebarDrawer}>
          <MenuIcon />
        </Button>
        <div className={classes.leftWrapper}>
          <Button
            type="button"
            className={classes.headerBtn}
            onClick={handleVerify}
          >
            <img src={Verify} alt="Verify" width="24px" height="24px" />
          </Button>
          <Button
            type="button"
            className={classes.headerBtn}
            onClick={openNotification}
          >
            <Badge badgeContent={newList?.length} color="error">
              <NotificationsNoneIcon />
            </Badge>
          </Button>
        </div>
      </header>
      <Sidebar
        toggleSidebarDrawer={toggleSidebarDrawer}
        openSidebarDrawer={openSidebarDrawer}
      />
    </>
  );
}

export default React.memo(Header);
