import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloudOne from "assets/images/svg/cloud-one.svg";
import CloudTwo from "assets/images/svg/cloud-two.svg";
import User from "assets/images/user.png";
import { getNotificationList } from "pages/Notification/Notification.action";
import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getHolderDetails } from "store/commonActions";
import { getCookie } from "utils";

const useStyles = makeStyles((theme) => ({
  Main: {
    minHeight: 260,
    background: "#083f85",
    position: "relative",
    padding: "10px 20px 0",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    userSelect: "none",
  },
  cloudOne: {
    position: "absolute",
    top: "50%",
    left: 0,
    zIndex: 1,
  },
  cloudTwo: {
    position: "absolute",
    top: "35%",
    right: 0,
    width: "73%",
  },
  user: {
    position: "absolute",
    bottom: 0,
    right: 10,
    zIndex: 2,
  },
  Title: {
    color: "#fff",
    zIndex: 2,
    fontWeight: 400,
    lineHeight: "29px",
    fontSize: 24,
  },
  userName: {
    color: "#fff",
    zIndex: 2,
    fontWeight: "bold",
    fontSize: 24,
    wordBreak: "break-all",
  },
}));

function UserDetail({ name }) {
  const classes = useStyles();
  const holderDetails = useSelector(
    (state) => state.common.holderDetails.response
  );
  const { response, page, perPage } = useSelector(
    (state) => state.notification.notificationList
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!holderDetails?.name && getCookie("accessToken")) {
      dispatch(getHolderDetails());
    }
  }, [dispatch, holderDetails]);

  useEffect(() => {
    let params = {
      page,
      per_page: perPage,
    };
    if (getCookie("accessToken") && page === 1 && !response?.data?.length) {
      dispatch(getNotificationList(params));
    }
    // eslint-disable-next-line
  }, [dispatch, page, perPage]);

  return (
    <div className={classes.Main}>
      <img src={CloudOne} alt="cloud" className={classes.cloudOne} />
      <img src={CloudTwo} alt="cloud" className={classes.cloudTwo} />
      <img src={User} alt="user" className={classes.user} />
      <Typography className={classes.Title}>Welcome Back</Typography>
      <Typography className={classes.userName}>{name}</Typography>
    </div>
  );
}

export default memo(UserDetail);
