import { makeStyles } from "@material-ui/core";
import FrontImage from "assets/images/login-top.png";

const useStyles = makeStyles((theme) => ({
  imgContainer: {
    display: "flex",
    justifyContent: "center",
    padding: "34px 0",
  },
}));

function AuthImage() {
  const classes = useStyles();
  return (
    <div className={classes.imgContainer}>
      <img src={FrontImage} alt="login-auth" />
    </div>
  );
}

export default AuthImage;
