import { createTheme } from "@material-ui/core/styles";

const themeBreakpoints = createTheme({
  breakpoints: {
    values: {
      xs: 576,
      sm: 768,
      md: 1025,
      lg: 1441,
      xl: 1680,
    },
  },
});

const theme = createTheme({
  breakpoints: {
    values: {
      xs: themeBreakpoints.breakpoints.values.xs,
      sm: themeBreakpoints.breakpoints.values.sm,
      md: themeBreakpoints.breakpoints.values.md,
      lg: themeBreakpoints.breakpoints.values.lg,
      xl: themeBreakpoints.breakpoints.values.xl,
    },
  },
  palette: {
    primary: {
      main: "#083f85",
    },
    secondary: {
      main: "#616161",
    },
    error: {
      main: "#f35162",
    },
    white: {
      main: "#ffffff",
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        marginBottom: 15,
        backgroundColor: "#f6f6f6",
        borderRadius: 4,
        "&.MuiOutlinedInput-multiline": {
          padding: "0",
        },
        "& .MuiInputBase-input": {
          boxSizing: "border-box",
          // fontSize: "16px",
          color: "#616161",
          padding: "10px 15px",
        },
        "& Input": {
          height: "40px",
          [themeBreakpoints.breakpoints.down("xl")]: {
            height: "35px",
          },
        },
        "& textarea": {
          height: "115px",
        },
      },
    },
    MuiButton: {
      root: {
        padding: "10px 0",
        borderRadius: 4,
        textTransform: "Capitalize",
        fontWeight: "600",
        fontSize: "14px",
      },
    },
    // MuiFormLabel: {
    //   root: {
    //     color: "#616161",
    //     fontSize: "16px",
    //     opacity: ".9",
    //     marginBottom: "8px",
    //   },
    // },
    MuiSelect: {
      backgroundColor: "#f6f6f6",
    },
    MuiAlert: {
      root: {
        alignItems: "center",
      },
    },
    // MuiCssBaseline: {
    //   "@global": {},
    // },
    // MuiTableRow: {
    //   root: {
    //     height: "60px",
    //     "&.Mui-selected": {
    //       background: "none",
    //       "&:hover": {
    //         background: "none",
    //       },
    //     },
    //   },
    // },
    // MuiTablePagination: {
    //   root: {
    //     display: "flex",
    //   },
    // },
    // MuiTableCell: {
    //   root: {
    //     padding: "0px 10px 0 0",
    //     fontSize: "13px",
    //     "&:first-child": {
    //       paddingLeft: 20,
    //     },
    //   },
    //   paddingCheckbox: {
    //     width: "60px",
    //     MuiIconButton: {
    //       colorSecondary: {
    //         "&:hover": {
    //           background: "none",
    //         },
    //         "&:checked": {
    //           color: "#083f85",
    //         },
    //       },
    //     },
    //   },
    // },
    MuiPagination: {
      ul: {
        justifyContent: "flex-end",
      },
    },
    MuiPaginationItem: {
      root: {
        height: "33px",
        padding: "0 3px",
        margin: "0",
        borderRadius: "50%",
      },
      icon: {
        fill: "#616161",
        backgroundColor: "#ececec",
        borderRadius: "50%",
        fontSize: "25px",
      },
      page: {
        "&.Mui-selected": {
          backgroundColor: "#083f85 !important",
          color: "#fff",
        },
      },
    },
    MuiDialog: {
      root: {
        "& .MuiDialog-paper": {
          borderRadius: "10",
        },
      },
    },
    PrivateTabIndicator: {
      root: {
        display: "none",
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 10,
      },
    },
  },
  typography: {
    fontFamily: "Roboto, Arial",
    h1: {
      fontSize: 26,
    },
    h2: {
      fontSize: 24,
    },
    h3: {
      fontSize: 22,
    },
    h4: {
      fontSize: 20,
    },
    h5: {
      fontSize: 18,
    },
    h6: {
      fontSize: 16,
    },
    body1: {
      fontSize: 14,
      fontWeight: "400",
    },
    body2: {
      fontSize: 12,
      fontWeight: "400",
    },
  },
});

export default theme;
