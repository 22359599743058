import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";

const useStyles = makeStyles((theme) => ({
  analyticsCardsWrapper: {
    // margin: "20px 0",
    overflow: "auto",
    display: "flex",
    paddingLeft: 8,
  },
  analyticsCard: {
    minWidth: 260,
    height: 105,
    marginRight: 20,
    borderRadius: 12,
  },
}));

const IssuerList = () => {
  const classes = useStyles();
  return (
    <div className={classes.analyticsCardsWrapper}>
      <Skeleton
        animation="wave"
        variant="rect"
        className={classes.analyticsCard}
      />
      <Skeleton
        animation="wave"
        variant="rect"
        className={classes.analyticsCard}
      />
    </div>
  );
};

export default React.memo(IssuerList);
