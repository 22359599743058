import { Typography } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import DefaultProfilePhoto from "assets/images/DefaultProfilePhoto.jpg";
import { ReactComponent as About } from "assets/images/svg/about.svg";
import MainPattern from "assets/images/svg/bg-pattern-big.svg";
import { ReactComponent as Credentials } from "assets/images/svg/credentials.svg";
import { ReactComponent as Dashboard } from "assets/images/svg/insights.svg";
import { ReactComponent as Notification } from "assets/images/svg/notification-icon.svg";
import { ReactComponent as Profile } from "assets/images/svg/profile.svg";
import { ReactComponent as Verify } from "assets/images/svg/verify.svg";
import CopyButton from "components/CopyButton";
import React from "react";
import { BiLogOut } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { toggleLogoutModal } from "store/commonActions";
import SidebarItem from "./components/SidebarItem";

const useStyles = makeStyles(() => ({
  sidebarNavItem: {
    display: "flex",
    alignItems: "center",
    height: 54,
    width: 257,
    padding: "1.6px 3.6px 6.2px 22px",
    textDecoration: "none",
  },
  iconWrap: {
    height: 28,
    width: 28,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "100%",
    background: "#083f85",
  },
  sidebarNavItemIcon: {
    lineHeight: 0,
    "& svg": {
      height: "auto",
      width: "16px",
    },
  },
  itemDetailsContainer: {
    marginLeft: 10,
    display: "flex",
    flexDirection: "column",
  },
  sidebarNavItemText: {
    fontSize: 16,
    color: "#083f85",
    fontWeight: 500,
  },
  subtitle: {
    fontSize: 10,
    color: "#616161",
  },
  sidebarContainer: {
    width: 280,
  },
  sidebarHeader: {
    background: `no-repeat`,
    padding: "25px 0",
    minHeight: 100,
    width: "100%",
    position: "relative",
    backgroundColor: "#083f85",
    "&:after": {
      content: `' '`,
      position: "absolute",
      backgroundImage: `url(${MainPattern})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "top right",
      backgroundSize: "contain",
      top: 0,
      right: 0,
      width: "70%",
      height: "70%",
      zIndex: 5,
    },
  },
  imgWrapper: {
    width: 60,
    height: 60,
    borderRadius: "50%",
    border: "1px solid white",
    "& img": {
      width: "100%",
      height: "100%",
      borderRadius: "50%",
    },
  },
  userWrapper: {
    marginLeft: 25,
  },
  userDetailsWrapper: {
    userSelect: "none",
    color: "#fff",
    paddingTop: 15,
  },
  userDetails: {
    fontWeight: "bold",
    paddingBottom: 5,
    wordBreak: "break-all",
    display: "block",
  },
  email: {
    paddingBottom: 5,
    display: "block",
    wordBreak: "break-all",
  },
  did: {
    fontSize: 12,
    display: "block",
    wordBreak: "break-all",
    paddingRight: 25,
  },
  navigationListContainer: {
    padding: "30px 0",
    width: "100%",
  },
  sidebarItemList: {
    width: "100%",
    padding: 0,
  },
}));

function Sidebar({ toggleSidebarDrawer, openSidebarDrawer }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const holderDetails = useSelector(
    (state) => state.common.holderDetails.response
  );

  const handleLogout = (event) => {
    dispatch(toggleLogoutModal());
  };

  return (
    <Drawer
      variant="temporary"
      className={classes.siteAside}
      open={openSidebarDrawer}
      onClose={toggleSidebarDrawer}
      classes={{ paperAnchorDockedLeft: classes.sidebarLeftSide }}
    >
      <div className={classes.sidebarContainer}>
        <div className={classes.sidebarHeader}>
          <div className={classes.userWrapper}>
            <div className={classes.imgWrapper}>
              <img
                src={holderDetails.profile_image || DefaultProfilePhoto}
                alt="user"
              />
            </div>
            <div className={classes.userDetailsWrapper}>
              <Typography
                variant="h4"
                component="span"
                className={classes.userName}
              >
                {holderDetails.name}
              </Typography>
              <Typography
                variant="body1"
                component="span"
                className={classes.email}
              >
                {holderDetails.email}
              </Typography>
              {holderDetails?.did ? (
                <Typography
                  variant="body2"
                  component="span"
                  className={classes.did}
                >
                  {holderDetails?.did}
                  <CopyButton content={holderDetails?.did} />
                </Typography>
              ) : null}
            </div>
          </div>
        </div>
        <List className={classes.navigationListContainer}>
          <ListItem className={classes.sidebarItemList}>
            <SidebarItem
              path="/insights"
              itemName="Insights"
              subtitle="Summary of all your credentials"
              icon={<Dashboard />}
              showBadge={false}
            />
          </ListItem>
          <ListItem className={classes.sidebarItemList}>
            <SidebarItem
              path="/credentials"
              itemName="Credentials"
              subtitle="Issued to email Id"
              icon={<Credentials />}
              showBadge={false}
            />
          </ListItem>
          <ListItem className={classes.sidebarItemList}>
            <SidebarItem
              path="/verify-credentials"
              itemName="Verify"
              subtitle="Verify any Everycred credentials"
              icon={<Verify />}
              showBadge={false}
            />
          </ListItem>
          <ListItem className={classes.sidebarItemList}>
            <SidebarItem
              path="/notification"
              itemName="Notification"
              subtitle="Credential notifications"
              icon={<Notification />}
              showBadge={true}
            />
          </ListItem>
          <ListItem className={classes.sidebarItemList}>
            <SidebarItem
              path="/profile"
              itemName="Profile"
              subtitle=""
              icon={<Profile />}
              showBadge={false}
            />
          </ListItem>
          <ListItem className={classes.sidebarItemList}>
            <SidebarItem
              path="/about"
              itemName="About"
              subtitle=""
              icon={<About />}
              showBadge={false}
            />
          </ListItem>
          <ListItem className={classes.sidebarItemList} onClick={handleLogout}>
            <div className={classes.sidebarNavItem}>
              <div className={classes.iconWrap}>
                <i className={classes.sidebarNavItemIcon}>
                  <BiLogOut color="white" />
                </i>
              </div>
              <div className={`${classes.itemDetailsContainer}`}>
                <span className={classes.sidebarNavItemText}>Logout</span>
              </div>
            </div>
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
}

export default React.memo(Sidebar);
