import Layout from "components/layout";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import store from "store";
import setupInterceptors, { getCookie } from "utils";
import defaultRoute from "./defaultRoute";

function AppRoute(route) {
  const token = getCookie("accessToken");
  const isAuthenticated = !route.protected || Boolean(token);

  setupInterceptors(store, useHistory());

  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props) =>
        // pass the sub-routes down to keep nesting
        !isAuthenticated ? (
          <Redirect
            to={{
              pathname: defaultRoute,
              state: { from: props.location },
            }}
          />
        ) : (
          <Layout isChild={route.isChild} config={route?.layout?.config}>
            {route.routes ? (
              <>
                <route.component
                  {...props}
                  routes={route.routes}
                  auth={route.auth}
                />
                <Switch>
                  {route.routes.map((subroute) => (
                    <AppRoute
                      key={subroute.path}
                      auth={route.auth}
                      isChild={true}
                      {...subroute}
                    />
                  ))}
                </Switch>
              </>
            ) : (
              <route.component {...props} auth={route.auth} />
            )}
          </Layout>
        )
      }
    />
  );
}

export default AppRoute;
