import {
  VERIFY_CREDENTIAL_ID_BEGIN,
  VERIFY_CREDENTIAL_ID_SUCCESS,
  VERIFY_CREDENTIAL_ID_FAILURE,
  UPLOAD_JSON_BEGIN,
  UPLOAD_JSON_SUCCESS,
  UPLOAD_JSON_FAILURE,
} from "./verifyCredentials.constants";

const initState = {
  verifyId: {
    loading: false,
    error: "",
    response: {},
  },
  uploadJson: {
    loading: false,
    error: "",
    response: {},
  },
};

export default function verifyCredentialsReducer(state = initState, action) {
  switch (action.type) {
    case VERIFY_CREDENTIAL_ID_BEGIN:
      return {
        ...state,
        verifyId: {
          ...state.verifyId,
          loading: true,
          response: "",
          error: "",
        },
      };
    case VERIFY_CREDENTIAL_ID_SUCCESS:
      return {
        ...state,
        verifyId: {
          ...state.verifyId,
          loading: false,
          response: action.payload,
          error: "",
        },
      };
    case VERIFY_CREDENTIAL_ID_FAILURE:
      return {
        ...state,
        verifyId: {
          ...state.verifyId,
          loading: false,
          error: action.payload,
        },
      };
    case UPLOAD_JSON_BEGIN:
      return {
        ...state,
        uploadJson: {
          ...state.uploadJson,
          loading: true,
        },
      };
    case UPLOAD_JSON_SUCCESS:
      return {
        ...state,
        uploadJson: {
          ...state.uploadJson,
          loading: false,
          response: action.payload,
        },
      };
    case UPLOAD_JSON_FAILURE:
      return {
        ...state,
        uploadJson: {
          ...state.uploadJson,
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
}
