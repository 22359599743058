import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as VisibleIcon } from "assets/images/svg/visible-icon.svg";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  actionBtn: {
    width: 30,
    minWidth: "auto",
    borderRadius: "50%",
    height: 30,
    backgroundColor: "#083f85",
    "&:hover": {
      backgroundColor: "#083f85",
    },
  },
});

function ViewCredentials({ credential }) {
  const classes = useStyles();
  const history = useHistory();
  const selectedIssuer = useSelector(
    (state) => state.credential.selectedIssuer
  );

  const handlePreview = (event) => {
    event.stopPropagation();
    history.push({
      pathname: "/credentials/credential-details",
      state: { credential, issuer: selectedIssuer },
    });
  };
  return (
    <Button className={classes.actionBtn} onClick={handlePreview}>
      <VisibleIcon />
    </Button>
  );
}

export default ViewCredentials;
