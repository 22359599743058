import ForgetPassword from "./ForgetPassword";
import authRoles from "core/auth/authRoles";

const ForgetPasswordConfig = {
  component: ForgetPassword,
  path: "/forgetpassword",
  layout: {
    config: {
      header: {
        display: false,
      },
      sidebar: {
        display: false,
      },
      footer: {
        display: false,
      },
    },
  },
  auth: authRoles.anyOne,
  protected: false,
};

export default ForgetPasswordConfig;
