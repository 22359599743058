import { io } from "socket.io-client";

export const initializeSocket = () => {
  return new Promise((resolve) => {
    const domain = process.env.REACT_APP_SOCKET_HOST;
    const options = {
      path: "/socket.io",
      forceNew: true,
      reconnectionAttempts: 3,
      timeout: 2000,
    };
    const newSocket = io(domain, options);
    newSocket?.on("connect", () => {
      resolve(newSocket);
    });
  });
};
