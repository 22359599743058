import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  tabMain: {
    height: 132,
    margin: "20px 20px 20px 10px",
    borderRadius: 10,
    position: "relative",
  },
  notificationContainer: {
    padding: "80px 20px 0 20px",
    // backgroundColor: "#f6f6f6",
    marginTop: -10,
    minHeight: "calc(100vh - 70px)",
    borderRadius: "20px 20px 0 0",
  },
  headingWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 5,
  },
  titleShadow: {
    height: 20,
    width: 80,
    borderRadius: 10,
  },
  notificationWrapper: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    padding: "15px 0",
    borderBottom: "1px solid #d4d0d0",
  },
  imgWrapper: {
    width: 48,
    flex: "0 0 48px",
    height: 48,
    borderRadius: "50%",
  },
  notificationMsgWrapper: {
    padding: "0 20px",
    flexGrow: 1,
    "& span:first-child": {
      marginBottom: 10,
    },
  },
  message: {
    display: "block",
    width: "100%",
    height: 14,
    borderRadius: 10,
  },
}));

function NotificationSkeleton() {
  const classes = useStyles();
  return (
    <div className={classes.notificationContainer}>
      {" "}
      <div className={classes.headingWrapper}>
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.titleShadow}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.titleShadow}
        />
      </div>{" "}
      <div className={classes.notificationWrapper}>
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.imgWrapper}
        />
        <div className={classes.notificationMsgWrapper}>
          <Skeleton
            animation="wave"
            variant="rect"
            className={classes.message}
          />
          <Skeleton
            animation="wave"
            variant="rect"
            className={classes.message}
          />
        </div>
      </div>
      <div className={classes.notificationWrapper}>
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.imgWrapper}
        />
        <div className={classes.notificationMsgWrapper}>
          <Skeleton
            animation="wave"
            variant="rect"
            className={classes.message}
          />
          <Skeleton
            animation="wave"
            variant="rect"
            className={classes.message}
          />
        </div>
      </div>
      <div className={classes.notificationWrapper}>
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.imgWrapper}
        />
        <div className={classes.notificationMsgWrapper}>
          <Skeleton
            animation="wave"
            variant="rect"
            className={classes.message}
          />
          <Skeleton
            animation="wave"
            variant="rect"
            className={classes.message}
          />
        </div>
      </div>
      <div className={classes.notificationWrapper}>
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.imgWrapper}
        />
        <div className={classes.notificationMsgWrapper}>
          <Skeleton
            animation="wave"
            variant="rect"
            className={classes.message}
          />
          <Skeleton
            animation="wave"
            variant="rect"
            className={classes.message}
          />
        </div>
      </div>
      <div className={classes.notificationWrapper}>
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.imgWrapper}
        />
        <div className={classes.notificationMsgWrapper}>
          <Skeleton
            animation="wave"
            variant="rect"
            className={classes.message}
          />
          <Skeleton
            animation="wave"
            variant="rect"
            className={classes.message}
          />
        </div>
      </div>
      <div className={classes.notificationWrapper}>
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.imgWrapper}
        />
        <div className={classes.notificationMsgWrapper}>
          <Skeleton
            animation="wave"
            variant="rect"
            className={classes.message}
          />
          <Skeleton
            animation="wave"
            variant="rect"
            className={classes.message}
          />
        </div>
      </div>
      <div className={classes.notificationWrapper}>
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.imgWrapper}
        />
        <div className={classes.notificationMsgWrapper}>
          <Skeleton
            animation="wave"
            variant="rect"
            className={classes.message}
          />
          <Skeleton
            animation="wave"
            variant="rect"
            className={classes.message}
          />
        </div>
      </div>
      <div className={classes.notificationWrapper}>
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.imgWrapper}
        />
        <div className={classes.notificationMsgWrapper}>
          <Skeleton
            animation="wave"
            variant="rect"
            className={classes.message}
          />
          <Skeleton
            animation="wave"
            variant="rect"
            className={classes.message}
          />
        </div>
      </div>
      <div className={classes.notificationWrapper}>
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.imgWrapper}
        />
        <div className={classes.notificationMsgWrapper}>
          <Skeleton
            animation="wave"
            variant="rect"
            className={classes.message}
          />
          <Skeleton
            animation="wave"
            variant="rect"
            className={classes.message}
          />
        </div>
      </div>
      <div className={classes.notificationWrapper}>
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.imgWrapper}
        />
        <div className={classes.notificationMsgWrapper}>
          <Skeleton
            animation="wave"
            variant="rect"
            className={classes.message}
          />
          <Skeleton
            animation="wave"
            variant="rect"
            className={classes.message}
          />
        </div>
      </div>
    </div>
  );
}

export default NotificationSkeleton;
