import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/layout/Header";
import IssuerList from "./components/IssuerList";
import CredentialsList from "./components/CredentialsList";
import CredentialsDetailsHeader from "./components/CredentialsDetailsHeader";
import CredentialsDetailsBody from "./components/CredentialsDetailsBody";
import { useDispatch, useSelector } from "react-redux";

import { Switch, Route, useRouteMatch } from "react-router-dom";
import { Typography } from "@material-ui/core";
import UserDetail from "components/UserDetail";
import styles from "assets/css/commonstyle.module.css";
import Error from "pages/NoMatch";
import { clearCredentialReducer } from "./Credentials.action";

const useStyles = makeStyles((theme) => ({
  Main: {
    height: "100vh",
    background: "#083f85",
  },
  content: {
    background: "#f6f6f6",
    zIndex: 2,
    position: "relative",
    borderRadius: "15px 15px 0 0",
    padding: "20px 0 20px 12px",
    minHeight: "60vh",
    margin: "auto 10px ",
    [theme.breakpoints.down("sm")]: {
      margin: "auto ",
    },
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

function Credentials() {
  const classes = useStyles();
  const holderDetails = useSelector(
    (state) => state.common.holderDetails.response
  );
  let { path } = useRouteMatch();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearCredentialReducer());
    };
  }, [dispatch]);

  return (
    <>
      <Switch>
        <Route exact path={"/credentials"}>
          <Header display={true} />
          <div className={classes.Main}>
            <UserDetail name={holderDetails.name} />
            <div className={classes.content}>
              <div className={classes.title}>
                <Typography className={styles.pageTitle}>Issuers</Typography>
                {/* <Link href="#" className={classes.link}>View all</Link> */}
              </div>
              <IssuerList />
              <CredentialsList />
            </div>
          </div>
        </Route>
        <Route path={`${path}/credential-details`}>
          <CredentialsDetailsHeader />
          <CredentialsDetailsBody />
        </Route>
        <Route exact path={`${path}/*`}>
          <Error />
        </Route>
      </Switch>
    </>
  );
}

export default Credentials;
