import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputLabel,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import styles from "assets/css/commonstyle.module.css";
import MainPattern from "assets/images/main-pattern.png";
import AppLogo from "components/AppLogo";
import AuthImage from "components/AuthImage/AuthImage";
import Loader from "components/Loader";
import RedirectComponent from "components/RedirectComponent";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toggleOfflineModal } from "store/commonActions";
import { emailRegex, getCookie, saveRememberInCookie } from "utils";
import SocialLogin from "./components/SocialLogin";
import { login } from "./login.action";

const useStyles = makeStyles((theme) => ({
  button: {
    width: "100%",
    marginBottom: "25px 0",
    borderRadius: "5px",
    padding: "10px",
    fontWeight: "500",
  },
  preLoginContentWrapper: {
    textAlign: "center",
    // maxWidth: "490px",
    margin: "0 auto",
  },
  containerWrapper: {
    maxWidth: "500px",
    margin: "0 auto",
  },
  fields: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 15,
    "& label": {
      textAlign: "left",
      marginBottom: 7,
    },
    "& .MuiOutlinedInput-root": {
      marginBottom: 6,
    },
  },

  checkbox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginLeft: 8,
    "& p": {
      fontSize: "15px",
      paddingLeft: "5px",
      letterSpacing: "normal",
      color: "#616161",
    },
    forgetPw: {
      fontWeight: 500,
    },
    "& a": {
      textDecoration: "none",
      fontWeight: "500",
      fontSize: 12,
      color: " #083f85",
    },
  },
  rememberContainer: {
    display: "flex",
    justifyContent: "spaceBetween",
    alignItems: "center",
    "& .MuiTypography-body1": {
      fontWeight: 500,
      fontSize: 12,
      color: " #616161",
      paddingLeft: 5,
    },
    "& .MuiFormControlLabel-root": {
      display: "block",
    },
  },
  checkedIcon: {
    root: {
      "&$checked": {
        color: "black",
      },
    },
    checked: {},
  },
  responseError: {
    marginTop: "15px",
    textAlign: "center",
    "& p": {
      color: "red",
      fontWeight: "500",
      fontSize: "13.5px",
    },
  },
  customLabel: {
    fontSize: "12px",
    fontWeight: "600",
    color: "#616161",
  },
  heading: {
    paddingBottom: 25,
  },
  pageContainer: {
    padding: "0 20px",
    background: `url(${MainPattern}) no-repeat`,
    backgroundSize: "contain",
  },
  buttonWrapper: {
    padding: "20px 0 10px",
    "& button": {
      height: 36,
    },
  },
  formWrapper: {
    padding: "20px 0",
  },
}));

function Login() {
  const classes = useStyles();
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState({
    emailError: { helperText: "", errorStatus: false },
    passwordError: { helperText: "", errorStatus: false },
    responseError: {
      errorStatus: false,
      helperText: "",
    },
  });

  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { normalLogin, SSOLogin } = useSelector((state) => state.login);
  const isUserOnline = useSelector((state) => state.common.isUserOnline);

  useEffect(() => {
    const rememberMe = getCookie("rememberMe");
    const token = getCookie("accessToken");
    if (rememberMe) {
      if (JSON.parse(rememberMe)) {
        const user = JSON.parse(decodeURIComponent(getCookie("user")));
        setData({
          email: user.email,
          password: user.password,
        });
        setRememberMe(JSON.parse(rememberMe));
      }
    }
    if (token) {
      history.push("/insights");
    }
  }, [history]);

  const handleChange = (event) => {
    let { name, value } = event.target;
    switch (name) {
      case "email":
        setData({
          ...data,
          email: value,
        });
        setError({
          ...error,
          responseError: {
            errorStatus: false,
            helperText: "",
          },
          emailError: {
            errorStatus: false,
            helperText: "",
          },
        });
        if (value === "") {
          setError({
            ...error,
            emailError: {
              errorStatus: true,
              helperText: "*This field is required!",
            },
          });
        }
        break;
      case "password":
        setData({
          ...data,
          password: value,
        });
        setError({
          ...error,
          responseError: {
            errorStatus: false,
            helperText: "",
          },
          passwordError: {
            errorStatus: false,
            helperText: "",
          },
        });
        if (value === "") {
          setError({
            ...error,
            passwordError: {
              errorStatus: true,
              helperText: "*This field is required!",
            },
          });
        }
        break;
      default:
        setData({ ...data });
    }
  };
  const handlePasswordVisibility = (event) => {
    setShowPassword(!showPassword);
  };

  const handleCheckbox = (event) => {
    setRememberMe(!rememberMe);
  };

  const validate = () => {
    let { email } = data;
    if (emailRegex.test(email)) {
      return true;
    } else {
      setError({
        ...error,
        emailError: {
          helperText: "Field Must Contain Valid Email",
          errorStatus: true,
        },
      });
      return false;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const requestBody = {
      email: data.email.trim(),
      password,
    };

    if (!isUserOnline) {
      dispatch(toggleOfflineModal(!isUserOnline));
      return;
    }
    if (
      validate() &&
      !error.responseError.errorStatus &&
      !error.passwordError.errorStatus
    ) {
      const successCB = ({ token }) => {
        setData({
          email: "",
          password: "",
        });
        if (token) {
          history.push("/insights");
        }
      };
      const FailureCB = (message) => {
        setError({
          ...error,
          responseError: {
            errorStatus: true,
            helperText: message,
          },
        });
      };
      dispatch(login(requestBody, successCB, FailureCB));
      saveRememberInCookie(rememberMe);
      document.cookie = rememberMe
        ? `user=${encodeURIComponent(
            JSON.stringify(requestBody)
          )}; expires=Thu, 18 Dec 2093 12:00:00 UTC; path=/`
        : "";
    }
  };

  const { email, password } = data;
  const { emailError, passwordError, responseError } = error;
  const { loading } = normalLogin;

  const errorInResponse = responseError.errorStatus ? (
    <div className={classes.responseError}>
      <Typography variant="body1">{responseError.helperText}</Typography>
    </div>
  ) : null;

  return (
    <Grid container className={classes.pageContainer}>
      <Grid item xs={12}>
        <AuthImage />
      </Grid>
      <Grid item xs={12} className={classes.preLoginContentWrapper}>
        <div className={classes.containerWrapper}>
          <Typography
            variant="h1"
            component="p"
            className={classes.heading}
            color="primary"
            align="center"
          >
            Welcome back!
          </Typography>
          <AppLogo />
          <form className={classes.formWrapper} onSubmit={handleSubmit}>
            <div className={classes.fields}>
              <InputLabel className={classes.customLabel}>Email</InputLabel>
              <TextField
                type="email"
                name="email"
                variant="outlined"
                className={styles.primaryInput}
                placeholder="Enter Email Address"
                size="small"
                value={email}
                onChange={handleChange}
                error={emailError.errorStatus}
                helperText={emailError.helperText}
                required
              />
            </div>
            <div className={classes.fields}>
              <InputLabel className={classes.customLabel}>Password</InputLabel>
              <TextField
                type={showPassword ? "text" : "password"}
                name="password"
                className={styles.primaryInput}
                variant="outlined"
                placeholder="Enter Your Password"
                size="small"
                value={password}
                onChange={handleChange}
                error={passwordError.errorStatus}
                helperText={passwordError.helperText}
                required
                InputProps={{
                  endAdornment: (
                    <>
                      {!showPassword ? (
                        <VisibilityOffOutlinedIcon
                          fontSize="small"
                          color="primary"
                          onClick={handlePasswordVisibility}
                          style={{ cursor: "pointer", paddingTop: "3px" }}
                        />
                      ) : (
                        <VisibilityOutlinedIcon
                          fontSize="small"
                          color="primary"
                          onClick={handlePasswordVisibility}
                          style={{ cursor: "pointer", paddingTop: "3px" }}
                        />
                      )}
                    </>
                  ),
                }}
              />
            </div>
            <div className={classes.checkbox}>
              <div className={classes.rememberContainer}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      style={{ padding: "0" }}
                      onChange={handleCheckbox}
                      checked={rememberMe}
                      checkedIcon={<CheckBoxOutlinedIcon />}
                    />
                  }
                  label="Remember Me"
                />
              </div>
              <div className={classes.forgetPw}>
                <Link to="/forgetpassword">Forgot Password?</Link>
              </div>
            </div>
            <div className={classes.buttonWrapper}>
              <Button
                fullWidth
                type="submit"
                color="primary"
                variant="contained"
                disabled={loading || SSOLogin.loading}
              >
                <Loader show={loading || SSOLogin.loading} />
                {!loading && !SSOLogin.loading ? "Log in" : null}
              </Button>
            </div>
            {errorInResponse}
          </form>
        </div>
        <SocialLogin />
        <RedirectComponent
          text="I don't have an account?"
          path="/register"
          name="Register Here"
        />
      </Grid>
    </Grid>
  );
}

export default Login;
