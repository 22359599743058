import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  formField: {
    marginBottom: 15,
    display: "flex",
    alignItems: "center",
    "&:last-child": {
      marginBottom: 0,
    },
  },
  customLabel: {
    width: 60,
    height: 15,
    marginRight: 55,
    borderRadius: 2,
  },
  inputField: {
    width: 220,
    height: 35,
    borderRadius: 5,
  },
}));

const Credentials = () => {
  const classes = useStyles();
  return (
    <div className={classes.formField}>
      <Skeleton
        animation="wave"
        variant="rect"
        className={classes.customLabel}
      />
      <Skeleton
        animation="wave"
        variant="rect"
        className={classes.inputField}
      />
    </div>
  );
};

export default Credentials;
