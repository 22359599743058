import { Dialog, makeStyles, Paper, Typography } from "@material-ui/core";
import offline from "assets/images/svg/offline.svg";
import { useDispatch, useSelector } from "react-redux";
import { toggleOfflineModal } from "store/commonActions";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    position: "relative",
    width: 270,
    maxWidth: 278,
    padding: "20px 10px",
    borderRadius: 10,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    "& img": {
      width: "20%",
    },
  },
  red: {
    color: "#ff0000c9",
  },
  message: {
    textAlign: "center",
    paddingTop: 15,
  },
  heading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "& span": {
      fontWeight: "bold",
      padding: "20px 0",
    },
  },
  headingIconWrapper: {
    backgroundColor: "#083f85",
    width: 52,
    height: 52,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
  },
  customLabel: {
    fontSize: 12,
    fontWeight: 600,
    color: "#616161",
  },
  formWrapper: {
    paddingTop: 20,
  },
  actionField: {
    display: "flex",
    marginBottom: 20,
    "&:last-child": {
      marginBottom: 0,
    },
    "& .MuiButton-root": {
      minWidth: 0,
    },
  },
  field: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    marginRight: 7,
    "& label": {
      textAlign: "left",
      marginBottom: 7,
    },
    "& .MuiOutlinedInput-root": {
      marginBottom: 0,
    },
  },
  actionButtonWrapper: {
    width: "32px",
    height: "36px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "19px",
    borderRadius: "4px",
    backgroundColor: "#083f85",
    "& svg": {
      fill: "#fff",
    },
  },
  closeIconWrapper: {
    top: 0,
    right: 0,
    fontWeight: "bold",
    position: "absolute",
  },
}));

export default function OfflineModal() {
  const classes = useStyles();
  const { show } = useSelector((state) => state.common.offlineModal);
  const dispatch = useDispatch();

  const handleClose = (event) => {
    event.stopPropagation();
    dispatch(toggleOfflineModal(false));
  };
  return (
    <Dialog open={show} onClose={handleClose}>
      <Paper className={classes.main}>
        <div className={classes.dialogWrapper}>
          <img src={offline} alt="offline" />
          <div className={classes.message}>
            <Typography variant="h6" component="p" color="secondary">
              Check Your Internet Conectivity
            </Typography>
            <Typography variant="subtitle2" component="p" color="secondary">
              You are Offline
            </Typography>
          </div>
        </div>
      </Paper>
    </Dialog>
  );
}
