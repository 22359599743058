import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as AnalyticsIcon } from "assets/images/svg/analytics.svg";
import { ReactComponent as PreviewIcon } from "assets/images/svg/preview.svg";
import PropTypes from "prop-types";
import React from "react";
import { useLocation } from "react-router-dom";
import CredentialImage from "../../../Actions/CredentialImage";
import DownloadCredentials from "../../../Actions/DownloadCredentials";
import PreviewCredential from "../../../Actions/PreviewCredential";
import ScanCredentials from "../../../Actions/ScanCredentials";
import ShareCredentials from "../../../Actions/ShareCredentials";
import Analytics from "./Analytics";
import Preview from "./Preview";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    background: "transparent",
  },
  tabsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 10,
    "& button": {
      padding: 0,
      marginRight: 5,
      "&:last-child": {
        marginRight: 0,
      },
    },
    "& .MuiTab-root": {
      minWidth: 0,
    },
  },
  tabListActive: {
    width: 90,
    minHeight: 30,
    background: "#083f85",
    display: "flex",
    alignItems: "center",
    borderRadius: 15,
    position: "relative",
    padding: "0px 0px 0px 15px",
    "&::before": {
      position: "absolute",
      content: `'Preview'`,
      fontSize: 13,
      top: 3,
      right: 0,
      left: 22,
      bottom: 0,
      textTransform: "capitalize",
      color: "#fff",
    },
  },
  tabListActive1: {
    width: 90,
    minHeight: 30,
    background: "#083f85",
    display: "flex",
    alignItems: "center",
    borderRadius: 15,
    position: "relative",
    padding: "0px 0px 0px 15px",
    "&::before": {
      position: "absolute",
      content: `'Insights'`,
      fontSize: 13,
      top: 3,
      right: 0,
      left: 22,
      bottom: 0,
      textTransform: "capitalize",
      color: "#fff",
    },
  },
  tabList: {
    background: "#083f85",
    minWidth: 30,
    minHeight: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    marginLeft: 25,
    "&:first-child": {
      marginLeft: 0,
    },
  },
  otherActionContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    flexGrow: 1,
  },
}));

export default function ScrollableTabsButtonAuto() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const location = useLocation();
  let { credential } = location.state;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <div className={classes.tabsContainer}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab
            label={
              <div
                className={
                  value === 0 ? classes.tabListActive : classes.tabList
                }
              >
                <PreviewIcon />
              </div>
            }
            {...a11yProps(0)}
          />
          <Tab
            label={
              <div
                className={
                  value === 1 ? classes.tabListActive1 : classes.tabList
                }
              >
                <AnalyticsIcon />
              </div>
            }
            {...a11yProps(1)}
          />
        </Tabs>
        <div className={classes.otherActionContainer}>
          <ShareCredentials
            record_id={credential.id}
            share_link={credential.viewer_link}
          />
          <DownloadCredentials record_id={credential.id} />
          <ScanCredentials qrCode={credential.qrcode_path} />
          <CredentialImage />
          <PreviewCredential link={credential.viewer_link} />
        </div>
      </div>

      <TabPanel value={value} index={0}>
        <Preview />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Analytics />
      </TabPanel>
    </div>
  );
}
