/**
 * Authorization Roles
 */
const authRoles = {
  admin: ["admin"],
  organization: ["admin", "organization"],
  user: ["admin", "organization", "user"],
  onlyGuest: ["guest"],
  anyOne: ["admin", "organization", "user", "guest"],
};

export default authRoles;
