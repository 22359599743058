import {
  UPDATE_USER_PROFILE_BEGIN,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAILURE,
  CHANGE_USER_PASSWORD_BEGIN,
  CHANGE_USER_PASSWORD_SUCCESS,
  CHANGE_USER_PASSWORD_FAILURE,
  CLEAR_UPDATE_USER_PROFILE_RESPONSE,
} from "./profile.constant";

import { http, headers } from "utils";
import { openNotification, setHolderDetails } from "store/commonActions";

// Update Profile
export const updateUserProfileBegin = () => ({
  type: UPDATE_USER_PROFILE_BEGIN,
});

export const updateUserProfileSuccess = (payload) => ({
  type: UPDATE_USER_PROFILE_SUCCESS,
  payload,
});

export const updateUserProfileFailure = (payload) => ({
  type: UPDATE_USER_PROFILE_FAILURE,
  payload,
});

export const updateUserProfile =
  (updateProfileData, successCB, failureCB) => (dispatch) => {
    dispatch(updateUserProfileBegin());
    http("put", "/holder/update", updateProfileData, { headers }, true)
      .then(function (response) {
        dispatch(updateUserProfileSuccess(response.data));
        dispatch(
          openNotification({
            message: "Your profile has been updated successfully!",
          })
        );
        successCB(response);
      })
      .catch(function (error) {
        dispatch(updateUserProfileFailure(error));
        failureCB(error.response?.data?.message);
      });
  };

// Change Password
export const changeUserPasswordBegin = () => ({
  type: CHANGE_USER_PASSWORD_BEGIN,
});

export const changeUserPasswordSuccess = (payload) => ({
  type: CHANGE_USER_PASSWORD_SUCCESS,
  payload,
});

export const changeUserPasswordFailure = (payload) => ({
  type: CHANGE_USER_PASSWORD_FAILURE,
  payload,
});

export const changeUserPassword =
  (updateProfileData, successCB, failureCB) => (dispatch) => {
    dispatch(changeUserPasswordBegin());

    http("put", "/holder/password/change", updateProfileData, { headers }, true)
      .then(function (response) {
        dispatch(changeUserPasswordSuccess(response.data));
        dispatch(setHolderDetails(response.data.data));
        dispatch(
          openNotification({
            message: "Your password has been changed successfully!",
          })
        );
        successCB(response);
      })
      .catch(function (error) {
        dispatch(changeUserPasswordFailure(error));
        failureCB(error.response?.data?.message);
      });
  };

export const clearUpdateUserProfileResponse = (payload) => ({
  type: CLEAR_UPDATE_USER_PROFILE_RESPONSE,
  payload,
});
