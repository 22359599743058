import {
  SET_NEW_NOTIFICATION,
  GET_NOTIFICATION_LIST_BEGIN,
  GET_NOTIFICATION_LIST_SUCCESS,
  GET_NOTIFICATION_LIST_FAILURE,
  SET_PARAMETER,
  READ_NOTIFICATIONS_BEGIN,
  READ_NOTIFICATIONS_SUCCESS,
  READ_NOTIFICATIONS_FAILURE,
} from "./Notification.constant";

const initState = {
  notificationList: {
    loading: false,
    error: "",
    response: {},
    page: 1,
    perPage: 10,
  },
  readNotification: {
    loading: false,
    response: "",
    error: "",
  },
};

export default function NotificationReducer(state = initState, action) {
  switch (action.type) {
    case GET_NOTIFICATION_LIST_BEGIN:
      return {
        ...state,
        notificationList: {
          ...state.notificationList,
          loading: true,
        },
      };
    case GET_NOTIFICATION_LIST_SUCCESS:
      return {
        ...state,
        notificationList: {
          ...state.notificationList,
          loading: false,
          response: action.payload,
        },
      };
    case GET_NOTIFICATION_LIST_FAILURE:
      return {
        ...state,
        notificationList: {
          ...state.notificationList,
          loading: false,
          error: action.payload,
        },
      };
    case SET_PARAMETER:
      return {
        ...state,
        notificationList: {
          ...state.notificationList,
          page: action.payload?.page || state.notificationList.page,
          perPage: action.payload?.perPage || state.notificationList.perPage,
        },
      };
    case SET_NEW_NOTIFICATION:
      return {
        ...state,
        notificationList: {
          ...state.notificationList,
          response: {
            ...state.notificationList.response,
            data: action.payload.data,
            pages: action.payload.pages,
            total: action.payload.total,
          },
        },
      };

    case READ_NOTIFICATIONS_BEGIN:
      return {
        ...state,
        readNotification: {
          ...state.readNotification,
          loading: true,
        },
      };
    case READ_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        readNotification: {
          ...state.readNotification,
          loading: false,
          response: action.payload,
        },
      };
    case READ_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        readNotification: {
          ...state.readNotification,
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
}
