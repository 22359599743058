import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
}));

function Loader({ show, size = 25, color = "inherit" }) {
  const classes = useStyles();

  if (!show) return null;

  return (
    <div className={classes.root}>
      <CircularProgress color={color} size={size} />
    </div>
  );
}

export default React.memo(Loader);
