import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { useEffect, useState } from "react";
import ReactImageCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    gap: "10px",
    justifyContent: "center",
  },
  buttonGen: {
    width: 150,
    marginRight: 20,
    "&:last-child": {
      marginRight: 0,
    },
    [theme.breakpoints.down("sm")]: {
      width: 80,
      marginRight: 10,
      padding: "3px 0",
    },
  },
  cancel: {
    backgroundColor: "#f6f6f6",
    border: "solid 1px #d5d5d5",
  },
  title: {
    fontWeight: "bold",
  },
  preview: {
    paddingBottom: 5,
    fontWeight: 600,
  },
  closeIconWrapper: {
    top: 10,
    right: 10,
    fontWeight: "bold",
    position: "absolute",
    [theme.breakpoints.down("sm")]: {
      top: 4,
    },
  },
  customizeDefault: {
    justifyContent: "center",
  },
}));

export default function ImageCropper(props) {
  const classes = useStyles();
  const { crop, setCrop, src, setSrc, open, setOpen, setFiles, children } =
    props;
  const [image, setImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState("");

  useEffect(() => {
    if (image) {
      getCroppedImg(crop);
    }
    //eslint-disable-next-line
  }, [image]);

  function handleClose(event) {
    event.stopPropagation();
    setOpen(false);
    setCroppedImageUrl("");
    setImage(null);
    setSrc("");
  }

  function submitImage(event) {
    handleClose(event);
    setFiles(croppedImageUrl);
  }

  function onImageLoaded(image) {
    setImage(image);
  }

  function onCropChange(crop) {
    setCrop(crop);
  }

  async function makeClientCrop(crop) {
    if (image && crop.width && crop.height) {
      await getCroppedImg(crop);
    }
  }

  function getCroppedImg(crop) {
    const fileExtension = image.src.slice(
      image.src.indexOf("/") + 1,
      image.src.indexOf(";")
    );
    const fileName = `image_${Date.now()}.${fileExtension}`;
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (blob) {
          blob.name = fileName;
          const fileImage = window.URL.createObjectURL(blob);
          setCroppedImage(fileImage);
          const fileUrl = new File([blob], fileName, {
            type: `image/${fileExtension}`,
          });
          fileUrl.preview = URL.createObjectURL(fileUrl);
          setCroppedImageUrl(fileUrl);
          resolve(fileUrl);
        }
      }, `image/${fileExtension}`);
    });
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <DialogTitle id="image-crop-box">
          <Typography
            variant="h4"
            component="p"
            className={classes.title}
            color="primary"
          >
            Crop Image
            <IconButton
              className={classes.closeIconWrapper}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Typography>
        </DialogTitle>
        <DialogContent id="crop-image-content">
          {children}
          <div className="flex grid grid-cols-12">
            <ReactImageCrop
              style={{ display: "block" }}
              className="grid col-span-7"
              ruleOfThirds
              crop={crop}
              src={src}
              onImageLoaded={onImageLoaded}
              onChange={onCropChange}
              onComplete={makeClientCrop}
            />
            <Divider orientation="vertical" className="mx-8" flexItem />
            {croppedImageUrl && (
              <div className="">
                <Typography
                  variant="h6"
                  component="p"
                  color="primary"
                  className={classes.preview}
                >
                  Preview
                </Typography>
                <img src={croppedImage} alt="cropped" />
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions className={classes.customizeDefault}>
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              className={[classes.buttonGen, classes.cancel].join(" ")}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              disabled={!croppedImageUrl}
              variant="contained"
              onClick={submitImage}
              color="primary"
              className={classes.buttonGen}
            >
              Crop
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
