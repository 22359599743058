export const emailRegex = RegExp(/^[a-z0-9._-]+@[a-z-]+(\.[a-z]{2,})+$/);

export const passwordRegex = RegExp(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])(?=.{8,})/gm
);

export const mobileNumberRegex = RegExp(/^[6-9]\d{9}$/);

export const urlRegex = RegExp(
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/
);

export const NAME_INPUT_LIMIT = 80;
