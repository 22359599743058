import {
  VERIFY_CREDENTIAL_ID_BEGIN,
  VERIFY_CREDENTIAL_ID_SUCCESS,
  VERIFY_CREDENTIAL_ID_FAILURE,
  UPLOAD_JSON_BEGIN,
  UPLOAD_JSON_SUCCESS,
  UPLOAD_JSON_FAILURE,
} from "./verifyCredentials.constants";

import { http, headers } from "utils";
import { openNotification } from "store/commonActions";

// Verify Credential ID
export const verifyCredentialsBegin = () => ({
  type: VERIFY_CREDENTIAL_ID_BEGIN,
});

export const verifyCredentialsSuccess = (payload) => ({
  type: VERIFY_CREDENTIAL_ID_SUCCESS,
  payload,
});

export const verifyCredentialsFailure = (payload) => ({
  type: VERIFY_CREDENTIAL_ID_FAILURE,
  payload,
});

export const verifyCredentials = (credId, successCB) => (dispatch) => {
  dispatch(verifyCredentialsBegin());
  http(
    "get",
    `/user/credential/validate?uuid=${credId}`,
    null,
    { headers },
    true
  )
    .then(function (response) {
      dispatch(verifyCredentialsSuccess(response.data));
      successCB(response);
    })
    .catch(function (error) {
      dispatch(verifyCredentialsFailure(error));
      dispatch(
        openNotification({
          message: "Credential ID is invalid!",
          severity: "error",
        })
      );
    });
};

// Upload JSON

const uploadJsonBegin = () => ({
  type: UPLOAD_JSON_BEGIN,
});

const uploadJsonSuccess = (payload) => ({
  type: UPLOAD_JSON_SUCCESS,
  payload,
});

const uploadJsonFailure = (payload) => ({
  type: UPLOAD_JSON_FAILURE,
  payload,
});

export const uploadJson = (body, successCB) => (dispatch) => {
  dispatch(uploadJsonBegin());
  http("post", "/user/credential/upload/json", body, { headers }, true)
    .then(function (response) {
      dispatch(uploadJsonSuccess(response?.data?.data));
      successCB(response?.data?.data);
    })
    .catch(function (error) {
      dispatch(uploadJsonFailure(error?.response?.data?.message));
      dispatch(
        openNotification({
          message: error?.response?.data?.message,
          severity: "error",
        })
      );
    });
};
