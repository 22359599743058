import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { useGoogleLogout } from "react-google-login";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { logout, toggleLogoutModal } from "store/commonActions";

const useStyles = makeStyles((theme) => ({
  main: {
    padding: "40px 0",
    minWidth: 278,
    minHeight: 200,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: 10,
  },
  subTitle: {
    color: "#535353",
    lineHeight: "29px",
    padding: "25px 0",
  },
  actionBtn: {
    width: 100,
    height: 40,
    border: "1px solid #d5d5d5",
    backgroundColor: "#f6f6f6",
    color: "#7e7e7e",
    borderRadius: 25,
    marginRight: 15,
  },
  actionBtnNo: {
    width: 100,
    height: 40,
    border: "1px solid #d5d5d5",
    backgroundColor: "#083f85",
    color: "#fff",
    borderRadius: 25,
    "&:hover": {
      backgroundColor: "#083f85",
      color: "#fff",
    },
  },
  closeIconWrapper: {
    top: 0,
    right: 0,
    fontWeight: "bold",
    position: "absolute",
  },
  heading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "bold",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
}));

function LogOutModal() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { show } = useSelector((state) => state.common.logout);
  const history = useHistory();

  const onLogoutSuccess = () => {};
  const onFailure = () => {};
  const { signOut } = useGoogleLogout({
    onFailure,
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    onLogoutSuccess,
  });

  const handleLogOut = (event) => {
    event.stopPropagation();
    dispatch(logout());
    signOut();
    history.push("/");
  };

  const handleClose = (event) => {
    event.stopPropagation();
    dispatch(toggleLogoutModal());
  };

  return (
    <Dialog open={show} onClose={handleClose}>
      <Paper className={classes.main}>
        <IconButton className={classes.closeIconWrapper} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <Typography
          variant="h5"
          component="span"
          color="primary"
          className={classes.heading}
        >
          Log Out
        </Typography>
        <Typography variant="h6" className={classes.subTitle} component="span">
          Are you sure you want to logout?
        </Typography>
        <div className={classes.buttonContainer}>
          <Button
            type="button"
            className={classes.actionBtn}
            onClick={handleLogOut}
          >
            Yes
          </Button>
          <Button
            type="button"
            className={classes.actionBtnNo}
            onClick={handleClose}
          >
            NO
          </Button>
        </div>
      </Paper>
    </Dialog>
  );
}

export default LogOutModal;
