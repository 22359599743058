import React from "react";
import { makeStyles } from "@material-ui/core";
import AnalyticsCards from "./AnalyticsCards";
import Recent from "./Recent";

const useStyles = makeStyles((theme) => ({
  actionWrapper: {
    paddingTop: 30,
  },
  formWrapper: {
    padding: "20px 0",
  },
  customLabel: {
    fontSize: 12,
    fontWeight: 600,
    minWidth: 85,
    maxWidth: 85,
    marginRight: 20,
    marginBottom: 0,
    textTransform: "capitalize",
  },
  formField: {
    marginBottom: 15,
    display: "flex",
    alignItems: "center",
    "&:last-child": {
      marginBottom: 0,
    },
    "& .MuiFormControl-root": {
      flexGrow: 1,
    },
    "& input": {
      height: "35px !important",
      fontSize: "12px !important",
      background: "#f6f6f6",
      minWidth: 180,
      borderRadius: 6,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiOutlinedInput-root": {
      marginBottom: 0,
    },
  },
}));
export default function Analytics() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.actionWrapper}>
        <AnalyticsCards />
        <Recent />
      </div>
    </React.Fragment>
  );
}
