import aboutConfig from "pages/About/AboutConfig";
import forgetPasswordConfig from "pages/ForgetPassword/forgetPasswordconfig";
import insightsConfig from "pages/Insights/InsinghtsConfig";
import LinkedInConfig from "pages/Login/components/SocialLogin/LinkedInLogin/LinkedLogin.config";
import loginConfig from "pages/Login/loginConfig";
import noMatchConfig from "pages/NoMatch/noMatchConfig";
import notificationConfig from "pages/Notification/Notification.config";
import profileConfig from "pages/Profile/profileConfig";
import registerConfig from "pages/Register/registerConfig";
import resetPasswordConfig from "pages/ResetPassword/resetPasswordConfig";
import verifyConfig from "pages/VerifyCredentials/verifyConfig";
import verifyEmailConfig from "pages/VerifyEmail/verifyEmailConfig";
import credentialsConfig from "pages/credentials/CredentialsConfig";

const routes = [
  loginConfig,
  registerConfig,
  forgetPasswordConfig,
  insightsConfig,
  resetPasswordConfig,
  credentialsConfig,
  notificationConfig,
  profileConfig,
  aboutConfig,
  verifyConfig,
  LinkedInConfig,
  verifyEmailConfig,
  noMatchConfig,
];

export default routes;
