import { http, headers, deleteCookie } from "utils";
import {
  OPEN_NOTIFICATION,
  CLOSE_NOTIFICATION,
  LOGOUT_USER,
  TOGGLE_LOGOUT_MODAL,
  GET_HOLDER_DETAILS_BEGIN,
  GET_HOLDER_DETAILS_SUCCESS,
  GET_HOLDER_DETAILS_FAILURE,
  SET_HOLDER_DETAILS,
  TOGGLE_OFFLINE_MODAL,
  TOGGLE_USER_NETWORK_STATUS,
  SET_NEW_SOCKET,
} from "./constants";

// Notification
export const openNotification = (payload) => ({
  type: OPEN_NOTIFICATION,
  payload: payload,
});

export const closeNotification = () => ({
  type: CLOSE_NOTIFICATION,
});

export const logOutUser = () => ({
  type: LOGOUT_USER,
});

export const toggleLogoutModal = () => ({
  type: TOGGLE_LOGOUT_MODAL,
});

export const logout = () => (dispatch, getState) => {
  let { newSocket } = getState().common;
  deleteCookie("rememberMe");
  deleteCookie("accessToken");
  deleteCookie("user");
  newSocket?.close?.();
  dispatch(logOutUser());
};

// Get Holder Details

const getHolderDetailsBegin = () => ({
  type: GET_HOLDER_DETAILS_BEGIN,
});
const getHolderDetailsSuccess = (payload) => ({
  type: GET_HOLDER_DETAILS_SUCCESS,
  payload,
});

const getHolderDetailsFailure = (payload) => ({
  type: GET_HOLDER_DETAILS_FAILURE,
  payload,
});

export const getHolderDetails = () => (dispatch) => {
  dispatch(getHolderDetailsBegin());
  http("get", "/holder", null, { headers }, true)
    .then((response) => {
      dispatch(getHolderDetailsSuccess(response.data.data));
    })
    .catch((err) => {
      dispatch(getHolderDetailsFailure(err.response?.data?.message));
    });
};

export const setHolderDetails = (payload) => ({
  type: SET_HOLDER_DETAILS,
  payload,
});

export const toggleOfflineModal = (payload) => ({
  type: TOGGLE_OFFLINE_MODAL,
  payload,
});

export const toggleUserNetworkStatus = (payload) => ({
  type: TOGGLE_USER_NETWORK_STATUS,
  payload,
});

// Socket

export const setNewSocket = (payload) => ({
  type: SET_NEW_SOCKET,
  payload,
});
