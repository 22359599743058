export const OPEN_NOTIFICATION = "OPEN_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";

export const LOGOUT_USER = "LOGOUT_USER";
export const TOGGLE_LOGOUT_MODAL = "SHOW_LOGOUT_MODAL";

export const TOGGLE_USER_NETWORK_STATUS = "TOGGLE_USER_NETWORK_STATUS";

export const GET_HOLDER_DETAILS_BEGIN = "GET_HOLDER_DETAILS_BEGIN";
export const GET_HOLDER_DETAILS_SUCCESS = "GET_HOLDER_DETAILS_SUCCESS";
export const GET_HOLDER_DETAILS_FAILURE = "GET_HOLDER_DETAILS_FAILURE";
export const SET_HOLDER_DETAILS = "SET_HOLDER_DETAILS";

export const TOGGLE_OFFLINE_MODAL = "TOGGLE_OFFLINE_MODAL";

export const SET_NEW_SOCKET = "SET_NEW_SOCKET";
