import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import styles from "assets/css/commonstyle.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getIssuerList,
  loadMoreIssuer,
  selectIssuer,
} from "pages/credentials/Credentials.action";
import EmailIcon from "assets/images/svg/email-icon.svg";
import ChevronLeftOutlinedIcon from "@material-ui/icons/ChevronLeftOutlined";
import ChevronRightOutlinedIcon from "@material-ui/icons/ChevronRightOutlined";

import IssuerListSkelton from "components/Skeletons/IssuerList";
import { toggleOfflineModal } from "store/commonActions";

const useStyles = makeStyles((theme) => ({
  analyticsCardsWrapper: {
    margin: "20px 0",
    flexWrap: "nowrap",
    overflow: "auto",
    display: "flex",
    paddingLeft: 8,
    scrollBehavior: "smooth",
  },
  analyticsTitle: {
    color: "#ffffff",
    fontWeight: "bold",
    textAlign: "left",
    maxWidth: 150,
    wordBreak: "break-word",
    "-webkit-line-clamp": 2,
    overflow: "hidden",
    "-webkit-box-orient": "vertical",
    display: "-webkit-box",
  },
  analyticsCard: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    borderRadius: 12,
    position: "relative",
    zIndex: 1,
    padding: 20,
    height: 60,
    width: "100%",
    minWidth: 240,
    marginRight: 20,
  },

  cardContent: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  cardIconsWrapper: {
    backgroundColor: "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 60,
    height: 60,
    borderRadius: 10,
    marginRight: 15,
  },
  cardIcon: {
    lineHeight: 0,
    width: 30,
    "& img": {
      width: "100%",
      height: "auto",
    },
  },
  detailWrapper: {
    flexGrow: 1,
  },
  emailWrapper: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "flex-start",
  },
  imgWrapper: {
    marginRight: 7,
    width: 12,
    "& img": {
      width: "100%",
    },
  },
  email: {
    color: "#ffffff",
    overflow: "hidden",
    "-webkit-box-orient": "vertical",
    display: "-webkit-box",
    wordBreak: "break-word",
    "-webkit-line-clamp": 1,
  },
  arrowWrapper: {
    position: "relative",
  },
  arrow: {
    position: "absolute",
    backgroundColor: "#f6f6f6",
    top: -10,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    color: "#808080",
    // [theme.breakpoints.down("xs")]: {
    //   top: -,
    // },
    "&:hover": {
      backgroundColor: "#083f85",
      color: "#fff",
      cursor: "pointer",
    },
  },
  arrowMainWrapper: {
    position: "absolute",
    right: "15px",
    top: "32px",
  },
  leftArrow: {
    right: 40,
  },
  rightArrow: {
    right: 0,
  },
}));

function IssuerList() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const issuerList = useSelector((state) => state.credential.issuerList);
  const selectedIssuer = useSelector(
    (state) => state.credential.selectedIssuer
  );
  const isUserOnline = useSelector((state) => state.common.isUserOnline);
  const [page, setPage] = useState(1);
  const classArray = [
    styles.blueCard,
    styles.redCard,
    styles.darkBlueCard,
    styles.yellowCard,
  ];
  const scrollbarRef = useRef(null);
  const rightArrowRef = useRef(null);
  useEffect(() => {
    if (
      !issuerList.loading &&
      !issuerList.response?.data?.length &&
      !isUserOnline
    )
      dispatch(toggleOfflineModal(!isUserOnline));
  });

  useEffect(() => {
    const params = {
      page: 1,
      per_page: 4,
    };
    dispatch(getIssuerList(params));
  }, [dispatch]);

  useEffect(() => {
    if (!selectedIssuer.id && Boolean(issuerList.response?.data?.length)) {
      dispatch(selectIssuer(issuerList.response?.data[0]));
    }
  });
  useEffect(() => {
    if (page > 1 && page <= issuerList.response?.pages) {
      const params = {
        page: page,
        per_page: 4,
      };
      dispatch(loadMoreIssuer(params));
    }
    // eslint-disable-next-line
  }, [page, dispatch]);

  const handleSelection = (event, issuer) => {
    if (selectedIssuer.id !== issuer.id) {
      dispatch(selectIssuer(issuer));
    }
  };

  const handleScroll = (event) => {
    let scrollPercentage =
      ((event.target.scrollLeft + event.target.offsetWidth) * 100) /
      event.target.scrollWidth;

    if (scrollPercentage > 97) {
      if (issuerList.response?.data?.length !== issuerList.response?.total) {
        if (page <= issuerList.response?.pages) {
          setPage((pre) => pre + 1);
        }
      }
    }
  };

  let list = issuerList.response?.data?.map((item, index) => {
    return (
      <div
        key={item.id}
        className={`${[classes.analyticsCard, classArray[index % 4]].join(
          " "
        )}`}
        aria-hidden="true"
        style={
          item.id === selectedIssuer.id
            ? {
                border: "solid 2.5px #083f85",
                borderRadius: "10px",
              }
            : {}
        }
        onClick={(event) => handleSelection(event, item)}
      >
        <div className={classes.cardContent}>
          <div className={classes.cardIconsWrapper}>
            <i className={classes.cardIcon}>
              <img src={item.logo} alt="Verification" />
            </i>
          </div>
          <div className={classes.detailWrapper}>
            <Typography variant="h6" className={classes.analyticsTitle}>
              {item.name}
            </Typography>
            <div className={classes.emailWrapper}>
              <div className={classes.imgWrapper}>
                <img src={EmailIcon} alt="email" />
              </div>
              <Typography variant="body2" className={classes.email}>
                {item.email}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    );
  });

  const handleLeftScroll = (event) => {
    scrollbarRef.current.scrollBy(-400, 0);
  };

  const handleRightScroll = (event) => {
    scrollbarRef.current.scrollBy(400, 0);
  };

  return (
    <>
      <div className={classes.arrowMainWrapper}>
        <div className={classes.arrowWrapper}>
          <div className={[classes.leftArrow, classes.arrow].join(" ")}>
            <ChevronLeftOutlinedIcon onClick={handleLeftScroll} />
          </div>
          <div
            className={[classes.rightArrow, classes.arrow].join(" ")}
            ref={rightArrowRef}
          >
            <ChevronRightOutlinedIcon onClick={handleRightScroll} />
          </div>
        </div>
      </div>
      <div
        ref={scrollbarRef}
        className={classes.analyticsCardsWrapper}
        onScroll={handleScroll}
      >
        {issuerList.loading && !issuerList.response?.data?.length ? (
          <IssuerListSkelton />
        ) : (
          list
        )}
      </div>
    </>
  );
}

export default IssuerList;
