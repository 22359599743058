import React from "react";
import TabBottom from "./components/Tab";
import VerifyHeader from "./components/VarifyHeader";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  verifyContent: {
    zIndex: "2",
    position: "relative",
    background: "none",
    borderRadius: "15px 15px 0 0",
    margin: "auto 10px ",
    [theme.breakpoints.down("sm")]: {
      margin: "auto ",
    },
  },
}));
const Verify = () => {
  const classes = useStyles();
  const holderDetails = useSelector(
    (state) => state.common.holderDetails.response
  );
  return (
    <React.Fragment>
      <VerifyHeader name={holderDetails.name} />
      <div className={classes.verifyContent}>
        <TabBottom />
      </div>
    </React.Fragment>
  );
};

export default Verify;
