import {
  REGISTER_BEGIN,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  SEND_VERIFICATION_LINK_TO_EMAIL_BEGIN,
  SEND_VERIFICATION_LINK_TO_EMAIL_SUCCESS,
  SEND_VERIFICATION_LINK_TO_EMAIL_FAILURE,
  TOGGLE_EMAIL_VERIFICATION_STATUS,
} from "./register.constants";
import { headers, http } from "utils";
import { openNotification } from "store/commonActions";

export const registerBegin = () => ({
  type: REGISTER_BEGIN,
});

export const registerSucess = (payload) => ({
  type: REGISTER_SUCCESS,
  payload,
});

export const registerFailure = (payload) => ({
  type: REGISTER_FAILURE,
  payload,
});

export const registerUser =
  (registerData, successCB, failureCB) => (dispatch) => {
    dispatch(registerBegin());
    http("post", "/holder/register", registerData, { headers })
      .then(function (response) {
        dispatch(registerSucess(response.data));
        dispatch(
          openNotification({
            message: "Congratulations! You have successfully registered!",
          })
        );
        successCB();
      })
      .catch(function (error) {
        dispatch(registerFailure(error));
        failureCB(error.response?.data?.message);
      });
  };

const sendVerificationLinkToEmailBegin = () => ({
  type: SEND_VERIFICATION_LINK_TO_EMAIL_BEGIN,
});
const sendVerificationLinkToEmailSuccess = (payload) => ({
  type: SEND_VERIFICATION_LINK_TO_EMAIL_SUCCESS,
  payload,
});
const sendVerificationLinkToEmailFailure = (payload) => ({
  type: SEND_VERIFICATION_LINK_TO_EMAIL_FAILURE,
  payload,
});

export const sendVerificationLinkToEmail = (body, successCB) => (dispatch) => {
  dispatch(sendVerificationLinkToEmailBegin());
  http("post", `/holder/email/confirmation`, body, { headers }, false)
    .then((response) => {
      dispatch(sendVerificationLinkToEmailSuccess(response.data?.data));
      dispatch(
        openNotification({
          message: response?.data?.message,
        })
      );
      successCB();
    })
    .catch((err) => {
      dispatch(
        sendVerificationLinkToEmailFailure(err?.response?.data?.message)
      );
      dispatch(
        openNotification({
          message: err?.response?.data?.message,
          severity: "error",
        })
      );
    });
};
export const toggleEmailVerficationStatus = (payload) => ({
  type: TOGGLE_EMAIL_VERIFICATION_STATUS,
  payload,
});

export const getEmailVerificationStatus = (email, data) => (dispatch) => {
  if (email === data.user_email) {
    dispatch(toggleEmailVerficationStatus(data.email_confirmed));
    if (data.email_confirmed) {
      dispatch(
        openNotification({
          message: "Your email address has been verified",
        })
      );
    }
  }
};
