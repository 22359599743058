import authRoles from "core/auth/authRoles";
import { LinkedInCallback } from "react-linkedin-login-oauth2";

const LinkedInConfig = {
  component: LinkedInCallback,
  path: "/linkedin",
  exact: true,
  layout: {
    config: {
      navbar: {
        display: false,
      },
      sidebar: {
        display: false,
      },
      footer: {
        display: false,
      },
    },
  },
  auth: authRoles.anyOne,
};

export default LinkedInConfig;
