import {
  GET_TOTAL_CREDENTIALS_STATS_BEGIN,
  GET_TOTAL_CREDENTIALS_STATS_FAILURE,
  GET_TOTAL_CREDENTIALS_STATS_SUCCESS,
} from "./Insights.constant";

const initState = {
  totalCredentialsStats: {
    loading: false,
    error: "",
    response: {},
  },
};

const insightsReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_TOTAL_CREDENTIALS_STATS_BEGIN:
      return {
        ...state,
        totalCredentialsStats: {
          ...state.totalCredentialsStats,
          loading: true,
        },
      };
    case GET_TOTAL_CREDENTIALS_STATS_SUCCESS:
      return {
        ...state,
        totalCredentialsStats: {
          ...state.totalCredentialsStats,
          loading: false,
          response: action.payload,
          error: "",
        },
      };
    case GET_TOTAL_CREDENTIALS_STATS_FAILURE:
      return {
        ...state,
        totalCredentialsStats: {
          ...state.totalCredentialsStats,
          loading: false,
          response: {},
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export default insightsReducer;
