import {
  LOGIN_BEGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  SOCIAL_LOGIN_BEGIN,
  SOCIAL_LOGIN_SUCCESS,
  SOCIAL_LOGIN_FAILURE,
} from "./login.constant";
import { http, headers, saveTokenInCookie, initializeSocket } from "utils";
import { openNotification, setNewSocket } from "store/commonActions";

const loginBegin = () => ({ type: LOGIN_BEGIN });

const loginSuccess = (payload) => ({ type: LOGIN_SUCCESS, payload });

const loginFailure = (payload) => ({ type: LOGIN_FAILURE, payload });

export const login = (data, successCB, FailureCB) => (dispatch) => {
  dispatch(loginBegin());
  http("post", "/holder/login", data, { headers })
    .then((response) => {
      let { data, message } = response.data;
      dispatch(
        openNotification({
          message: message,
        })
      );
      dispatch(loginSuccess(response.data.data));
      if (data.token) {
        saveTokenInCookie(data.token);
      }
      initializeSocket().then((newSocket) => {
        dispatch(setNewSocket(newSocket));
      });
      successCB(response.data.data);
    })
    .catch((err) => {
      dispatch(loginFailure(err.response?.data?.message));
      FailureCB(err.response?.data?.message);
    });
};

// Social Login : Google, LinkedIn, Mircrosoft

const socialLoginBegin = () => ({ type: SOCIAL_LOGIN_BEGIN });

export const socialLoginSuccess = (payload) => ({
  type: SOCIAL_LOGIN_SUCCESS,
  payload,
});

const socialLoginFailure = (payload) => ({
  type: SOCIAL_LOGIN_FAILURE,
  payload,
});

export const socialLogin =
  (params, data, successCB, failureCB) => (dispatch) => {
    dispatch(socialLoginBegin());
    http("post", "/holder/login/sso", data, { headers, params })
      .then((response) => {
        dispatch(socialLoginSuccess(response?.data?.data));
        successCB(response?.data);
        initializeSocket().then((newSocket) => {
          dispatch(setNewSocket(newSocket));
        });
      })
      .catch((err) => {
        dispatch(socialLoginFailure(err.response?.data?.message));
        failureCB(err.response?.data?.message);
      });
  };
