import authRoles from "core/auth/authRoles";
import Credentials from "./Credentials";

const CredentialsConfig = {
  component: Credentials,
  path: "/credentials",
  exact: false,
  layout: {
    config: {
      header: {
        display: false,
      },
      sidebar: {
        display: false,
      },
      footer: {
        display: false,
      },
    },
  },
  protected: true,
  auth: authRoles.onlyGuest,
};

export default CredentialsConfig;
