import authRoles from "core/auth/authRoles";
import Notification from "./Notification";

const NotificationConfig = {
  component: Notification,
  path: "/notification",
  exact: true,
  layout: {
    config: {
      header: {
        display: false,
      },
      sidebar: {
        display: false,
      },
      footer: {
        display: false,
      },
    },
  },
  protected: true,
  auth: authRoles.onlyGuest,
};

export default NotificationConfig;
