import React, { useEffect, useRef, useState } from "react";
import {
  TextField,
  InputLabel,
  makeStyles,
  Typography,
  Button,
  IconButton,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import MainPattern from "assets/images/svg/bg-pattern-big.svg";
import DefaultProfilePhoto from "assets/images/DefaultProfilePhoto.jpg";
import styles from "assets/css/commonstyle.module.css";
import { useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import { useDispatch, useSelector } from "react-redux";
import { updateUserProfile } from "../../profile.actions";
import Loader from "components/Loader";
import Profile from "assets/images/svg/profile.svg";
import EditIcon from "assets/images/svg/edit.svg";
import ChangePassword from "../ChangePassword";
import CopyButton from "components/CopyButton";
import {
  getHolderDetails,
  openNotification,
  setHolderDetails,
  toggleOfflineModal,
} from "store/commonActions";
import { getCookie, NAME_INPUT_LIMIT } from "utils";
import ImageCropper from "components/ImageCropper";

const useStyles = makeStyles((theme) => ({
  modal: {
    height: "100vh",
    background: "#fff",
  },
  modalHead: {
    height: 165,
    background: "#083f85",
    position: "relative",
    width: "100%",
    "&:after": {
      content: `' '`,
      position: "absolute",
      backgroundImage: `url(${MainPattern})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "top right",
      backgroundSize: "contain",
      top: 0,
      right: 0,
      width: 250,
      height: "68%",
      zIndex: 5,
    },
  },
  headingWrapper: {
    padding: "20px 0 10px 30px",
    display: "flex",
    alignItems: "center",
    position: "fixed",
    zIndex: 4,
    backgroundColor: "#083f85",
    width: "calc(1024px - 30px)",
    maxWidth: "calc(1024px - 30px)",
    [theme.breakpoints.up("md")]: {
      width: "calc(768px - 30px)",
      maxWidth: "calc(768px - 30px)",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  iconWrapper: {
    width: 36,
    height: 36,
    borderRadius: "50%",
    backgroundColor: "#fff",
    paddingLeft: 20,
  },
  title: {
    fontWeight: "bold",
    color: "#fff",
    paddingLeft: 18,
  },
  modalBody: {
    background: "#f6f6f6",
    zIndex: 2,
    position: "relative",
    borderRadius: "15px 15px 0 0",
    padding: "20px 20px 0",
    // marginTop: -15,
    margin: "-15px 10px 0 ",
    [theme.breakpoints.down("sm")]: {
      margin: "-15px 0px 0",
    },
  },
  pictureContainer: {
    display: "flex",
    marginTop: -60,
    justifyContent: "center",
  },
  pictureWrapper: {
    position: "relative",
    width: 78,
    height: 78,
    border: "4px solid #fff",
    borderRadius: "50%",
    "& img": {
      width: "100%",
      height: "100%",
      borderRadius: "50%",
    },
  },
  pictureImage: {
    width: "100%",
  },
  editProfileWrapper: {
    position: "absolute",
    width: 22,
    height: 22,
    background: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: 0,
    right: 0,
    borderRadius: "50%",
    "& img": {
      width: "65%",
    },
  },
  userDetailsWrapper: {
    textAlign: "center",
    padding: "25px 0",
    userSelect: "none",
    "& span": {
      fontWeight: "normal",
      display: "block",
      "&:first-child": {
        fontWeight: 600,
        paddingBottom: 10,
        wordBreak: "break-word",
      },
    },
  },
  sectionHeading: {
    display: "flex",
    alignItems: "center",
    "& span": {
      fontWeight: "bold",
    },
  },
  sectionIconWrapper: {
    height: 28,
    width: 28,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "100%",
    background: "#083f85",
    marginRight: 12,
  },
  sectionWrapper: {
    paddingTop: 20,
  },
  field: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 15,
    "& label": {
      textAlign: "left",
      marginBottom: 8,
    },
    "& .MuiOutlinedInput-root": {
      marginBottom: 6,
      borderRadius: 6,
    },
    "& input": {
      height: "42px !important",
      fontSize: "14px !important",
    },
  },
  buttonWrapper: {
    padding: "10px 0",
    "& button": {
      height: 36,
    },
  },
  customLabel: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#616161",
  },
  formWrapper: {
    padding: "30px 0 20px",
  },
  backdrop: {
    color: "#fff",
    position: "absolute",
    zIndex: theme.zIndex.drawer - 1,
    opacity: 0.5,
    borderRadius: "50%",
  },
  responseError: {
    marginTop: "15px",
    textAlign: "left",
    "& p": {
      color: "red",
      fontWeight: "500",
      fontSize: "13.5px",
    },
  },
  email: {
    paddingBottom: 5,
    display: "block",
    wordBreak: "break-all",
  },
  did: {
    fontSize: 12,
    display: "block",
    wordBreak: "break-all",
  },
}));

const errors = {
  contactError: "",
  nameError: "",
};

function UpdateProfile() {
  const classes = useStyles();
  const history = useHistory();
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const [file, setFile] = useState([]);
  const [imageSrc, setImageSrc] = React.useState("");
  const [open, setOpen] = useState(false);
  const [crop, setCrop] = useState({
    unit: "%",
    width: 50,
    height: 50,
    x: 25,
    y: 25,
  });

  const [userProfile, setUserProfile] = useState({
    name: "",
    contact: "",
  });
  const [error, setError] = useState(errors);
  let { nameError, contactError } = error;
  const [showLoader, setShowLoader] = useState(true);
  const [failureError, setFailureError] = useState({
    errorStatus: false,
    helperText: "",
  });

  const updateUserProfileState = useSelector(
    (state) => state.profile.updateUserProfile
  );
  const { loading } = updateUserProfileState;

  const userDetails = useSelector(
    (state) => state.common.holderDetails.response
  );
  const holderDetails = useSelector(
    (state) => state.common.holderDetails.response
  );

  const isUserOnline = useSelector((state) => state.common.isUserOnline);

  const isUpdateButtonDisable =
    userProfile?.name?.trim() === userDetails?.name &&
    userProfile?.contact?.trim() === userDetails?.contact;

  useEffect(() => {
    if (!holderDetails?.name && getCookie("accessToken")) {
      dispatch(getHolderDetails());
    }
  }, [dispatch, holderDetails]);

  useEffect(() => {
    setUserProfile({
      name: userDetails.name,
      contact: userDetails.contact,
    });
  }, [userDetails]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFailureError({
      errorStatus: false,
      helperText: "",
    });

    switch (name) {
      case "name":
        if (value === "") {
          nameError = "This field is required!";
        } else {
          nameError = "";
        }
        break;
      case "contact":
        if (value === "") {
          contactError = "This field is required!";
        } else if (value.length !== 10 && value.length > 0) {
          contactError = "Enter Valid Contact Number!";
        } else {
          contactError = "";
        }
        break;
      default:
        break;
    }

    setError({
      nameError,
      contactError,
    });
    setUserProfile({ ...userProfile, [name]: value });
  };
  const onKeyDown = (event) => {
    if (event.keyCode === 38 || event.keyCode === 40) {
      event.preventDefault();
    }
    if (
      event.key === "e" ||
      event.key === "E" ||
      event.keyCode === 107 ||
      event.keyCode === 109 ||
      event.keyCode === 187 ||
      event.keyCode === 189 ||
      event.keyCode === 190 ||
      event.keyCode === 110
    ) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (!file?.preview) return;
    let profile_image;

    const successCB = (response) => {
      dispatch(setHolderDetails(response.data.data));
      setShowLoader(true);
    };

    const failureCB = (error) => {
      setShowLoader(true);
      dispatch(
        openNotification({
          message: error,
          severity: "error",
        })
      );
    };

    setShowLoader(false);
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = function () {
      profile_image = reader.result.slice(
        reader.result.indexOf(",") + 1,
        reader.result.length
      );
      dispatch(updateUserProfile({ profile_image }, successCB, failureCB));
    };
  }, [file, dispatch]);

  const handleProfilePictureEdit = (event) => {
    let { files } = event.target;
    if (!isUserOnline) {
      dispatch(toggleOfflineModal(!isUserOnline));
      return;
    }
    if (files[0]?.size > 1000000) {
      dispatch(
        openNotification({
          severity: "error",
          message: "Image size should not be more than 1mb!",
        })
      );
      return;
    }

    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const image = new Image();
      let url;
      image.onload = function () {
        files[0].width = image.width;
        files[0].height = image.height;
        const aspect = 1;
        let img = files[0];
        let maxHeight = img.height < 200 ? img.height : 200;
        let maxWidth = img.width < 200 ? img.height : 200;
        const height = (maxHeight * 100) / img.height;
        const width = (maxWidth * 100) / img.width;
        setCrop({
          unit: "%",
          width: width,
          height: height,
          x: (100 - width) / 2,
          y: (100 - height) / 2,
          aspect,
        });
        setImageSrc(reader.result);
        setOpen(true);
      };
      url = URL.createObjectURL(files[0]);
      image.src = url;
    });
    if (files.length) {
      reader.readAsDataURL(files[0]);
    }
  };

  const handleUpdateUserProfile = () => {
    if (!userProfile.name || !userProfile.contact) {
      setFailureError({
        errorStatus: true,
        helperText: "Please fill all mandatory fields!",
      });
      return;
    }
    if (!isUserOnline) {
      dispatch(toggleOfflineModal(!isUserOnline));
      return;
    }

    const successCB = (response) => {
      dispatch(setHolderDetails(response.data.data));
    };

    const failureCB = (error) => {
      setFailureError({
        errorStatus: true,
        helperText: error,
      });
    };

    const updateProfileData = {
      name: userProfile?.name?.trim(),
      contact: userProfile?.contact,
    };

    if (error.commonError || failureError.errorStatus || error.contactError) {
      return;
    } else {
      dispatch(updateUserProfile(updateProfileData, successCB, failureCB));
    }
  };

  const errorInResponse = failureError.errorStatus ? (
    <div className={classes.responseError}>
      <Typography variant="body1">{failureError.helperText}</Typography>{" "}
    </div>
  ) : null;

  const handleGoBackClick = () => {
    history.goBack();
  };

  const handleHiddenInputOpen = () => {
    inputRef.current.click();
  };
  const onInputClick = (event) => {
    event.target.value = "";
  };

  return (
    <div>
      <ImageCropper
        crop={crop}
        setCrop={setCrop}
        src={imageSrc}
        setSrc={setImageSrc}
        open={open}
        setOpen={setOpen}
        setFiles={setFile}
      />
      <div className={classes.modal}>
        <div className={classes.modalHead}>
          <div className={classes.headingWrapper}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleGoBackClick}
              aria-label="close"
              className={classes.iconWrapper}
            >
              <ArrowBackIosIcon color="primary" />
            </IconButton>
            <Typography variant="h4" component="span" className={classes.title}>
              Profile
            </Typography>
          </div>
        </div>
        <div className={classes.modalBody}>
          <div className={classes.pictureContainer}>
            <div className={classes.pictureWrapper}>
              <img
                src={userDetails.profile_image || DefaultProfilePhoto}
                alt="profile"
              />
              <input
                ref={inputRef}
                type="file"
                onClick={onInputClick}
                onChange={handleProfilePictureEdit}
                accept="image/png, image/jpg, image/jpeg"
                hidden
              />

              <Backdrop
                className={classes.backdrop}
                open={loading && !showLoader}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              {!loading && showLoader ? (
                <div
                  className={classes.editProfileWrapper}
                  onClick={handleHiddenInputOpen}
                  aria-hidden="true"
                >
                  <img src={EditIcon} alt="edit" />
                </div>
              ) : null}
            </div>
          </div>
          <div className={classes.userDetailsWrapper}>
            <Typography variant="h3" component="span" color="primary">
              {userDetails.name}
            </Typography>
            <Typography
              variant="h6"
              component="span"
              color="primary"
              className={classes.email}
            >
              {userDetails.email}
            </Typography>
            {userDetails?.did ? (
              <Typography
                variant="body2"
                component="span"
                color="primary"
                className={classes.did}
              >
                {userDetails?.did}
                <CopyButton color="#083f85" content={userDetails?.did} />
              </Typography>
            ) : null}
          </div>
          <div className={classes.sectionWrapper}>
            <div className={classes.sectionHeading}>
              <div className={classes.sectionIconWrapper}>
                <img src={Profile} alt="profile" />
              </div>
              <Typography variant="h4" component="span" color="primary">
                Profile
              </Typography>
            </div>
            <div className={classes.formWrapper}>
              <div className={classes.field}>
                <InputLabel className={classes.customLabel} htmlFor="name">
                  Name
                </InputLabel>
                <TextField
                  placeholder="Name"
                  type="text"
                  variant="outlined"
                  size="small"
                  name="name"
                  className={styles.primaryInput}
                  value={userProfile.name || ""}
                  onChange={handleInputChange}
                  error={!!nameError}
                  helperText={nameError}
                  inputProps={{
                    maxLength: NAME_INPUT_LIMIT,
                  }}
                  InputProps={{
                    classes: { input: classes.inputProps },
                    endAdornment: `${
                      userProfile?.name?.length || 0
                    }/${NAME_INPUT_LIMIT}`,
                  }}
                  required
                />
              </div>
              <div className={classes.field}>
                <InputLabel className={classes.customLabel} htmlFor="contact">
                  Mobile Number
                </InputLabel>
                <TextField
                  placeholder="Mobile Number"
                  type="number"
                  variant="outlined"
                  size="small"
                  name="contact"
                  className={styles.primaryInput}
                  value={userProfile.contact || ""}
                  onChange={handleInputChange}
                  error={!!contactError}
                  helperText={contactError}
                  onKeyDown={onKeyDown}
                  required
                />
              </div>

              {errorInResponse}

              <div className={classes.buttonWrapper}>
                <Button
                  fullWidth
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={loading || isUpdateButtonDisable}
                  onClick={handleUpdateUserProfile}
                >
                  <Loader show={loading && showLoader} />
                  {!loading || !showLoader ? "Update Profile" : null}
                </Button>
              </div>
            </div>
          </div>
          <ChangePassword />
        </div>
      </div>
    </div>
  );
}

export default UpdateProfile;
