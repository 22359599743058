import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  field: {
    width: "100%",
    height: 60,
    marginBottom: 10,
    borderRadius: 4,
    "&:last-child": {
      marginBottom: 0,
    },
  },
  recentWrapper: {
    padding: "20px 0",
  },
}));

export default function RecentField() {
  const classes = useStyles();
  return (
    <div className={classes.recentWrapper}>
      <Skeleton animation="wave" variant="rect" className={classes.field} />
      <Skeleton animation="wave" variant="rect" className={classes.field} />
      <Skeleton animation="wave" variant="rect" className={classes.field} />
    </div>
  );
}
