import authRoles from "core/auth/authRoles";
import VerifyEmail from "./VerifyEmail";

const verifyEmailConfig = {
  component: VerifyEmail,
  path: "/verify-email/:verifyToken",
  exact: true,
  layout: {
    config: {
      header: {
        display: false,
      },
      sidebar: {
        display: false,
      },
      footer: {
        display: false,
      },
    },
  },
  protected: false,
  auth: authRoles.onlyGuest,
};

export default verifyEmailConfig;
