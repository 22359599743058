import React from "react";
import MicrosoftLogin from "react-microsoft-login";
import MicrosoftIcon from "assets/images/svg/office-icon.svg";
import styles from "assets/css/commonstyle.module.css";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { openNotification } from "store/commonActions";
import { socialLogin, socialLoginSuccess } from "pages/Login/login.action";
import { saveTokenInCookie } from "utils";

function MicrosoftSocialLogin() {
  const dispatch = useDispatch();
  const history = useHistory();
  const loginHandler = (err, data, msal) => {
    if (!err && data) {
      sessionStorage.clear();
      const body = {
        contact: data.mobilePhone || "",
        email: data.mail,
        name: data.displayName,
        profile_image: "",
        provider_id: data.uniqueId,
      };
      const successCB = ({ data, message }) => {
        dispatch(socialLoginSuccess(msal));
        dispatch(
          openNotification({
            message: message,
          })
        );
        if (data?.token) {
          saveTokenInCookie(data?.token);
          history.push("/insights");
        }
      };
      const failureCB = (error) => {
        dispatch(
          openNotification({
            message: error,
            severity: "error",
          })
        );
      };
      dispatch(
        socialLogin(
          { token: data.accessToken, sso_type: "office365" },
          body,
          successCB,
          failureCB
        )
      );
    }
  };

  return (
    <MicrosoftLogin
      clientId={process.env.REACT_APP_MICROSOFT_CLIENT_ID}
      authCallback={loginHandler}
      prompt="select_account"
      forceRedirectStrategy={false}
      withUserData={true}
    >
      <button className={styles.preLoginSocial}>
        <img src={MicrosoftIcon} alt="Microsoft Logo" height="20" width="20" />
      </button>
    </MicrosoftLogin>
  );
}

export default MicrosoftSocialLogin;
