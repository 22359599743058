export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}
export function deleteCookie(name) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

export function saveTokenInCookie(token) {
  document.cookie = `accessToken=${token}; expires=Thu, 18 Dec 2093 12:00:00 UTC; path=/`;
}

export function saveRememberInCookie(rememberMe) {
  document.cookie = `rememberMe=${rememberMe}; expires=Thu, 18 Dec 2093 12:00:00 UTC; path=/`;
}
