import React from "react";
import {
  TextField,
  InputLabel,
  IconButton,
  Dialog,
  makeStyles,
  Typography,
  Button,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ShareIcon from "assets/images/svg/share-icon-large.svg";
import EmailIcon from "assets/images/svg/email-icon.svg";
import CopyIcon from "assets/images/svg/copy-icon.svg";
import styles from "assets/css/commonstyle.module.css";
import { useDispatch, useSelector } from "react-redux";
import { shareCredential } from "../Credentials.action";
import { emailRegex } from "utils";
import Loader from "components/Loader";
import { openNotification, toggleOfflineModal } from "store/commonActions";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    position: "relative",
    width: 270,
    maxWidth: 278,
    padding: "35px 12px",
    borderRadius: 10,
  },
  iconWrapper: {
    position: "absolute",
    right: 0,
    top: 0,
  },
  heading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "& span": {
      fontWeight: "bold",
      padding: "20px 0",
    },
  },
  headingIconWrapper: {
    backgroundColor: "#083f85",
    width: 52,
    height: 52,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
  },
  customLabel: {
    fontSize: 12,
    fontWeight: 600,
    color: "#616161",
  },
  formWrapper: {
    paddingTop: 20,
  },
  actionField: {
    display: "flex",
    marginBottom: 20,
    "&:last-child": {
      marginBottom: 0,
    },
    "& .MuiButton-root": {
      minWidth: 0,
    },
  },
  field: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    marginRight: 7,
    "& label": {
      textAlign: "left",
      marginBottom: 7,
    },
    "& .MuiOutlinedInput-root": {
      marginBottom: 0,
    },
  },
  actionButtonWrapper: {
    width: "32px",
    height: "36px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "19px",
    borderRadius: "4px",
    backgroundColor: "#083f85",
    "& svg": {
      fill: "#fff",
    },
  },
  primaryInput: {
    "& .Mui-disabled": {
      color: "black",
    },
  },
}));

export default function ShareCredentialModal({
  open,
  handleClose,
  record_id,
  emailState,
  setEmailState,
  credLink,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const shareCredentialState = useSelector(
    (state) => state.credentialsAction.shareCredential
  );
  const { loading } = shareCredentialState;
  const isUserOnline = useSelector((state) => state.common.isUserOnline);

  const handleShareCredential = (event) => {
    const successCB = (response) => {
      handleClose(event);
    };

    if (!isUserOnline) {
      dispatch(toggleOfflineModal(!isUserOnline));
      return;
    }
    let reqBody = {
      holder_email: emailState.email,
    };
    if (emailState.email) {
      dispatch(shareCredential(record_id, reqBody, successCB));
    }
  };

  const handleEmailChange = (event) => {
    const { value } = event.target;
    let errMessage = "";

    if (!emailRegex.test(value) && value) {
      errMessage = "Email is not valid!";
    }
    setEmailState({ ...emailState, email: value, error: errMessage });
  };

  const copyCredLink = (event) => {
    event.stopPropagation();
    if (credLink) {
      navigator.clipboard.writeText(credLink);
      dispatch(
        openNotification({
          message: "Copied credential link !",
          hideDuration: 2000,
        })
      );
      handleClose(event);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      onClick={(event) => event.stopPropagation()}
    >
      <div className={classes.dialogWrapper}>
        <IconButton className={classes.iconWrapper} onClick={handleClose}>
          <CloseIcon color="primary" />
        </IconButton>
        <div className={classes.heading}>
          <div className={classes.headingIconWrapper}>
            <img src={ShareIcon} alt="share" />
          </div>
          <Typography variant="h5" component="span" color="primary">
            Share Credential
          </Typography>
        </div>
        <div className={classes.formWrapper}>
          <div className={classes.actionField}>
            <div className={classes.field}>
              <InputLabel className={classes.customLabel}>
                Share via email
              </InputLabel>
              <TextField
                type="email"
                name="email"
                variant="outlined"
                className={styles.primaryInput}
                placeholder="Enter Email Address"
                size="small"
                value={emailState.email}
                onChange={handleEmailChange}
                error={!!emailState.error}
                helperText={emailState.error}
                required
              />
            </div>
            <Button
              className={classes.actionButtonWrapper}
              onClick={handleShareCredential}
              disabled={
                loading || Boolean(emailState.error) || emailState.email === ""
              }
            >
              <Loader show={loading} />
              {!loading ? <img src={EmailIcon} alt="mail" /> : null}
            </Button>
          </div>
          <div className={classes.actionField}>
            <div className={classes.field}>
              <InputLabel className={classes.customLabel}>Copy Link</InputLabel>
              <TextField
                type="text"
                name="credLink"
                variant="outlined"
                className={styles.primaryInput}
                disabled
                size="small"
                value={credLink || "Loading..."}
              />
            </div>
            <div
              className={classes.actionButtonWrapper}
              onClick={copyCredLink}
              aria-hidden="true"
            >
              <img src={CopyIcon} alt="copy-link" />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
