import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { getFormattedDate } from "utils";
import { useSelector, useDispatch } from "react-redux";
import {
  readNotification,
  setNewNotificationList,
} from "pages/Notification/Notification.action";

const useStyles = makeStyles((theme) => ({
  notificationWrapper: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    padding: "15px 0",
    borderBottom: "1px solid #d4d0d0",
  },
  imgWrapper: {
    width: 48,
    flex: "0 0 48px",
    height: 48,
    margin: 0,
    "& img": {
      width: "100%",
      height: "100%",
      borderRadius: "50%",
    },
  },
  notificationMsgWrapper: {
    paddingLeft: 20,
    paddingRight: 38,
    fontSize: 14,
    flexGrow: 1,
    color: "#5e5e5e",
  },
  issuerName: {
    fontWeight: 600,
    wordBreak: "break-all",
  },
  newWrapper: {
    position: "absolute",
    backgroundColor: "#083f85",
    color: "#fff",
    fontSize: 10,
    padding: "0px 6px 2px",
    borderRadius: 6,
    top: 20,
    right: 5,
  },
  timeFix: {
    position: "relative",
    opacity: 0.9,
    color: "#444444",
    fontWeight: 600,
    display: "flex",
    alignItems: "baseline",
    paddingRight: 3,
    "&::before": {
      position: "absolute",
      content: "|",
    },
  },
  revertDesign: {
    display: "block",
    color: "#5e5e5e",
    fontWeight: "400 !important",
  },
}));

function Item({ data }) {
  const classes = useStyles();
  const { response } = useSelector(
    (state) => state.notification.notificationList
  );
  const dispatch = useDispatch();

  let {
    credential,
    credential_id,
    credential_image,
    is_new,
    issuer_name,
    type,
  } = data;

  let date = {
    issued: data.issued_at,
    revoked: data.revoked_at,
  };

  const openVerifier = () => {
    let link = process.env.REACT_APP_EVERYCRED_VERIFIER + "/" + credential_id;
    window.open(`${link}`, "_blank", "noopener,noreferrer");
  };

  const handleClick = (event) => {
    event.stopPropagation();

    const index = response?.data.findIndex(
      (item) => item.notification_id === data.notification_id
    );
    let copyList = [...(response?.data || [])];
    copyList[index].is_new = false;

    const successCB = () => {
      let payload = {
        data: copyList,
        pages: response?.pages,
        total: response?.total,
      };
      dispatch(setNewNotificationList(payload));
      openVerifier();
    };
    if (is_new) {
      dispatch(
        readNotification(
          {
            notification_id: JSON.stringify([copyList[index].notification_id]),
          },
          successCB
        )
      );
    } else {
      openVerifier();
    }
  };

  return (
    <div
      className={classes.notificationWrapper}
      onClick={handleClick}
      aria-hidden="true"
    >
      <figure className={classes.imgWrapper}>
        <img src={credential_image} alt="user" />
      </figure>
      <div className={classes.notificationMsgWrapper}>
        {/* <div className={classes.notificationDetail}> */}
        <Typography component="span">
          <Typography
            variant="h6"
            component="span"
            color="primary"
            className={classes.issuerName}
          >
            {issuer_name}
          </Typography>{" "}
          has {type} {type === "issued" ? "you" : "your"} {credential}{" "}
          credential.{" "}
        </Typography>
        {/* </div> */}
        <Typography component="span" className={classes.timeFix}>
          {getFormattedDate(date[type])}
        </Typography>
        {is_new && <div className={classes.newWrapper}>New</div>}
      </div>
    </div>
  );
}

export default Item;
