import React from "react";
import { IconButton, Dialog, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    position: "relative",
    width: 278,
    maxWidth: 278,
    minHeight: 100,
    padding: "55px 0px",
    borderRadius: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconWrapper: {
    position: "absolute",
    right: 0,
    top: 0,
  },
  imgWrapper: {
    width: 180,
    minHeight: 100,
    border: "11px solid #efefef",
    borderRadius: 12,
    "& img": {
      width: "100%",
    },
  },
  actionButtonsWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  actionIconWrapper: {
    width: 36,
    height: 36,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#083f85",
  },
}));

export default function VerifyModal({ open, handleClose, qrCode }) {
  const classes = useStyles();

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        onClick={(event) => event.stopPropagation()}
      >
        <div className={classes.dialogWrapper}>
          <IconButton className={classes.iconWrapper} onClick={handleClose}>
            <CloseIcon color="primary" />
          </IconButton>
          <div className={classes.imgWrapper}>
            <img src={qrCode} alt="QR Code" />
          </div>
        </div>
      </Dialog>
    </>
  );
}
