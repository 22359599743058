import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as ScanIcon } from "assets/images/svg/scan-icon.svg";
import VerifyModal from "../ActionModals/VerifyModal";
import { useDispatch, useSelector } from "react-redux";
import { toggleOfflineModal } from "store/commonActions";

const useStyles = makeStyles({
  actionBtn: {
    width: 30,
    minWidth: "auto",
    borderRadius: "50%",
    height: 30,
    backgroundColor: "#083f85",
    "&:hover": {
      backgroundColor: "#083f85",
    },
  },
});

function ScanCredentials({ qrCode }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const isUserOnline = useSelector((state) => state.common.isUserOnline);
  const dispatch = useDispatch();

  const handleQrModalOpen = (event) => {
    event.stopPropagation();
    if (!isUserOnline) {
      dispatch(toggleOfflineModal(!isUserOnline));
      return;
    }
    setOpen(true);
  };

  const closeQrModal = (event) => {
    event.stopPropagation();
    setOpen(false);
  };

  return (
    <>
      <Button className={classes.actionBtn} onClick={handleQrModalOpen}>
        <ScanIcon />
      </Button>
      <VerifyModal open={open} handleClose={closeQrModal} qrCode={qrCode} />
    </>
  );
}

export default ScanCredentials;
