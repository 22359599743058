import {
  OPEN_NOTIFICATION,
  CLOSE_NOTIFICATION,
  LOGOUT_USER,
  TOGGLE_LOGOUT_MODAL,
  GET_HOLDER_DETAILS_BEGIN,
  GET_HOLDER_DETAILS_SUCCESS,
  GET_HOLDER_DETAILS_FAILURE,
  SET_HOLDER_DETAILS,
  TOGGLE_OFFLINE_MODAL,
  TOGGLE_USER_NETWORK_STATUS,
  SET_NEW_SOCKET,
} from "./constants";

const initState = {
  notification: {
    open: false,
    message: "",
    hideDuration: "",
    severity: "",
  },
  logout: {
    show: false,
  },
  holderDetails: {
    loading: false,
    error: "",
    response: {},
  },
  offlineModal: {
    show: false,
  },
  isUserOnline: true,
  newSocket: {},
};

const commonReducer = (state = initState, action) => {
  switch (action.type) {
    case OPEN_NOTIFICATION:
      return {
        ...state,
        notification: {
          ...state.notification,
          open: true,
          message: action.payload.message,
          hideDuration: action.payload.hideDuration,
          severity: action.payload.severity,
        },
      };
    case CLOSE_NOTIFICATION:
      return {
        ...state,
        notification: {
          ...state.notification,
          open: false,
        },
      };
    case TOGGLE_LOGOUT_MODAL:
      return {
        ...state,
        logout: {
          show: !state.logout.show,
        },
      };
    case LOGOUT_USER:
      return {
        ...state,
        logout: {
          show: false,
        },
      };

    case GET_HOLDER_DETAILS_BEGIN:
      return {
        ...state,
        holderDetails: {
          ...state.holderDetails,
          loading: true,
        },
      };
    case GET_HOLDER_DETAILS_SUCCESS:
      return {
        ...state,
        holderDetails: {
          ...state.holderDetails,
          loading: false,
          response: action.payload,
        },
      };
    case GET_HOLDER_DETAILS_FAILURE:
      return {
        ...state,
        holderDetails: {
          ...state.holderDetails,
          loading: false,
          error: action.payload,
        },
      };
    case SET_HOLDER_DETAILS:
      return {
        ...state,
        holderDetails: {
          ...state.holderDetails,
          response: action.payload,
        },
      };
    case TOGGLE_OFFLINE_MODAL:
      return {
        ...state,
        offlineModal: {
          show: action.payload,
        },
      };
    case TOGGLE_USER_NETWORK_STATUS:
      return {
        ...state,
        isUserOnline: action.payload,
      };
    case SET_NEW_SOCKET:
      return {
        ...state,
        newSocket: action.payload,
      };
    default:
      return state;
  }
};

export default commonReducer;
