export const UPDATE_USER_PROFILE_BEGIN = "UPDATE_USER_PROFILE_BEGIN";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCESS";
export const UPDATE_USER_PROFILE_FAILURE = "UPDATE_USER_PROFILE_FAILURE";

export const CHANGE_USER_PASSWORD_BEGIN = "CHANGE_USER_PASSWORD_BEGIN";
export const CHANGE_USER_PASSWORD_SUCCESS = "CHANGE_USER_PASSWORD_SUCESS";
export const CHANGE_USER_PASSWORD_FAILURE = "CHANGE_USER_PASSWORD_FAILURE";

export const CLEAR_UPDATE_USER_PROFILE_RESPONSE =
  "CLEAR_UPDATE_USER_PROFILE_RESPONSE";
