import React from "react";
import GoogleLogin from "react-google-login";
import { gapi } from "gapi-script";
import googleIcon from "assets/images/svg/google-icon.svg";
import styles from "assets/css/commonstyle.module.css";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { socialLogin } from "pages/Login/login.action";
import { openNotification } from "store/commonActions";
import { saveTokenInCookie } from "utils";

const LoginWithGoogle = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const autoRemove = () => {
    const auth2 = gapi.auth2.getAuthInstance();
    if (auth2 != null) {
      auth2.signOut().then(auth2.disconnect().then());
    }
  };
  const onSuccess = (googleData) => {
    const data = {
      contact: "",
      email: "",
      name: "",
      profile_image: "",
      provider_id: "",
    };
    autoRemove();
    const successCB = ({ data, message }) => {
      dispatch(
        openNotification({
          message: message,
        })
      );
      if (data?.token) {
        saveTokenInCookie(data?.token);
        history.push("/insights");
      }
    };
    const failureCB = (error) => {
      dispatch(
        openNotification({
          message: error,
          severity: "error",
        })
      );
    };
    dispatch(
      socialLogin(
        { token: googleData.tokenId, sso_type: "gmail" },
        data,
        successCB,
        failureCB
      )
    );
  };

  const onFailure = ({ error }) => {
    dispatch(
      openNotification({
        message: error ?? "Something went wrong",
        severity: "error",
      })
    );
  };

  return (
    <GoogleLogin
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
      render={(renderProps) => (
        <button
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
          className={styles.preLoginSocial}
        >
          <img alt="google Icon" src={googleIcon} width="20" height="20" />
        </button>
      )}
      buttonText=""
      onSuccess={onSuccess}
      onFailure={onFailure}
      cookiePolicy={"single_host_origin"}
      style={{ fontSize: "20px" }}
    />
  );
};
export default LoginWithGoogle;
