import React from "react";
import { makeStyles } from "@material-ui/core";
import TabsNavigation from "./components/TabsNavigation";

const useStyles = makeStyles((theme) => ({
  userActionContainer: {
    background: "#fbfcfe",
    zIndex: 2,
    position: "relative",
    borderRadius: "15px 15px 0 0",
    padding: "30px 20px 0",
    // marginTop: -15,
    margin: "-15px 10px 0 ",
    [theme.breakpoints.down("sm")]: {
      margin: "-15px 0px 0",
    },
  },
}));
export default function CredentialsDetailsBody() {
  const classes = useStyles();

  return (
    <>
      <div className={classes.userActionContainer}>
        <TabsNavigation />
      </div>
    </>
  );
}
