import React, { useEffect, useState } from "react";
import {
  TextField,
  InputLabel,
  makeStyles,
  Typography,
  Grid,
  Button,
  InputAdornment,
} from "@material-ui/core";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import AppLogo from "components/AppLogo";
import RedirectComponent from "components/RedirectComponent";
import AuthImage from "components/AuthImage";
import {
  getEmailVerificationStatus,
  registerUser,
  sendVerificationLinkToEmail,
  toggleEmailVerficationStatus,
} from "./register.action";
import { useDispatch, useSelector } from "react-redux";
import { emailRegex, getCookie, NAME_INPUT_LIMIT, passwordRegex } from "utils";
import { ReactComponent as VerifiedIcon } from "assets/images/svg/verified-email.svg";
import { useHistory } from "react-router-dom";
import styles from "assets/css/commonstyle.module.css";
import Loader from "components/Loader";
import MainPattern from "assets/images/main-pattern.png";
import { openNotification, toggleOfflineModal } from "store/commonActions";
import SocialLogin from "pages/Login/components/SocialLogin";
import TimeOut from "components/TimeOut";

const useStyles = makeStyles((theme) => ({
  preLoginContentWrapper: {
    textAlign: "center",
  },
  adromentButton: {
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0",
    },
    "& .MuiButton-root:hover": {
      backgroundColor: "#083f85",
    },
    "& button[disabled]": {
      backgroundColor: "#ddd",
    },
  },
  registerField: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 15,
    "& label": {
      textAlign: "left",
      marginBottom: 7,
    },
    "& .MuiOutlinedInput-root": {
      marginBottom: 6,
    },
  },
  registerButton: {
    marginTop: theme.spacing(1),
    width: "100%",
  },
  mobileNumberSpinners: {
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  registerationError: {
    marginTop: "15px",
    textAlign: "center",
    "& p": {
      color: "red",
      fontWeight: "550",
      fontSize: "12px",
    },
  },
  heading: {
    paddingBottom: 25,
  },
  containerWrapper: {
    maxWidth: "500px",
    margin: "0 auto",
  },
  pageContainer: {
    padding: "0 20px",
    background: `url(${MainPattern}) no-repeat`,
    backgroundSize: "contain",
  },
  buttonWrapper: {
    padding: "10px 0",
    "& button": {
      height: 36,
    },
  },
  customLabel: {
    fontSize: "12px",
    fontWeight: "600",
    color: "#616161",
  },
  formWrapper: {
    padding: "20px 0",
  },
  verifyButton: {
    color: "#fff",
    height: "35px",
    padding: "16px 0",
    minWidth: "92px",
    background: "none",
    fontWeight: "500",
    borderRadius: "0 6px 6px 0",
    letterSpacing: "1px",
    textTransform: "capitalize",
    backgroundColor: "#083f85",
    [theme.breakpoints.down("xs")]: {
      height: 38,
    },
  },
  verified: {
    height: "35px",
    minWidth: "92px",
    fontWeight: "600",
    borderRadius: "0 6px 6px 0",
    textTransform: "capitalize",
    backgroundColor: "transparent",
    color: "#007333",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingRight: 10,
    [theme.breakpoints.down("sm")]: {
      height: 40,
    },
  },
  afterIcon: {
    marginRight: 5,
  },
  emailField: {
    "& .MuiOutlinedInput-adornedEnd": {
      padding: 0,
    },
  },
}));

//initial values for register input field
const initialValues = {
  name: "",
  email: "",
  mobileNumber: "",
  password: "",
  confirmPassword: "",
  errors: {
    name: "",
    email: "",
    mobileNumber: "",
    password: "",
    confirmPassword: "",
  },
  errorStatus: {
    name: false,
    email: false,
    mobileNumber: false,
    password: false,
    confirmPassword: false,
  },
};

function RegisterHolder() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const registerState = useSelector((state) => state.register);
  const { SSOLogin } = useSelector((state) => state.login);
  const { loading } = registerState;
  const isUserOnline = useSelector((state) => state.common.isUserOnline);

  const [values, setValues] = useState(initialValues);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [registerationError, setRegisterationError] = useState("");

  const socketConnection = useSelector((state) => state.common.newSocket);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const isEmailVerified = useSelector(
    (state) => state.register.isEmailVerified
  );
  const sendVerificationLink = useSelector(
    (state) => state.register.sendVerificationLink
  );
  const [showTimer, setShowTimer] = useState(false);
  const [verifyButtonText, setVerifyButtonText] = useState("Verify");

  useEffect(() => {
    const token = getCookie("accessToken");
    if (token) {
      history.push("/insights");
    }
  }, [history]);

  useEffect(() => {
    if (Object.keys(socketConnection).length) {
      socketConnection?.on("holder_email_confirmation", ({ data }) => {
        dispatch(getEmailVerificationStatus(values?.email, data));
      });
    }
    return () => dispatch(toggleEmailVerficationStatus(false));
  }, [dispatch, socketConnection, values?.email]);

  // function to remove up & down key event from number input
  const onKeyDown = (event) => {
    if (event.keyCode === 38 || event.keyCode === 40) {
      event.preventDefault();
    }
    if (
      event.key === "e" ||
      event.key === "E" ||
      event.keyCode === 107 ||
      event.keyCode === 109 ||
      event.keyCode === 187 ||
      event.keyCode === 189 ||
      event.keyCode === 190 ||
      event.keyCode === 110
    ) {
      event.preventDefault();
    }
  };

  // function to change password visibility icon
  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  //method to handle input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let errors = values.errors;
    let errorStatus = values.errorStatus;
    setRegisterationError("");
    switch (name) {
      case "name":
        errors.name = "";
        errorStatus.name = false;
        break;
      case "email":
        if (value === "") {
          errors.email = "This field is required!";
          errorStatus.email = true;
          setBtnDisabled(true);
        } else if (!emailRegex.test(value)) {
          errors.email = "Enter Valid email address!";
          errorStatus.email = true;
          setBtnDisabled(true);
        } else {
          errors.email = "";
          errorStatus.email = false;
          setBtnDisabled(false);
        }
        dispatch(toggleEmailVerficationStatus(false));
        setShowTimer(false);
        setVerifyButtonText("Verify");
        break;
      case "mobileNumber":
        errors.mobileNumber = "";
        errorStatus.mobileNumber = false;
        break;

      case "password":
        errors.password = "";
        errorStatus.password = false;
        if (value.length === 0) {
          errors.password = "This field is required!";
          errorStatus.password = true;
        } else if (
          value !== values.confirmPassword &&
          values.confirmPassword.length > 0
        ) {
          errors.confirmPassword = "Confirm Password doesn't match!";
          errorStatus.confirmPassword = true;
        } else {
          errors.confirmPassword = "";
          errorStatus.confirmPassword = false;
        }
        break;

      case "confirmPassword":
        if (value !== values.password) {
          errors.confirmPassword = "Confirm Password doesn't match!";
          errorStatus.confirmPassword = true;
        } else {
          errors.confirmPassword = "";
          errorStatus.confirmPassword = false;
        }
        break;

      default:
        break;
    }
    setValues({ ...values, [name]: value });
  };

  const validatePassword = (event) => {
    const { name, value } = event.target;
    let errors = values.errors;
    let errorStatus = values.errorStatus;

    if (!passwordRegex.test(value) && value.length > 0) {
      errors.password =
        "Your password must be have at least: 8 characters,one lowerCase,one upperCase,one special character and one Number!";
      errorStatus.password = true;
    } else {
      errors.password = "";
      errorStatus.password = false;
    }
    setValues({ ...values, [name]: value });
  };

  const validateAllFields = ({ keys, errors, errorStatus }) => {
    for (const element of keys) {
      switch (element) {
        case "name":
          errors.name = "";
          errorStatus.name = false;
          break;
        case "email":
          if (!emailRegex.test(values.email)) {
            errors.email = "This Email is not valid!";
            errorStatus.email = true;
          } else {
            errors.email = "";
            errorStatus.email = false;
          }
          break;
        case "mobileNumber":
          if (values.mobileNumber.length !== 10) {
            errors.mobileNumber =
              "Please enter 10-digit mobile number [w/o country code]!";
            errorStatus.mobileNumber = true;
          } else {
            errors.mobileNumber = "";
            errorStatus.mobileNumber = false;
          }
          break;
        case "password":
          if (!passwordRegex.test(values.password)) {
            errors.password =
              "Your password must be have at least: 8 characters,one lowerCase,one upperCase,one special character and one Number! ";
            errorStatus.password = true;
          } else {
            errors.password = "";
            errorStatus.password = false;
          }
          break;
        case "confirmPassword":
          if (values.confirmPassword !== values.password) {
            errors.confirmPassword = "Confirm Password doesn't match!";
            errorStatus.confirmPassword = true;
          } else {
            errors.confirmPassword = "";
            errorStatus.confirmPassword = false;
          }
          break;
        default:
          break;
      }
    }
  };

  //submit data on register button click
  const submitRegisterData = (event) => {
    event.preventDefault();
    if (!isUserOnline) {
      dispatch(toggleOfflineModal(!isUserOnline));
      return;
    }
    if (!isEmailVerified) {
      dispatch(
        openNotification({
          message: "Please Verify Your Email First!",
          severity: "warning",
        })
      );
      return;
    }

    let errors = values.errors;
    let errorStatus = values.errorStatus;

    // validation for input values
    let keys = Object.keys(values);
    validateAllFields({ keys, errors, errorStatus });
    setValues({ ...values, errors });

    const registerData = {
      name: values.name.trim(),
      contact: values.mobileNumber.trim(),
      email: values.email.trim(),
      password: values.password,
    };

    const successCB = () => {
      setValues(initialValues);
      history.push("/");
    };

    const failureCB = (error) => {
      setRegisterationError(error);
    };

    if (
      errorStatus.name === false &&
      errorStatus.email === false &&
      errorStatus.mobileNumber === false &&
      errorStatus.password === false &&
      errorStatus.confirmPassword === false &&
      !registerationError
    ) {
      dispatch(registerUser(registerData, successCB, failureCB));
    }
  };

  const handleEmailVerification = () => {
    const successCB = () => {
      setShowTimer(true);
    };
    dispatch(sendVerificationLinkToEmail({ email: values?.email }, successCB));
  };

  const Timer = !showTimer ? (
    <Button
      type="button"
      color="primary"
      variant="contained"
      className={classes.verifyButton}
      disabled={btnDisabled || sendVerificationLink.loading}
      onClick={handleEmailVerification}
    >
      <Loader show={sendVerificationLink.loading} />
      {!sendVerificationLink.loading ? verifyButtonText : null}
    </Button>
  ) : (
    <TimeOut
      afterTimeOut={() => {
        setVerifyButtonText("Resend");
        setShowTimer(false);
      }}
    />
  );

  //output rendered by register component
  return (
    <Grid container className={classes.pageContainer}>
      <Grid item xs={12}>
        <AuthImage />
      </Grid>
      <Grid item xs={12} className={classes.preLoginContentWrapper}>
        <div className={classes.containerWrapper}>
          <Typography
            variant="h1"
            component="p"
            className={classes.heading}
            color="primary"
            align="center"
          >
            Let's Get Started!
          </Typography>
          <AppLogo />
          <form className={classes.formWrapper} onSubmit={submitRegisterData}>
            <div className={classes.registerField}>
              <InputLabel className={classes.customLabel} htmlFor="name">
                Name
              </InputLabel>
              <TextField
                placeholder="Enter Name"
                type="text"
                variant="outlined"
                size="small"
                name="name"
                className={styles.primaryInput}
                value={values.name}
                onChange={handleInputChange}
                error={values.errorStatus.name}
                helperText={values.errors.name}
                inputProps={{
                  maxLength: NAME_INPUT_LIMIT,
                }}
                InputProps={{
                  classes: { input: classes.inputProps },
                  endAdornment: `${
                    values?.name?.length || 0
                  }/${NAME_INPUT_LIMIT}`,
                }}
                required
              />
            </div>
            <div className={classes.registerField}>
              <InputLabel className={classes.customLabel} htmlFor="email">
                Email
              </InputLabel>
              <TextField
                placeholder="Enter Email Address"
                required
                size="small"
                variant="outlined"
                className={[styles.primaryInput, classes.adromentButton].join(
                  " "
                )}
                type="email"
                name="email"
                value={values.email}
                onChange={handleInputChange}
                error={values.errorStatus.email}
                helperText={values.errors.email}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {!isEmailVerified ? (
                        Timer
                      ) : (
                        <span className={classes.verified}>
                          <VerifiedIcon className={classes.afterIcon} />{" "}
                          Verified
                        </span>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className={classes.registerField}>
              <InputLabel
                className={classes.customLabel}
                htmlFor="mobileNumber"
              >
                Mobile Number
              </InputLabel>
              <TextField
                placeholder="Enter Mobile Number"
                type="number"
                variant="outlined"
                size="small"
                className={styles.primaryInput}
                inputProps={{ className: classes.mobileNumberSpinners }}
                name="mobileNumber"
                required
                value={values.mobileNumber}
                onChange={handleInputChange}
                onKeyDown={onKeyDown}
                error={values.errorStatus.mobileNumber}
                helperText={values.errors.mobileNumber}
              />
            </div>
            <div className={classes.registerField}>
              <InputLabel className={classes.customLabel} htmlFor="password">
                Password
              </InputLabel>
              <TextField
                placeholder="Password [e.g.Abc@1234]"
                type={showPassword ? "text" : "password"}
                variant="outlined"
                size="small"
                name="password"
                className={styles.primaryInput}
                required
                value={values.password}
                onChange={handleInputChange}
                onBlur={validatePassword}
                error={values.errorStatus.password}
                helperText={values.errors.password}
                InputProps={{
                  endAdornment: (
                    <>
                      {!showPassword ? (
                        <VisibilityOffOutlinedIcon
                          fontSize="small"
                          color="primary"
                          style={{ cursor: "pointer" }}
                          onClick={handlePasswordVisibility}
                        />
                      ) : (
                        <VisibilityOutlinedIcon
                          fontSize="small"
                          color="primary"
                          style={{ cursor: "pointer" }}
                          onClick={handlePasswordVisibility}
                        />
                      )}
                    </>
                  ),
                }}
              />
            </div>

            <div className={classes.registerField}>
              <InputLabel
                className={classes.customLabel}
                htmlFor="confirmPassword"
              >
                Confirm Password
              </InputLabel>
              <TextField
                placeholder="Confirm Password"
                type={showConfirmPassword ? "text" : "password"}
                variant="outlined"
                size="small"
                name="confirmPassword"
                className={styles.primaryInput}
                required
                value={values.confirmPassword}
                onChange={handleInputChange}
                error={values.errorStatus.confirmPassword}
                helperText={values.errors.confirmPassword}
                InputProps={{
                  endAdornment: (
                    <>
                      {!showConfirmPassword ? (
                        <VisibilityOffOutlinedIcon
                          fontSize="small"
                          color="primary"
                          onClick={handleConfirmPasswordVisibility}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <VisibilityOutlinedIcon
                          fontSize="small"
                          color="primary"
                          onClick={handleConfirmPasswordVisibility}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </>
                  ),
                }}
              />
            </div>

            {/* error message shown when registation fails. */}
            {registerationError ? (
              <div className={classes.registerationError}>
                <Typography variant="body1">{registerationError}</Typography>{" "}
              </div>
            ) : null}

            <div className={classes.buttonWrapper}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                type="submit"
                disabled={loading || SSOLogin.loading}
              >
                <Loader show={loading || SSOLogin.loading} />
                {!loading && !SSOLogin.loading ? "Register Now" : null}
              </Button>
            </div>
          </form>
        </div>
        <SocialLogin title="Register" />

        {/* Link to redirect to login page */}
        <RedirectComponent
          text="I have an account?"
          path="/"
          name=" Login Here"
        />
      </Grid>
    </Grid>
  );
}

export default RegisterHolder;
